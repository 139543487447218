import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-circular-progressbar/dist/styles.css";
import API from "../../utils/apiCalling";
import { useParams } from "react-router-dom";
import Scratchsurvey from "../scratchsurvey2";
import ShareSurvey from "./shareSurvey";
import ReportScreen from "./report";
import { useHistory } from "react-router-dom";
import Footer from "../../Components/ReusableComponents/Footer";
import { capitalizeFirstLetter } from "../../utils/CommonFunction";
import Config from '../../config/env'
import NpsShare from "./nps/npsShare";
import NpsReport from "./nps/npsReport";
import CsatReport from "./nps/csatReport";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const CreateSurvey = () => {
  let api = new API();
  let history = new useHistory();
  let userData = JSON.parse(localStorage.getItem("user"));

  const { id } = useParams();

  const [editSurvey, setEditSurvey] = useState({});
  const [surveys, setSurveyId] = useState([]);
  const [audience, setaudience] = useState([]);
  const [survey, setSurveyDetails] = useState({});
  const [questionArray, setQuestionArray] = useState();
  const [screen, setScreen] = useState(1);
  const [access, setAccess] = useState();
  const [Workspace, setWorkSpace] = useState();
  const [adminButton, setAdminButton] = useState('')
  const logOut = () => {
    localStorage.clear();
    history.push("/login");
  };

  const BuyAudience = () => {
    history.push(`/estimatePage`);
  };

  const getAccess = async (id) => {
    let data = {
      _id: id,
    };

    let result = await api.post(
      `${Config.new.site_api}/client-survey/getPackages`,
      data
    );
    if (result.code === 200) {
      setAccess(
        result.data && result.data[0] && result.data[0].package_details
          ? result.data[0].package_details
          : undefined
      );
    } else {
      toast.error(result.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getSurveyById = async (id) => {
    let data = {
      id: id,
    };
    let result = await api.post(
      `${Config.new.site_api}/client-survey/getSurvey`,
      data
    );
    if (result.code === 200) {
      setaudience(result.data);
      setSurveyDetails(result.data);
      setQuestionArray(result.data.questions);
      setEditSurvey(result.data);
      getCategoryById(result.data.category_id);
    } else {
      toast.error(result.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const Upgrade = () => {
    history.push("/plan");
  };

  const initials = (userData.firstName[0] + userData.lastName[0]).toUpperCase();


  const getSurvey = async (id) => {
    let data = {
      User_Id: id,
    };
    let result = await api.post(
      `${Config.new.site_api}/client-survey/getSurvey`,
      data
    );
    if (result.code === 200) {
      setSurveyId(result.data);
    } else {
      toast.error(result.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getCategoryById = async (id) => {
    let data = {
      Id: id,
    };
    let result = await api.post(
      `${Config.new.site_api}/client-survey/getSurveyCategory`,
      data
    );
    if (result.code === 200) {
      setWorkSpace(result.data[0]?.category);
      // result.data.forEach(()=>
      //   result.data[0].category      )
    }
  };
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.userType === "admin"
      ? setAdminButton("admin")
      : setAdminButton("");
    getSurvey(user._id);
    setSurveyDetails(JSON.parse(localStorage.getItem("survey")));
    getSurveyById(id);
    getAccess(user._id);
  }, []);

  // <<<<<<<<<<<<<<<<<<<<<<<<             Excel upload function             >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const handleExcelUpload = async  (event) => {

    const file = event.target.files[0];
    const formData = new FormData();
        if (file) {
            formData.append('file', file);
            formData.append('_id', id);
            let data = {
              file: formData,
              _id:id
            }
          try {
              const result = await api.putPostFileFun('http://localhost:3009/client-survey/createNewSurveyByExcel', formData);
              if(result.code==200){
                toast.success("File uploaded successfully")
                window.location.reload()
              }
              else{
                toast.error("File upload failed")
              }
            }
            catch (error) {
               console.error('Error uploading file', error);
               toast.error("Error uploading file")
           }
    }
  }

  const handleExcleClick = () => {
    document.getElementById('excelUploadInput').click();
  }
  
// <<<<<<<<<<<<<<<<<<<<<<       Sample excel download function   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const handleSampleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    const headers = ['question', 'que_message', 'type', 'selection'];

    const type = ['radio', 'checkbox', 'text', 'form', 'matrix', 'nps', 'csat', 'rating', 'contact' ]

    const selection = ['single', 'multiple', 'emoji', 'ynm' ,'rating','star']

    const numberOfOptions = 11; 
  
    for (let i = 1; i <= numberOfOptions; i++) {
      headers.push(`Option ${i}`);
      headers.push(`Text Box ${i}`);
    }
    worksheet.addRow(headers);
  
    const numRows = 100;
    const textBox = ['yes', 'no'];

    const getColumnLetter = (col) => {
      let letter = '';
      while (col > 0) {
        let temp = (col - 1) % 26;
        letter = String.fromCharCode(temp + 65) + letter;
        col = (col - temp - 1) / 26;
      }
      return letter;
    };
  
    for (let e = 2; e <= numRows + 1; e++) {
      for (let f = 1; f <= numberOfOptions; f++) {
        // const optionCol = getColumnLetter(4 + (e - 1) * 2); 
        const textBoxCol = getColumnLetter(6 + (f - 1) * 2); 
        
        // const optionCell = worksheet.getCell(`${optionCol}${d}`);
        const textBoxCell = worksheet.getCell(`${textBoxCol}${e}`);
        // optionCell.dataValidation = {
        //   type: 'list',
        //   formulae: [`"${textBox.join(',')}"`],
        // };
        textBoxCell.dataValidation = {
          type: 'list',
          formulae: [`"${textBox.join(',')}"`],
        };
        worksheet.getCell(`C${e}`).dataValidation = {
          type: 'list',
          formulae: [`"${type.join(',')}"`],
        };
        worksheet.getCell(`D${e}`).dataValidation = {
          type: 'list',
          formulae: [`"${selection.join(',')}"`],
  
        };
      }
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sampleSurvey.xlsx';
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <section id="all-section" className="admin-header">
        <nav className="navbar navbar-expand-lg bg-white p-0 border nevigtsao">
          <div className="container-fluid px-2">
            <div className="manheadersurvey w-100">
              <div className="d-flex justify-content-between w-100 align-items-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-white m-0">
                    <li className="breadcrumb-item">
                      <a href="#/adminDash">
                        {Workspace ? capitalizeFirstLetter(Workspace) : "My Workspace"}</a>
                    </li>

                    <li className="breadcrumb-item active" aria-current="page">
                      {editSurvey?.survey_name}
                    </li>
                  </ol>
                </nav>
                {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContentabc"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="fs-1 bi bi-list"></i>
            </span>
          </button> */}
                <div className=" mb-3 mb-md-0 tabsdesktop" id="navbarSupportedContentabc">
                  <div className="">
                    <ul className="nav navbar-nav">
                      <ul
                        className="nav nav-tabs border-0 justify-content-around "
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              screen === 1
                                ? "nav-link scratchheadernavpill active"
                                : "nav-link scratchheadernavpill"
                            }
                            id="create-tab"
                            onClick={(e) => setScreen(1)}
                          >
                            Create
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              screen === 2
                                ? "nav-link scratchheadernavpill active"
                                : "nav-link scratchheadernavpill"
                            }
                            id="share-tab"
                            onClick={(e) => survey && survey.saved == 1 ? survey?.surveyFor == "NPS" ? setScreen(4) : setScreen(2) : null}
                          >
                            Share
                          </button>
                        </li>
                        <li className="nav-item " role="presentation">
                          <button
                            className={
                              screen === 3
                                ? "nav-link scratchheadernavpill active"
                                : "nav-link scratchheadernavpill"
                            }
                            id="result-tab"
                            onClick={(e) => survey && survey.saved == 1 ? survey?.surveyFor == "NPS" ? setScreen(6) : setScreen(3) : null}
                          >
                            Report
                          </button>
                        </li>
                        {/* <button
                    type="button"
                    class="mainbtn mt-3 mainheadbtun visbBUtton d-flex align-items-center"
                    style={{
                      height: "37px",
                      border: "1px solid #f47321",
                      color: "#f47321",
                      background: "#fff",
                      position: 'absolute',
                      right: '200px'

                    }}
                    onClick={(e) => Upgrade()}
                  >
                    <img
                      src="../images/crown.svg"
                      className="me-2"
                      width="24px"
                      alt=""
                    />
                    <label className='mt-1' style={{ color: "#f47321" }}>
                      Upgrade
                    </label>
                  </button> */}
                      </ul>
                    </ul>
                  </div>
                </div>

                <div className="catn d-flex align-items-center justify-content-between w-auto">
                <div className="mr-3">
                  <form action="/sendFile" enctype="multipart/form-data">
                    <input type="file" style={{display:"none"}}  id="excelUploadInput"  accept=".xlsx, .xls"  onChange={handleExcelUpload}></input>
                    {/* <ExcelUpload /> */}
                  </form>
                  {survey?.saved!=1 && <span onClick={()=>{handleExcleClick()}} className="excelUploadButtonIndex p-1 mr-2 " style={{border: '1px solid #f47321', borderRadius: '5px', backgroundColor: 'transparent', color: '#f47321',cursor: 'pointer', transition: 'background-color 0.3s, color 0.3s'}}>Excel Upload</span>}
                  {survey?.saved!=1 && <span onClick={()=>{handleSampleExcelDownload()}} className="excelUploadButtonIndex p-1" style={{border: '1px solid #f47321', borderRadius: '5px', backgroundColor: 'transparent', color: '#f47321',cursor: 'pointer', transition: 'background-color 0.3s, color 0.3s'}}>Sample Download</span>}
                    
                </div>
                  {/* <button
              type="button"
              class="mainbtn me-3 mainheadbtun visbBUtton d-flex align-items-center"
              style={{
                height: "37px",
                border: "1px solid #f47321",
                color: "#f47321",
                background: "#fff",
                right: "200px",
              }}
              onClick={(e) => Upgrade()}
            >
              <img
                src="../images/crown.svg"
                className="me-2"
                width="24px"
                alt=""
              />
              <label className="mt-1" style={{ color: "#f47321" }}>
                Upgrade
              </label>
            </button> */}
                  {/* <button type="button" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Tooltip on bottom">
              Tooltip on bottom
            </button> */}
                  <div className="preview-web ">
                    <a
                      href={`https://doyoursurvey.com/#/review/` + id}
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye-fill "
                        viewBox="0 0 16 16"
                      >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                      </svg>
                    </a>
                    <span>Preview</span>
                  </div>
                  {audience && audience.audience === "Buy Audience" && (
                    <a className="mainbtn f-14 mx-4" onClick={BuyAudience}>
                      Buy Target Audience
                    </a>
                  )}
                  <button
                    type="button"
                    class="ms-2 mainheadbtun visbBUtton d-flex align-items-center p-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Upgrade"
                    style={{
                      height: "37px",
                      border: "1px solid #f47321",
                      borderBottom: "1px solid #f47321 !important",
                      color: "#f47321",
                      background: "#fff",
                      width: "40px",
                      justifyContent: "center",
                      borderRadius: "4px",
                      right: "200px",
                    }}
                    onClick={(e) => Upgrade()}
                  >
                    <img src="../images/crown.svg" className="" width="24px" alt="" />
                    {/* <label className='mt-1' style={{ color: "#f47321" }}>
                      Upgrade
                    </label> */}
                  </button>
                  <ul className="navbar-nav profileview profileheader">
                    <div className="nav-item dropdown notification-drop">
                      {/* <a id="dLabel" role="button" className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-bell-fill text-blue fs-4 position-relative"><span className="position-absolute top-0 start-100 translate-middle badge rounded-circle text-blue f-12 bg-grey">
                    <small className='fw-bold'>4</small>
                    <span className="visually-hidden">unread messages</span>
                  </span></i>
                </a> */}
                      <ul
                        className="dropdown-menu dropdown-menu-lg-end notifications"
                        role="menu"
                        aria-labelledby="dLabel"
                      >
                        <div className="notification-heading">
                          <p className="text-orange m-2 f-14">Notificationsd</p>
                        </div>
                        <li className="divider" />
                        <div className="notifications-wrapper">
                          <a className="content" href="#">
                            <div className="notification-item  read">
                              <img src="../images/2.png"></img>
                              <div className="tableform">
                                <p className="text-orange f-14">
                                  Marrisa Fair
                                  <span className="float-right f-12">4:00PM</span>
                                </p>
                                <p className="item-info f-12">
                                  Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the
                                  industry's standard dummy text ever since the 1500s,
                                  when an unknown printer took a galley of type and
                                  scrambled it to make a type specimen book. It has
                                  survived not only five centuries
                                </p>
                              </div>
                            </div>
                          </a>
                          <a className="content" href="#">
                            <div className="notification-item  read">
                              <img src="../images/2.png"></img>
                              <div className="tableform">
                                <p className="text-orange f-14">
                                  Marrisa Fair
                                  <span className="float-right f-12">4:00PM</span>
                                </p>
                                <p className="item-info f-12">
                                  Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the{" "}
                                </p>
                              </div>
                            </div>
                          </a>
                          <a className="content" href="#">
                            <div className="notification-item ">
                              <img src="../images/2.png"></img>
                              <div className="tableform">
                                <p className="text-orange f-14">
                                  Marrisa Fair
                                  <span className="float-right f-12">4:00PM</span>
                                </p>
                                <p className="item-info f-12">
                                  Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the{" "}
                                </p>
                              </div>
                            </div>
                          </a>
                          <a className="content " href="#">
                            <div className="notification-item">
                              <img src="../images/2.png"></img>
                              <div className="tableform">
                                <p className="text-orange f-14">
                                  Marrisa Fair
                                  <span className="float-right f-12">4:00PM</span>
                                </p>
                                <p className="item-info f-12">
                                  Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the{" "}
                                </p>
                              </div>
                            </div>
                          </a>
                          <a className="content " href="#">
                            <div className="notification-item ">
                              <img src="../images/2.png"></img>
                              <div className="tableform">
                                <p className="text-orange f-14">
                                  Marrisa Fair
                                  <span className="float-right f-12">4:00PM</span>
                                </p>
                                <p className="item-info f-12">
                                  Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the{" "}
                                </p>
                              </div>
                            </div>
                          </a>
                          <a className="content " href="#">
                            <div className="notification-item ">
                              <img src="../images/2.png"></img>
                              <div className="tableform">
                                <p className="text-orange f-14">
                                  Marrisa Fair
                                  <span className="float-right f-12">4:00PM</span>
                                </p>
                                <p className="item-info f-12">
                                  Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the{" "}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </ul>
                    </div>
                    <li className="nav-item dropdown profile-drop">
                      <a
                        className="nav-link dropdown-toggle d-flex align-items-center justify-content-between align-items-center position-static "
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <img
                    src="../images/2@2x.png"
                    width="50px"
                    className="rounded-circle"
                    alt=""
                  /> */}

                        <div style={{ width: "46px" }}
                          className="rounded-circle" id="profileImage">{initials}</div>
                        <p className="profile-name text-grey m-0 f-14 px-2">
                          {" "}
                          <i className="bi bi-chevron-down text-grey mt-1"></i>
                        </p>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-lg-end">
                        <li>
                          {/* <a className="dropdown-item border-bottom" href="#">
                      <i className="bi bi-person-fill pe-2" />
                      Profile
                    </a> */}
                        </li>
                        {adminButton === "admin" && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/#/192837465/adminDashboard"
                            >
                              Admin Dashboard
                            </a>
                          </li>
                        )}
                        <li>
                          <a className="dropdown-item" onClick={logOut}>
                            <i className="fa fa-sign-out pe-2" aria-hidden="true" />
                            Logout
                          </a>
                        </li>
                        
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tabstablate" id="navbarSupportedContentabc">
              <div className="">
                <ul className="nav navbar-nav">
                  <ul
                    className="nav nav-tabs border-0 justify-content-around "
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          screen === 1
                            ? "nav-link scratchheadernavpill active"
                            : "nav-link scratchheadernavpill"
                        }
                        id="create-tab"
                        onClick={(e) => setScreen(1)}
                      >
                        Create
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          screen === 2
                            ? "nav-link scratchheadernavpill active"
                            : "nav-link scratchheadernavpill"
                        }
                        id="share-tab"
                        onClick={(e) => survey && survey.saved == 1 ? setScreen(2) : null}
                      >
                        Share
                      </button>
                    </li>
                    <li className="nav-item " role="presentation">
                      <button
                        className={
                          screen === 3
                            ? "nav-link scratchheadernavpill active"
                            : "nav-link scratchheadernavpill"
                        }
                        id="result-tab"
                        onClick={(e) => survey && survey.saved == 1 ? setScreen(3) : null}
                      >
                        Report
                      </button>
                    </li>
                    {/* <button
                    type="button"
                    class="mainbtn mt-3 mainheadbtun visbBUtton d-flex align-items-center"
                    style={{
                      height: "37px",
                      border: "1px solid #f47321",
                      color: "#f47321",
                      background: "#fff",
                      position: 'absolute',
                      right: '200px'

                    }}
                    onClick={(e) => Upgrade()}
                  >
                    <img
                      src="../images/crown.svg"
                      className="me-2"
                      width="24px"
                      alt=""
                    />
                    <label className='mt-1' style={{ color: "#f47321" }}>
                      Upgrade
                    </label>
                  </button> */}
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        {screen === 1 && (
          <Scratchsurvey survey={survey} setScreen={setScreen} access={access} setSurvey={setSurveyDetails} />
        )}
        {screen === 2 && (
          <ShareSurvey id={id} survey={survey} access={access?.Limit} />
        )}
        {screen === 3 && <ReportScreen selectedSurveys={surveys} id={id} />}

        {screen === 4 && <NpsShare selectedSurveys={surveys} id={id} surveyName={editSurvey?.survey_name} survey={editSurvey}/>}
        {screen === 5 && <NpsReport selectedSurveys={surveys} id={id} surveyName={editSurvey?.survey_name}/>}
        {screen === 6 && <CsatReport selectedSurveys={surveys.filter((ele)=>{return ele._id==id})[0]} id={id} surveyName={editSurvey?.survey_name}/>}

        {/* <Footer /> */}
      </section>
    </>
  );
};

export default CreateSurvey;
