import React from 'react'
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../utils/apiCalling';
import { useHistory } from 'react-router-dom';
import Loader from '../utils/loader';
import ReactPlayer from 'react-player'
import { useLocation, useParams } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';
import Config from '../config/env'
import { useSpeechSynthesis } from 'react-speech-kit'
import { v4 as uuid } from "uuid";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
// import AudioRecorderComponent from '../Components/ReusableComponents/audiorecorder';
// import AudioAnalyser from "react-audio-analyser";
import MicRecorder from 'mic-recorder-to-mp3';
const recorder = new MicRecorder({
    bitRate: 128
});


function DirectSurvey(props) {
    const { id } = useParams();
    const uniqieId = uuid()
    const location = new useLocation()
    const { profilePercent } = props;
    const { speak, voices } = useSpeechSynthesis()
    const [surveyStartTime, setSurveyStartTime] = useState('')
    const [surveyScreen, setSurveyScreen] = useState(false)
    const [survey, setSurveys] = useState()
    const [type, setSurveyType] = useState('survey')
    const [selectedCsat, setSelectedCsat] = useState()
    const [surveyQues, setSurveyQues] = useState()
    const [quesNo, setQuesNo] = useState(0)
    const [user, setUserData] = useState("");
    const [button, setButton] = useState(false)
    const [answer, setTemporaryAns] = useState([])
    const [progress, setProgress] = useState(0)
    const history = new useHistory();
    const [checkboxCount, setCount] = useState(0);
    const [eventRadio, setEvent] = useState("");
    const [radioOption, setRadioOption] = useState("");
    const [eventText, setEventText] = useState("");
    const [eventForm, setEventForm] = useState([])
    const [eventCheckbox, setEventCheckbox] = useState([])
    const [eventMatrix, setEventMatrix] = useState([])
    const [text_box, setTextBox] = useState(false)
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [emailScreen, setEmailScreen] = useState(true)
    const [surveySubmited, setSurveySubmited] = useState(false)
    const [surveyStutas, setSurveyStutas] = useState()
    const [shareId, setShareId] = useState()
    const [doubleDrop, setDoubleDrop] = useState(["", ""])
    const [loader, setLoader] = useState(false)
    const [savedAns, setSavedAns] = useState([])
    const [actionId, setActionId] = useState([])
    const [previousQue, setPreveiousQue] = useState()
    const [dropdownAns, setDropdownAns] = useState()
    const [ratingValue, setRatingValue] = useState(0)
    const [anslength, setAnslength] = useState([])
    const [language, setLanguage] = useState([]);
    const [starClass, setStarClass] = useState()
    const [selectedLang, setSelectedLang] = useState('en')
    const search = useLocation().search;
    const [audioPause, setAudioPause] = useState("onPause")
    const player = useRef();
    const [checkBoxIndex, setCheckBoxIndex] = useState([])
    const [filee, setFile] = useState('')
    const [selectImage, setselectImage] = useState('no')
    const [displayKeys, setDisplayKeys] = useState({ question: 'question', option: 'option', que_message: "que_message" })
    const [count, setcount] = useState(0)
    const [Images, SetImages] = useState([])
    const [newmatValue, setNewmatValue] = useState('')
    const [inputTag, setInputTag] = useState('')
    const [buttonIndex, setButtonIndex] = useState()
    const [surveyIframe, setSurveyIframe] = useState(false)
    const [matHeader, setMatHeader] = useState([])
    const [backQueAns, setBackQueAns] = useState([])
    const [textarea, setTextarea] = useState([])
    const [questionIndex, setQuestionIndex] = useState([0])
    const [matInputValue, seMmatInputValue] = useState([])
    const [numberInput, setNumberInput] = useState("")
    const [multiActId, setMultiActId] = useState([])
    const [dependant_ques, setDependentQues] = useState([])
    const [showRecording, setShowRecording] = useState(false);
    const [shouldDownload, setShouldDownload] = useState(false);
    const [optionExclusive, setOptionExclusive] = useState([])
    const [serial, setSerial] = useState(1)
    const [responseId, setResponseId] = useState('')
    const [recordingStatus, setRecordingStatus] = useState("");
    const [audioSrc, setAudioSrc] = useState(null);
    const [audioType, setAudioType] = useState("audio/mp3");
    const [maturitCount, setMaturitCount] = useState(0)

    const api = new API

    const [input, setInput] = useState('');
    const [layout, setLayout] = useState('english');
    const [shiftMode, setShiftMode] = useState(false);
    const keyboardRef = useRef(null)

    const englishLayout = {
        default: [
            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",


            "q w e r t y u i o p [ ] \\",


            "{tab} a s d f g h j k l ; ' {enter}",


            "{shift} z x c v b n m , . / {shift}",


            "{space}",

        ],


        shift: [


            "~ ! @ # $ % ^ & * ( ) _ + {bksp}",


            "Q W E R T Y U I O P { } |",


            "{tab} A S D F G H J K L : \" {enter}",


            "{shift} Z X C V B N M < > ? {shift}",


            "{space}",

        ],
    };

    const layouts = {


        english: englishLayout

    };

    const resetKeyboardInput = (input) => {
        setInput(input);
        if (keyboardRef.current && input == '') {
            keyboardRef.current.clearInput()
        } else if (keyboardRef.current) {
            keyboardRef.current.setInput(input)
        }
    };
    const onKeyPress = (button) => {
        if (button === '{shift}' || button === '{lock}') {
            handleShift();
        }
    };

    const handleShift = () => {
        setShiftMode(!shiftMode);
    };

    const sendMail = async (question, ans) => {
        let answerr
        if (question.optionColumn) {
            let a = ''
            Object.keys(ans[0]).forEach((ele) => {
                a = a + `<h4>${ele}  - ${ans[0][ele]} </h4>`

            })
            answerr = a
        } else {
            answerr = `<h4>${JSON.stringify(ans[0])}</h4>`
        }
        let data = {
            "subject": survey.survey_name,
            "html": `<!DOCTYPE html>

            <html lang="en">
            
            <head>
            
            <meta charset="UTF-8">
            
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            
            <title> Email</title>
            
            <style>
            
            body {
            
            font-family: Arial, sans-serif;
            
            background-color: #f4f8fb;
            
            margin: 0;
            
            padding: 0;
            
            }
            
            .container {
            
            max-width: 600px;
            
            margin: 0 auto;
            
            background-color: #ffffff;
            
            padding: 20px;
            
            border: 1px solid #e6e6e6;
            
            border-radius: 8px;
            
            }
            
            .header {
            
            text-align: center;
            
            padding-bottom: 20px;
            
            border-bottom: 1px solid #e6e6e6;
            
            }
            
            .header img {
            
            width: 120px;
            
            }
            
            .content {
            
            padding: 20px;
            
            text-align: center;
            
            }
            
            .content h1 {
            
            font-size: 24px;
            
            color: #333333;
            
            }
            
            .content p {
            
            font-size: 16px;
            
            color: #666666;
            
            line-height: 1.5;
            
            }
            
            .footer {
            
            text-align: center;
            
            padding-top: 20px;
            
            border-top: 1px solid #e6e6e6;
            
            }
            
            .footer p {
            
            font-size: 12px;
            
            color: #999999;
            
            }
            
            .social-icons {
            
            margin-top: 10px;
            
            }
            
            .social-icons img {
            
            width: 24px;
            
            margin: 0 10px;
            
            }
            
            </style>
            
            </head>
            
            <body>
            
            <div class="container">
            
            <div class="header">
            
            </div>
            
            <div class="content">
            
            <h1>Hello <span style="font-size: 24px;">👋</span></h1>
            
            <h2>
            ${survey.survey_name}
            </h2>
            
            <h3>${question.mail.title}</h3>
            
            <h4>${answerr}<h4>
            </div>
          
            
            </div>
            
            </body>
            
            </html>
            
            `,
            "email": question.mail.email
        }
        let result = await api.post(`${Config.new.site_api}/survey/sendMail`, data)
    }


    const goToNextQuestion = async (question, option) => {
        setAudioPause('onPuase')
        setCount(0)

        let finalAns = option ? option : answer
        if (question.mail) {
            sendMail(question, finalAns)
        }
        let noteButton
        if (question.type == 'matrix' && question.selection == 'single') {
            let tempAns = ""
            answer.forEach(ans => {
                tempAns = `${tempAns}field:${ans.bank}-Answer:${ans.answer},`
            })
            finalAns = [tempAns]
        }
        else if (question.type == 'note') {
            if (question.nccsCatgory && ["C1", "C2", "D1", "D2", "E1", "E2", "E3"].includes(question.answer)) {
                setSurveyStutas('uncomplete')
                saveResponses('uncomplete')
                return
            } else {
                setButton(false)
                setQuesNo(quesNo + 1)
                if (question?.note_que === "next_note_que") {
                    setButton(true)
                }
                setPreveiousQue(quesNo)
                setSerial(serial + 1)
                questionIndex.push(quesNo + 1)
                setQuestionIndex(questionIndex)
                if (surveyQues && surveyQues[quesNo + 1].dynamicOption) {
                    // setLoader(true)
                    let r = surveyQues.filter(e => { return surveyQues[quesNo + 1].dynamicOption === e._id })[0].answer
                    surveyQues[quesNo + 1].newOptions = surveyQues[quesNo + 1].options.filter((op) => {

                        if (op.filter && r.includes(op.filter)) {
                            return op
                        } else if (r.join('').includes(op.option)) {
                            if (op.text_box == 'yes') {
                                let newop = op
                                let result = r.find(item => item.startsWith(op.option));
                                newop.option = result
                                return newop
                            } else {
                                return op
                            }
                        }

                    })
                }
                return
            }
        }
        else if (question.type == 'matrix' && question.selection == 'multiple') {
            let tempAns = ""

            answer.forEach(ans => {
                let matAns = ""
                ans.answer.forEach(an => {
                    matAns = `${matAns} ${an},`
                })
                tempAns = `${tempAns}field:${ans.bank}-Answer:${matAns},`
            })
            finalAns = [tempAns]
        }
        else if (question.type == 'matrix' && (question.selection == 'input' || question.selection == "random")) {
            let temp = [...finalAns.map((ele) => { return JSON.stringify(ele) })]
            let set = []
            temp.forEach((ele, ind) => {
                ele = ele.split(',')
                set.push(ele.join("^"))

            })

            set = set.join(";")
            finalAns = [set]

        }
        else if (question.type == 'form' || question.type == 'contact') {

            let optionKeys = {}
            question.options.map((op) => {
                if (finalAns[0][op.option]) {
                    optionKeys[op.option] = finalAns[0][op.option]
                }
            })

            let tempAns = [];
            let fields = Object.keys(optionKeys)

            fields.forEach(f => {
                tempAns.push(`${f}:${optionKeys[f]}`)
            })
            finalAns = tempAns


        } else if (question.type == 'cxNote') {
            setQuesNo(quesNo + 1)
            questionIndex.push(quesNo + 1)
            setQuestionIndex(questionIndex)
            return
        }
        else if (question.type == 'rating' || question.type == 'nps') {
            setQuesNo(quesNo + 1)
        }

        if (question.matchTotal) {
            for (let m = 0; m < surveyQues.length; m++) {
                if (surveyQues[m]._id == question.matchTotal) {
                    let matchAns = finalAns

                    const numberArray = []
                    matchAns.forEach((e) => {
                        let ans = e.split(':')
                        numberArray.push(Number(ans[1]))
                    })

                    // const numberArray = numbers.map(Number);
                    const total = numberArray.reduce((sum, num) => sum + num, 0);
                    if (surveyQues[m].answer != total) {

                        toast.error('Total should be match', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        return
                    }
                }
            }
        }

        if (question.type == 'radio' || question.type == 'checkbox') {
            question.options.forEach(option => {
                if (option.textOpen === true) { option.textOpen = false }
            })
        }
        let newCount = maturitCount
        if (question.addMaturitCount) {
            question.options.forEach((op) => {
                if (finalAns.includes(op.option)) {
                    newCount = maturitCount + op.optionCount
                    setMaturitCount(newCount)
                }
            })
        }

        if (question.type == 'cxScore') {
            setQuesNo(quesNo + 1)
            questionIndex.push(quesNo + 1)
            setQuestionIndex(questionIndex)
            return
        } else if (surveyQues[quesNo + 1]?.type == 'cxScore') {
            surveyQues[quesNo + 1].answer = [newCount]
        }

        question.answer = finalAns;
        let finalActionId = actionId


        if (question.selection == "multiple") {
            let code = false
            question.options.forEach((op) => {
                if (op.trigger && actionId.includes(op.action_id) && !code) {
                    finalActionId = [op.action_id]
                    code = true

                }
            })
        }
        let clearContact = true

        if (quesNo + 1 === surveyQues.length || finalActionId.includes('final')) {

            setSurveyScreen(false)
            setSurveyStutas('complete')
            saveResponses('complete', newCount);
            return
        }
        if (finalActionId.includes("exit")) {
            setSurveyStutas('uncomplete')
            saveResponses('uncomplete')
            return
        } else if (finalActionId.includes("error")) {
            question.options.forEach(option => {
                if (option.action_id === 'error') {
                    toast.error(option.error_msg, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            setButton(false)
            return
        }
        else {

            if (question.type == 'contact' && answer[0]?.["Email ID"]) {
                if (!answer[0]?.["Email ID"].match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                    toast.error("Please enter valid email!", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return
                } else {
                    // if (question.type == 'contact' && answer[0]?.["Email ID"]) {
                    let data = { email: answer[0]?.["Email ID"] }
                    let result = await api.post(`${Config.new.site_api}/common/emailVerify`, data)
                    if (result.code !== 200) {
                        clearContact = false
                        toast.error(result.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        return
                    }
                }

            }
            if (question.type == 'contact' && clearContact && answer[0]["Phone No."] && !answer[0]?.["Phone No."].match(/^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/)) {

                clearContact = false
                toast.error("Please enter a valid phone number.", {
                    position: toast.POSITION.TOP_RIGHT
                });
                return
            }
            // let id = localStorage.getItem(`${survey._id}`)
            let id = responseId
            if (survey?.saveOnNext) {
                let newAnswer = []
                surveyQues.map(e => {
                    let queObj = {}
                    queObj._id = e._id
                    queObj.selection = e.selection
                    queObj.type = e.type
                    queObj.question = e.question
                    queObj.optionColumn = e.optionColumn
                    if (e.answer) {
                        queObj.answer = e.answer
                    }
                    newAnswer.push(queObj)
                })

                if (survey?.saveOnNext && id) {
                    if (props.match.path === '/npsSurvey/:id/rId=:id') {
                        // updateRequired = false
                        let params = props.match.params.id;
                        // setShareId(params)
                        let data = {
                            id: params,
                            client_id: survey.User_Id,
                            surveyName: survey.survey_name,
                            surveyId: survey._id ? survey._id : '',
                            surveyStatus: "unComplite",
                            questions: JSON.stringify(newAnswer),
                            surveyFor: "NPS",
                            updateRequired: true
                        }
                        let result = await api.post(`${Config.new.site_api}/survey/updateNpsSurvey`, data);
                        if (result.code == 200 && result.status === 'success') {
                            setLoader(false)

                            localStorage.setItem(survey._id, result.data._id)
                            setResponseId(result.data._id)
                        } else {
                            setSurveySubmited(true)
                            setSurveyScreen(false)
                            toast.error(result.message)
                            setLoader(false)
                            if (result.status == "Survey Already Submited") {
                                setSurveyStutas("Survey Already Submited")
                            }
                        }
                    } else {
                        var now = new Date();
                        var hours = now.getHours();
                        var minutes = now.getMinutes();
                        var seconds = now.getSeconds();
                        var milliseconds = now.getMilliseconds();
                        var timeString = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + milliseconds
                        var totalSeconds = (timeString - surveyStartTime) / 1000

                        setLoader(true)
                        let data = {
                            _id: id,
                            questions: JSON.stringify(newAnswer),
                            surveyId: survey._id ? survey._id : '',
                            surveyStatus: "unComplite",
                            shareId: shareId,
                            loi: totalSeconds
                        }
                        let result = await api.post(`${Config.new.site_api}/survey/updatedResponse`, data)
                        if (result.code == 200) {
                            // alert("done")
                            setLoader(false)
                        }
                    }
                } else {
                    if (survey.saveOnNext && (props.match.path === '/Survey/:id' || props.match.path === '/NewSurvey/:id' || props.match.path === '/lucidSurvey/:id')) {
                        var now = new Date();
                        var hours = now.getHours();
                        var minutes = now.getMinutes();
                        var seconds = now.getSeconds();
                        var milliseconds = now.getMilliseconds();

                        var timeString = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + milliseconds


                        var totalSeconds = (timeString - surveyStartTime) / 1000

                        let data = {
                            compltedSurveyNumber: 1,
                            pointAdd: survey.points ? survey.points : 0,

                            surveyName: survey.survey_name,
                            surveyId: survey._id ? survey._id : '',
                            surveyType: "response_only",
                            surveyStatus: "unComplite",
                            responseLimitName: "myntra",
                            shareId: shareId,
                            questions: JSON.stringify(newAnswer),
                            loi: totalSeconds
                        }
                        setLoader(true)

                        let result = await api.post(`${Config.new.site_api}/survey/SubmitSurveyOnlyResponse`, data);
                        if (result.code == 200 && result.status === 'success') {
                            setLoader(false)

                            localStorage.setItem(survey._id, result.data._id)
                            setResponseId(result.data._id)
                        } else {
                            setSurveySubmited(true)
                            setSurveyScreen(false)
                            toast.error(result.message)
                            setLoader(false)
                            if (result.status == "Survey Already Submited") {
                                setSurveyStutas("Survey Already Submited")
                            }
                        }
                    } else if (props.match.path === '/npsSurvey/:id/rId=:id') {

                        let params = props.match.params.id;

                        let data = {
                            id: params,
                            client_id: survey.User_Id,
                            surveyName: survey.survey_name,
                            surveyId: survey._id ? survey._id : '',
                            surveyStatus: "unComplite",
                            questions: JSON.stringify(newAnswer),
                            surveyFor: "NPS",
                            // updateRequired: true
                        }
                        let result = await api.post(`${Config.new.site_api}/survey/submitNpsSurvey`, data);
                        if (result.code == 200 && result.status === 'success') {
                            setLoader(false)

                            localStorage.setItem(survey._id, result.data._id)
                            setResponseId(result.data._id)
                        } else {
                            setSurveySubmited(true)
                            setSurveyScreen(false)
                            toast.error(result.message)
                            setLoader(false)
                            if (result.status == "Survey Already Submited") {
                                setSurveyStutas("Survey Already Submited")
                            }
                        }
                    } else if (props.match.path === '/fieldSurvey/:id') {

                    }
                }
            }

            if (finalActionId.length) {
                for (let i = 0; i < surveyQues.length; i++) {
                    if (finalActionId.includes(surveyQues[i]._id)) {

                        if (surveyQues[i].type === 'radio' || surveyQues[i].type == 'checkbox') {
                            surveyQues[i].options.forEach((op) => {
                                op.selectedOption = false
                            })


                        }
                        if (surveyQues[i].changeOPSequence) {
                            let newOptions = changeOPSequence(surveyQues[i].options)
                            surveyQues[i].options = newOptions
                        }
                        setPreveiousQue(quesNo)
                        if (question?.setNextQueOption && surveyQues[i].optionDynamic == true) {
                            setLoader(true)
                            let r = surveyQues.filter(e => { return question?.setNextQueOption === e._id })[0].answer
                            let data = {
                                usedFor: 'idfcCitywiseBankList',
                                matchFor: r[0]
                            }
                            let result = await api.post(`${Config.new.site_api}/common/getCommonUsed`, data)
                            setLoader(false)
                            if (result && result.code === 200) {

                                surveyQues[i].options = [...surveyQues[i].options, ...result.data[0].detail]
                            }
                        } else
                            if (surveyQues && surveyQues[i].dynamicOption) {
                                // setLoader(true)
                                let r = surveyQues.filter(e => { return surveyQues[i].dynamicOption === e._id })[0].answer
                                if (typeof (r) === 'string') {
                                    r = [r]
                                }
                                surveyQues[i].newOptions = surveyQues[i].options.filter((op) => {
                                    if (op.filter && r.includes(op.filter)) {
                                        return op
                                    } else if (r.join('').includes(op.option)) {
                                        if (op.text_box == 'yes') {
                                            let newop = op
                                            let result = r.find(item => item.startsWith(op.option));
                                            newop.option = result
                                            return newop
                                        } else {
                                            return op
                                        }
                                    }
                                })
                                if (surveyQues[i].secondDynamicOption) {
                                    let s = surveyQues.filter(e => { return surveyQues[i].secondDynamicOption === e._id })[0].answer
                                    if (typeof (s) === 'string') {
                                        s = [s]
                                    }
                                    surveyQues[i].newOptions = surveyQues[i].newOptions.filter((op) => {
                                        if (s.join('').includes(op.option) === false) {
                                            if (op.text_box == 'yes') {
                                                let newop = op
                                                let result = r.find(item => item.startsWith(op.option));
                                                newop.option = result
                                                return newop
                                            } else {
                                                return op
                                            }
                                        }
                                    })
                                }
                                if (surveyQues[i].newOptions.length == 0) {
                                    let rel = [...dependant_ques]
                                    if (rel.includes(surveyQues[quesNo + 1]._id)) {
                                        for (let z = quesNo + 1; z < surveyQues.length; z++) {
                                            if ((surveyQues[z].option_dependant && dependant_ques.includes(surveyQues[z]._id)) || !surveyQues[z].option_dependant) {
                                                if (surveyQues && surveyQues[z].dynamicOption) {
                                                    // setLoader(true)
                                                    let r = surveyQues.filter(e => { return surveyQues[i + 1].dynamicOption === e._id })[0].answer
                                                    surveyQues[i + 1].newOptions = surveyQues[i + 1].options.filter((op) => {
                                                        if (op.filter && r.includes(op.filter)) {
                                                            return op
                                                        } else if (r.join('').includes(op.option)) {
                                                            if (op.text_box == 'yes') {
                                                                let newop = op
                                                                let result = r.find(item => item.startsWith(op.option));
                                                                newop.option = result
                                                                return newop
                                                            } else {
                                                                return op
                                                            }
                                                        }

                                                    })
                                                    if (surveyQues[i + 1].secondDynamicOption) {
                                                        let s = surveyQues.filter(e => { return surveyQues[i + 1].secondDynamicOption === e._id })[0].answer
                                                        surveyQues[i + 1].newOptions = surveyQues[i + 1].newOptions.filter((op) => {
                                                            if (s.join('').includes(op.option) === false) {
                                                                return op
                                                            }
                                                        })
                                                    }
                                                }

                                                setQuesNo(z)
                                                questionIndex.push(z)
                                                break;
                                            }
                                        }
                                        break;
                                    } else {
                                        setQuesNo(i + 1)
                                        questionIndex.push(quesNo)

                                        localStorage.setItem('lastQueId', surveyQues[i + 1]._id)

                                        if (surveyQues && surveyQues[i + 1].dynamicOption) {
                                            // setLoader(true)
                                            let r = surveyQues.filter(e => { return surveyQues[i + 1].dynamicOption === e._id })[0].answer
                                            surveyQues[i + 1].newOptions = surveyQues[i + 1].options.filter((op) => {
                                                if (op.filter && r.includes(op.filter)) {
                                                    return op
                                                } else if (r.join('').includes(op.option)) {
                                                    if (op.text_box == 'yes') {
                                                        let newop = op
                                                        let result = r.find(item => item.startsWith(op.option));
                                                        newop.option = result
                                                        return newop
                                                    } else {
                                                        return op
                                                    }
                                                }

                                            })
                                            if (surveyQues[i + 1].secondDynamicOption) {
                                                let s = surveyQues.filter(e => { return surveyQues[i + 1].secondDynamicOption === e._id })[0].answer
                                                surveyQues[i + 1].newOptions = surveyQues[i + 1].newOptions.filter((op) => {
                                                    if (s.join('').includes(op.option) === false) {
                                                        return op
                                                    }
                                                })
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                        if (question.continue) {
                            let nextIndexTo = multiActId.filter((ele) => {
                                return ele.selected
                            })[0]?.id

                            if (nextIndexTo) {
                                for (let o = 0; o < surveyQues.length; o++) {

                                    if (surveyQues[o]._id == nextIndexTo) {
                                        // if (surveyQues[o].type == 'checkbox') {
                                        let mAcyid = [...multiActId]

                                        let index
                                        for (let y = 0; y < mAcyid.length; y++) {
                                            if (mAcyid[y].id == surveyQues[o]._id) {
                                                index = y
                                                break;
                                            }
                                        }
                                        if (index || index == 0) { mAcyid[index].selected = false }
                                        // mAcyid[index].selected = false
                                        setMultiActId(mAcyid)
                                        // }
                                        setQuesNo(o)
                                        questionIndex.push(o)
                                        break;
                                    } else if (surveyQues.length - 1 == o && !nextIndexTo) {
                                        let mAcyid = [...multiActId]
                                        let index
                                        for (let y = 0; y < multiActId.length; y++) {
                                            if (mAcyid[y].id == surveyQues[i]._id) {
                                                index = y
                                                break;
                                            }
                                        }
                                        if (index || index == 0) { mAcyid[index].selected = false }
                                        setMultiActId(mAcyid)

                                        setQuesNo(i);
                                        questionIndex.push(i)
                                    }
                                }
                            }
                            else {
                                for (let z = i; z < surveyQues.length; z++) {
                                    if ((surveyQues[z].option_dependant && dependant_ques.includes(surveyQues[z]._id)) || !surveyQues[z].option_dependant) {

                                        setQuesNo(z)
                                        questionIndex.push(z)
                                        break;
                                    } else if (surveyQues[z].next_dependant) {
                                        if (surveyQues[z].next_dependant === "exit") {
                                            setSurveyStutas('uncomplete')
                                            saveResponses('uncomplete')
                                            return
                                        }
                                        for (let r = quesNo; r < surveyQues.length; r++) {
                                            if (surveyQues[r]._id == surveyQues[z].next_dependant) {
                                                setQuesNo(r)
                                                questionIndex.push(r)
                                                break;
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                        } else {


                            let mAcyid = [...multiActId]
                            let index
                            for (let y = 0; y < multiActId.length; y++) {
                                if (mAcyid[y].id == surveyQues[i]._id) {
                                    index = y
                                    break;
                                }
                            }
                            if (index || index == 0) { mAcyid[index].selected = false }
                            setMultiActId(mAcyid)
                            let rel = [...dependant_ques]
                            if (rel.includes(surveyQues[quesNo + 1]._id)) {

                                for (let z = quesNo + 1; z < surveyQues.length; z++) {
                                    if ((surveyQues[z].option_dependant && dependant_ques.includes(surveyQues[z]._id)) || !surveyQues[z].option_dependant) {

                                        setQuesNo(z)
                                        questionIndex.push(z)
                                        break;
                                    }
                                }
                            } else {
                                // setQuesNo(i);
                                for (let z = i; z < surveyQues.length; z++) {
                                    if ((surveyQues[z].option_dependant && dependant_ques.includes(surveyQues[z]._id)) || !surveyQues[z].option_dependant) {

                                        setQuesNo(z)
                                        questionIndex.push(z)
                                        break;
                                    }
                                    else if (surveyQues[z].next_dependant) {
                                        if (surveyQues[z].next_dependant === "exit") {
                                            setSurveyStutas('uncomplete')
                                            saveResponses('uncomplete')
                                            return
                                        }
                                        for (let r = quesNo; r < surveyQues.length; r++) {
                                            if (surveyQues[r]._id == surveyQues[z].next_dependant) {
                                                setQuesNo(r)
                                                questionIndex.push(r)
                                                break;
                                            }
                                        }
                                        break;
                                    }
                                }
                            }
                            // questionIndex.continuepush(i)
                        }
                        if (surveyQues[i].type === 'note') {
                            noteButton = true
                        } else if (surveyQues[i].buttonNotRequired == true) {
                            noteButton = true
                        }
                        setQuestionIndex(questionIndex)
                        localStorage.setItem('lastQueId', surveyQues[i]._id)
                        break
                    }
                }
            }
            else {

                if (surveyQues[quesNo + 1].type === 'radio' || surveyQues[quesNo + 1].type == 'checkbox') {
                    surveyQues[quesNo + 1].options.forEach((op) => {
                        op.selectedOption = false
                    })
                }
                if (surveyQues[quesNo + 1].changeOPSequence) {
                    let newOptions = changeOPSequence(surveyQues[quesNo + 1].options)
                    surveyQues[quesNo + 1].options = newOptions
                }
                if (question?.match) {
                    let answer1
                    let answer2
                    for (let o = 0; o < surveyQues.length; o++) {
                        if (surveyQues[o]._id == question?.match) {
                            answer1 = surveyQues[o].answer
                        } else if (surveyQues[o].confilict) {
                            answer2 = surveyQues[o].answer
                        }
                        if (answer1 && answer2 && answer1.includes(answer2[0])) {
                            setQuesNo(quesNo + 2)
                            questionIndex.push(quesNo + 2)
                            break;
                        } else if (surveyQues.length === o + 1) {
                            setQuesNo(quesNo + 1)
                            questionIndex.push(quesNo + 1)
                        }
                    }

                } else if (question.answerLength && question.answerLength >= answer.length) {

                    setQuesNo(quesNo + question.skip)
                    questionIndex.push(quesNo + question.skip)
                } else if (question.continue) {
                    let nextIndexTo = multiActId.filter((ele) => { return ele.selected })[0]?.id

                    if (nextIndexTo) {
                        for (let o = 0; o < surveyQues.length; o++) {
                            if (surveyQues[o]._id == nextIndexTo) {

                                let mAcyid = [...multiActId]
                                let index
                                for (let y = 0; y < mAcyid.length; y++) {
                                    if (mAcyid[y].id == surveyQues[o]._id) {
                                        index = y
                                        break;
                                    }
                                }
                                if (index || index == 0) { mAcyid[index].selected = false }
                                setMultiActId(mAcyid)
                                setQuesNo(o)
                                questionIndex.push(o)
                                break;
                            }
                        }
                    }
                    else {
                        for (let z = quesNo + 1; z < surveyQues.length; z++) {
                            if ((surveyQues[z].option_dependant && dependant_ques.includes(surveyQues[z]._id)) || !surveyQues[z].option_dependant) {

                                setQuesNo(z)
                                questionIndex.push(z)
                                break;
                            }
                        }
                        // setQuesNo(quesNo + 1);
                        // questionIndex.push(quesNo + 1)
                    }
                }
                else {

                    // if (surveyQues[quesNo + 1].type == 'checkbox') {
                    let mAcyid = [...multiActId]
                    let index
                    for (let y = 0; y < mAcyid.length; y++) {
                        if (mAcyid[y].id == surveyQues[quesNo + 1]._id) {
                            index = y
                            break;
                        }
                    }
                    if (index || index == 0) { mAcyid[index].selected = false }
                    setMultiActId(mAcyid)



                    for (let z = quesNo + 1; z < surveyQues.length; z++) {

                        if ((surveyQues[z].option_dependant && dependant_ques.includes(surveyQues[z]._id)) || !surveyQues[z].option_dependant) {
                            setQuesNo(z)
                            questionIndex.push(z)
                            break;
                        } else if (surveyQues[z].next_dependant) {
                            if (surveyQues[z].next_dependant === "exit") {
                                setSurveyStutas('uncomplete')
                                saveResponses('uncomplete')
                                return
                            }
                            for (let r = quesNo; r < surveyQues.length; r++) {
                                if (surveyQues[r]._id == surveyQues[z].next_dependant) {
                                    setQuesNo(r)
                                    questionIndex.push(r)
                                    break;
                                }
                            }
                            break;
                        }
                    }

                }
                setPreveiousQue(quesNo)


                setQuestionIndex(questionIndex)
                setTemporaryAns([]);
                localStorage.setItem('lastQueId', surveyQues[quesNo + 1]._id)

                if (question?.setNextQueOption && surveyQues[quesNo + 1].optionDynamic == true) {
                    setLoader(true)
                    let r = surveyQues.filter(e => { return question?.setNextQueOption === e._id })[0].answer
                    let data = {
                        usedFor: 'idfcCitywiseBankList',
                        matchFor: r[0]
                    }
                    let result = await api.post(`${Config.new.site_api}/common/getCommonUsed`, data)
                    setLoader(false)
                    if (result && result.code === 200) {

                        surveyQues[quesNo + 1].options = [...surveyQues[quesNo + 1].options, ...result.data[0].detail]
                    }
                } else
                    if (surveyQues && surveyQues[quesNo + 1].dynamicOption) {
                        // setLoader(true)
                        let r = surveyQues.filter(e => { return surveyQues[quesNo + 1].dynamicOption === e._id })[0].answer
                        surveyQues[quesNo + 1].newOptions = surveyQues[quesNo + 1].options.filter((op) => {

                            if (op.filter && (r.includes(op.filter) || (op.text_box == 'yes' && r.join("").includes(op.filter)))) {
                                return op
                            } else if (r.join('').includes(op.option)) {
                                if (op.text_box == 'yes') {
                                    let newop = op
                                    let result = r.find(item => item.startsWith(op.option));
                                    newop.option = result
                                    return newop
                                } else {
                                    return op
                                }
                            }

                        })
                        if (surveyQues[quesNo + 1].secondDynamicOption) {
                            let s = surveyQues.filter(e => { return surveyQues[quesNo + 1].secondDynamicOption === e._id })[0].answer
                            surveyQues[quesNo + 1].newOptions = surveyQues[quesNo + 1].newOptions.filter((op) => {
                                if (s.join('').includes(op.option) === false) {
                                    if (op.text_box == 'yes') {
                                        let newop = op
                                        let result = r.find(item => item.startsWith(op.option));
                                        newop.option = result
                                        return newop
                                    } else {
                                        return op
                                    }
                                }
                            })
                        }

                        if (surveyQues[quesNo + 1].newOptions.length == 0) {
                            setQuesNo(quesNo + 2)
                            localStorage.setItem('lastQueId', surveyQues[quesNo + 2]._id)

                            if (surveyQues && surveyQues[quesNo + 2].dynamicOption) {
                                // setLoader(true)
                                let r = surveyQues.filter(e => { return surveyQues[quesNo + 2].dynamicOption === e._id })[0].answer
                                surveyQues[quesNo + 2].newOptions = surveyQues[quesNo + 2].options.filter((op) => {
                                    if (op.filter && r.includes(op.filter)) {
                                        return op
                                    } else if (r.join('').includes(op.option)) {
                                        if (op.text_box == 'yes') {
                                            let newop = op
                                            let result = r.find(item => item.startsWith(op.option));
                                            newop.option = result
                                            return newop
                                        } else {
                                            return op
                                        }
                                    }

                                })
                                if (surveyQues[quesNo + 2].secondDynamicOption) {
                                    let s = surveyQues.filter(e => { return surveyQues[quesNo + 2].secondDynamicOption === e._id })[0].answer
                                    surveyQues[quesNo + 2].newOptions = surveyQues[quesNo + 2].newOptions.filter((op) => {
                                        if (s.join('').includes(op.option) === false) {
                                            return op
                                        }
                                    })
                                }
                            }
                        }

                    }
                if (surveyQues[quesNo + 1].type === 'matrix' && surveyQues[quesNo + 1].dependance) {

                    let matHeaderNew = []
                    let indx
                    let filteredAnswer
                    surveyQues.filter((e, m) => {
                        if (e._id == surveyQues[quesNo + 1].dependance) {
                            indx = m
                            filteredAnswer = e.answer
                            return
                        }
                    })
                    surveyQues[indx].options.map(op => {
                        if (filteredAnswer.includes(op.option) && !op?.notComeInheader) {
                            matHeaderNew.push(op)
                        }
                    })

                    if (matHeaderNew.length === 0) {
                        setQuesNo(quesNo + 2)
                    }
                    setMatHeader(matHeaderNew)
                }
            }
            if (clearContact) { setButton(false) }

            if (question.note_que === "next_note_que" || noteButton) {
                setButton(true)
            }
        }
        if (question.type === 'radio') {
            if (eventRadio) {
                eventRadio.target.checked = false
                setTemporaryAns([]);
            }
        } else if (question.type == 'checkbox') {
            eventCheckbox.forEach((eve) => {
                eve.target.checked = false;
            })
            setTemporaryAns([]);
        }
        else if (question.type === 'matrix') {
            eventMatrix.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        else if (question.type === 'text' || question.type === 'time') {
            eventText.target.value = ""
            setTemporaryAns([]);
        } else if (question.type === 'rating' || question.type === 'nps' || question.type === 'csat') {
            setTemporaryAns([]);
        } else if (question.type === 'number' || question.type === 'date') {
            eventText.target.value = ""
            setTemporaryAns([]);
        }
        else if (question.type === 'form' || question.type == 'contact' && clearContact) {
            eventForm.forEach((eve) => {
                eve.target.value = "";
            })
            setTemporaryAns([]);
        }
        else if (question.type === 'matrix') {
            eventRadio.target.checked = false

        }
        if (question.dependency && question.dependency == 'yes') {

            setSavedAns(answer);
        }
        if (surveyQues[quesNo + 1]?.ansChecked) {
            setButton(true)
        }


        resetKeyboardInput()
        setTextBox(false)
        setActionId([])
        setRadioOption("")
        setAnslength([])
        setDoubleDrop([])
        setStarClass()
        setRatingValue(0)
        setSelectedCsat()
        setCheckBoxIndex([])
        setselectImage('no')
        setButtonIndex()
        setTextarea([])
        setBackQueAns([])
        document.querySelectorAll("#que").forEach((val) => {
            if (val.checked) {

                val.checked = false
            }
            val.value = ""
        })

        if (question?.nextAnsChecked) {
            let r = surveyQues.filter(e => { return e.setAnsChecked })[0].answer

            setTemporaryAns(r)

        }
        setSerial(serial + 1)

    }

    const setTemplate = () => {
        history.push(`/useTemplate/${id}`)
    }


    const changeOPSequence = arr => [...arr].sort(() => Math.random() - 0.5);



    const handleDownload = async (recordingBlob) => {

        var now = new Date();
        var hours = now.getHours();
        var minutes = now.getMinutes();
        var seconds = now.getSeconds();
        var milliseconds = now.getMilliseconds();

        var timeString = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + milliseconds


        var totalSeconds = (timeString - surveyStartTime) / 1000
        if (recordingBlob && survey.recordingRequired && props.match.path !== '/review/:id') {
            setLoader(true)

            let newData = new FormData()
            let data
            let uniqueId = uniqieId
            let audio = recordingBlob
            const reader = new FileReader();
            reader.readAsArrayBuffer(audio);
            reader.onloadend = async () => {
                // data = new File([reader.result], `${survey.survey_name}${Date.now()}.mp3`, { type: 'MP3/WAV/PCM/OGG/ALAC' })
                // newData.append('isAudio', 1)
                // newData.append('file', data);
                // newData.append("Id", uniqueId)
                // newData.append("surveyId", survey._id)
                // let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadImage`, newData)
                // if (result?.code === 200) {
                //     setLoader(false)
                //     toast.success('Upload successfully', {
                //         position: toast.POSITION.TOP_RIGHT
                //     });
                //     setFile('')
                //     SetImages([])
                // setTemporaryAns([uniqueId])
                // if (survey.recordingRequired) {
                // surveyQues.length
                let updateRequired = true
                let result
                let toa = false
                // let recordingQue = {
                //     "_id": "667ab5d5b63799003f27bc25",
                //     "question": "Recording url",
                //     "que_message": "",
                //     "options": [
                //         {
                //             "option": "",
                //             "action_id": "",
                //             "text_box": "no"
                //         }
                //     ],
                //     "matOption": [
                //         ""
                //     ],
                //     "s": 1,
                //     "maxCharacter": 255,
                //     "nccsCatgory": true,
                //     "selection": "single",
                //     "type": "recording",
                //     "attachment": "",
                //     "matDropdown": [],
                //     "condition": "required",
                //     "answer": uniqueId
                // }
                // surveyQues.push(recordingQue)

                // updateRequired = false
                // let data = {
                //     compltedSurveyNumber: 1,
                //     pointAdd: survey.points ? survey.points : 0,
                //     email: email.trim(),
                //     name: name,
                //     surveyName: survey.survey_name,
                //     surveyId: survey._id ? survey._id : '',
                //     surveyType: survey.survey_type,
                //     surveyStatus: status,
                //     responseLimitName: "myntra",
                //     trackSharePlatform: "manual",
                //     questions: JSON.stringify(surveyQues),
                //     loi: totalSeconds
                // }
                // result = await api.post(`${Config.new.site_api}/survey/SubmitFieldSurveyOnly`, data);


                if (props.match.path === '/Survey/:id' || props.match.path === '/NewSurvey/:id' || props.match.path === '/lucidSurvey/:id') {


                    let data = {
                        compltedSurveyNumber: 1,
                        pointAdd: survey.points ? survey.points : 0,
                        // email: email,
                        surveyName: survey.survey_name,
                        surveyId: survey._id ? survey._id : '',
                        surveyType: "response_only",
                        surveyStatus: surveyStutas,
                        responseLimitName: "myntra",
                        shareId: shareId,
                        questions: JSON.stringify(surveyQues),
                        loi: totalSeconds
                    }
                    setLoader(true)
                    // let id = localStorage.getItem(`${survey._id}`)
                    let id = responseId
                    if (survey?.saveOnNext && id) {
                        data["_id"] = id
                        result = await api.post(`${Config.new.site_api}/survey/updatedResponse`, data)
                        toa = true
                    } else {

                        result = await api.post(`${Config.new.site_api}/survey/SubmitSurveyOnlyResponse`, data);
                    }
                } else if (props.match.path === '/fieldSurvey/:id') {
                    updateRequired = false
                    let data = {
                        compltedSurveyNumber: 1,
                        pointAdd: survey.points ? survey.points : 0,
                        email: email.trim(),
                        name: name,
                        surveyName: survey.survey_name,
                        surveyId: survey._id ? survey._id : '',
                        surveyType: survey.survey_type,
                        surveyStatus: surveyStutas,
                        responseLimitName: "myntra",
                        trackSharePlatform: "manual",
                        questions: JSON.stringify(surveyQues),
                        loi: totalSeconds
                    }
                    result = await api.post(`${Config.new.site_api}/survey/SubmitFieldSurveyOnly`, data);
                }
                if (result?.code === 200 && result?.status == 'success') {

                    localStorage.removeItem(`${survey._id}`)
                    toast.success(result.message)
                    setSurveySubmited(true)
                    setSurveyScreen(false)
                    setLoader(false)
                    if (survey.recordingRequired) {
                        const audioBlob1 = new Blob([audio], { type: 'audio/mp3' });
                        const url = URL.createObjectURL(audioBlob1);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `${survey.survey_name}-${responseId}-${Date.now()}.mp3`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        URL.revokeObjectURL(url);

                        data = new File([reader.result], `${survey.survey_name}-${responseId}-${Date.now()}.mp3`, { type: 'MP3/WAV/PCM/OGG/ALAC' })
                        newData.append('isAudio', 1)
                        newData.append('file', data);
                        newData.append("Id", responseId)
                        newData.append("surveyId", survey._id)
                        let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadImage`, newData)
                        if (result?.code === 200) {
                            setLoader(false)
                            toast.success('Upload successfully', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setFile('')
                            SetImages([])

                        }
                    }
                } else if (result) {
                    setSurveySubmited(true)
                    setSurveyScreen(false)
                    toast.error(result.message)
                    // if (updateRequired === true) { updateCount() }
                    setLoader(false)
                }
            }
            // }
            // }

        } else if (props.match.path === '/review/:id') {
            setSurveySubmited(true)
            setSurveyScreen(false)

            setLoader(false)
        }
        // const url = URL.createObjectURL(recordingBlob);
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = url;
        // a.download = 'recording.wav';
        // document.body.appendChild(a);
        // a.click();
        // URL.revokeObjectURL(url);
        // }
    };
    const handleStopRecording = (status) => {
        // stopRecording();
        // setShouldDownload(status); // Set flag to download once blob is available
        // handleDownload(status)
    };

    const submitEmail = () => {

        if (!email) {
            toast.error("Please Enter Email", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            toast.error("Please Enter Valid Email", {
                position: toast.POSITION.TOP_RIGHT
            });

        } else if (!name) {
            toast.error("Please Enter Your Name", {
                position: toast.POSITION.TOP_RIGHT
            });

        }
        else {
            // alert("dddd")
            setEmailScreen(false)
            { (props.match.path === '/attemp-survey/:id') && registerIfNewUser() }
            setLoader(true)
            surveyDynamic()
        }
    }

    const registerIfNewUser = async () => {
        let data = {
            name: name,
            email: email,
            device: "survey"
        }
        setLoader(true)
        const result = await api.post(`${Config.new.site_api}/client/registerByEmail`, data)
        if (result.code === 200) {
            // surveyDynamic()
            setLoader(false)
        }
        else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        }
    }

    const updateCount = async () => {

        survey.questions.map((ans) => (
            ans.answer && delete ans.answer
        ))
        setLoader(true)

        // const result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(survey) })
        setLoader(false)
    }
    const startSurvey = async (id) => {
        try {
            let data = {
                response_id: id,
                surveyId: survey._id,
                Respondent_router_entry_time: new Date(),
                Session_entry_time: new Date(),
                User_Id: survey.User_Id,
                supplier_name: '',
                Complete_time: ""
            }
            await api.post(`${Config.new.site_api}/client-survey/rederictResponse`, data)
            history.push(`${location.pathname}/surveyId=${id}`)
        } catch (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const checkMicPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setSurveyScreen(true)
            // Remember to stop the tracks to avoid media stream usage
            //   stream.getTracks().forEach(track => track.stop());
        } catch (error) {
            setSurveyScreen(false)
            if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                toast.error('Mic Permission denied');
            } else if (error.name === 'NotFoundError') {
                toast.error('Mic not found');
            } else {
                toast.error('Something Went Wrong');
            }
        }
    }

    const surveyDynamic = async () => {
        setLoader(true)
        let data
        let result
        if (props.match.path === '/fieldSurvey/:id') {
            data = {
                survey_id: props.match.params.id,
                email: ""
            }
        } else if (props.match.path == '/npsSurvey/:id/rId=:id') {
            let path = location.pathname.split('/')
            data = {
                survey_id: path[2],
                nps: props.match.params.id,
                email: ""
            }
        }
        else {
            data = {
                survey_id: props.match.params.id,
                email: email
            }
        }


        result = await api.post(`${Config.new.site_api}/survey/getDynSurveyAttemp`, data)
        if (result && result.code === 200) {
            if (result.data[0].recordingRequired) {
                // setRecordingStatus('recording')
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then(() => {
                        console.log('Permission Granted');
                        recorder.start().then(() => {
                        }).catch((e) => console.error(e));
                    })
                    .catch(() => {
                        console.log('Permission Denied');

                    });
            }
            setSurveys(result.data[0])
            setSurveyType(result.data[0].type)
            if (result.data[0].response_Limit === 0) {
                toast.error("This Survey Is Closed", {
                    position: toast.POSITION.TOP_RIGHT
                });
                setSurveyScreen(false)
            } else if (result.data[0]?.is_client === 1) {
                setSurveyScreen(false)
                startSurvey(uniqieId)
            } else {
                let updateResult = result.data[0]
                let id = localStorage.getItem(`${updateResult._id}`)
                // if (updateResult?.saveOnNext && id) {
                //     let lastQueId = localStorage.getItem(`${'lastQueId'}`)
                //     let body = {
                //         _id: updateResult._id
                //     }
                //     let newSurvey = await api.post(`${Config.new.site_api}/survey/getOldResponse`, body)
                //     if (newSurvey.code == 200) {
                //         result = newSurvey
                //         updateResult = result.data[0]

                //         result.data[0].questions.forEach((que, index) => {
                //             if (que._id === lastQueId) {
                //                 setQuesNo(index)
                //             }
                //         })
                //     }
                // }
                if (updateResult.questions[0].type === 'note') {
                    setButton(true)
                }

                if (result.data[0]?.logo) {
                    let array = []
                    array.push(result.data[0].logo)
                    let url = { "url": JSON.stringify(array) }
                    let logo = await api.post(`${Config.new.site_api}/client-survey/getImageUrl`, url)

                    if (logo?.code === 200) {
                        updateResult.logo = logo.data[0]
                    }
                }
                updateResult.questions.forEach((que) => {
                    que.options.forEach(async (op) => {
                        if (op?.imageUrl) {
                            let url = { "url": JSON.stringify([op?.imageUrl]) }
                            let opImage = await api.post(`${Config.new.site_api}/client-survey/getImageUrl`, url)
                            if (opImage.code === 200) {
                                op.imageUrl = opImage.data[0]
                            }
                        }
                    })
                })
                // }
                setSurveys(updateResult)

                if (result.data[0].recordingRequired) {
                    checkMicPermission()
                }
                else {
                    setSurveyScreen(true)
                }
            }

            setSurveyQues(result.data[0].type === "matrix" ? result.data[0].questions[0] : result.data[0].questions)
            setLoader(false)

        }
        else {
            if (result.message === 'Feedback already received') {
                setLoader(false)
                setSurveySubmited(true)
                setSurveyStutas('Survey Already Submited')
            }

            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        }
    }

    const saveProfile = async (user) => {
        setLoader(true)
        const data = {
            email: user.email,
            type: survey.survey_name,
            attempQuestionTask: JSON.stringify(surveyQues),
            profileCompletionPoint: 0,
            newPercentage: profilePercent + 10
        }
        const result = await api.post(`${Config.new.site_api}/user-panel-profile/userProfileTaskSubmit`, data);
        if (result) {
            setTimeout(() => {
                if (result.code === 200) {
                    setLoader(false)
                    setSurveyScreen(false)
                    toast.success('Profile Saved Succesfully', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    window.location.reload();
                } else {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }, 100)
        }
    }


    const saveResponses = async (status, maturityCount) => {

        let newAnswer = []
        surveyQues.map(e => {
            let queObj = {}
            queObj._id = e._id
            queObj.selection = e.selection
            queObj.type = e.type
            queObj.question = e.question
            queObj.optionColumn = e.optionColumn

            if (e.answer) {
                queObj.answer = e.answer
            }
            newAnswer.push(queObj)
        })
        // if (survey.maturity_category) {
        //     let newQue = {
        //         "_id": "667ae944d0790e34d403c3e8",
        //         "question": "CX Maturity Assessment",
        //         "que_message": "",
        //         "options": [
        //             {
        //                 "option": "",
        //                 "action_id": "",
        //                 "text_box": "no",
        //                 "textOpen": false
        //             }
        //         ],
        //         "matOption": [
        //             ""
        //         ],
        //         "layout": 1,
        //         "matDropdown": [],
        //         "condition": "required",
        //         "answer": [
        //             Object.entries(survey.maturity_category).find(([key, range]) => maturityCount >= range[0] && maturityCount <= range[1])[0], maturityCount
        //         ]
        //     }

        //     newAnswer.push(newQue)
        // }
        if (props.match.path === '/review/:id' || props.match.path === '/template/:id') {
            setEmailScreen(false);
            setSurveySubmited(true);
            setLoader(false)
            setSurveyScreen(false)
            return
        }
        var now = new Date();
        var hours = now.getHours();
        var minutes = now.getMinutes();
        var seconds = now.getSeconds();
        var milliseconds = now.getMilliseconds();

        var timeString = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + milliseconds


        var totalSeconds = (timeString - surveyStartTime) / 1000

        setLoader(true)
        let user = JSON.parse(localStorage.getItem('user'))
        let emailId = (props.match.params.id) ? email : user.email;
        if (type == 'profile') {
            saveProfile(user)

        }
        else {
            let updateRequired = true
            let result
            let toa = false
            let recordingRequiredBlob
            if (survey.recordingRequired) {
                setRecordingStatus('inactive')

                recorder.stop().getMp3().then(([buffer, blob]) => {

                    recordingRequiredBlob = blob

                }).catch((e) => console.log(e));

            }
            if (props.match.path === '/Survey/:id' || props.match.path === '/NewSurvey/:id' || props.match.path === '/lucidSurvey/:id') {


                let data = {
                    compltedSurveyNumber: 1,
                    pointAdd: survey.points ? survey.points : 0,
                    // email: email,
                    surveyName: survey.survey_name,
                    surveyId: survey._id ? survey._id : '',
                    surveyType: "response_only",
                    surveyStatus: status,
                    responseLimitName: "myntra",
                    shareId: shareId,
                    questions: JSON.stringify(newAnswer),
                    loi: totalSeconds
                }
                setLoader(true)
                // let id = localStorage.getItem(`${survey._id}`)
                let id = responseId
                if (survey?.saveOnNext && id) {
                    data["_id"] = id
                    result = await api.post(`${Config.new.site_api}/survey/updatedResponse`, data)
                    toa = true
                } else {

                    result = await api.post(`${Config.new.site_api}/survey/SubmitSurveyOnlyResponse`, data);
                }
            } else if (props.match.path === '/fieldSurvey/:id') {
                updateRequired = false
                let data = {
                    compltedSurveyNumber: 1,
                    pointAdd: survey.points ? survey.points : 0,
                    email: email.trim(),
                    name: name,
                    surveyName: survey.survey_name,
                    surveyId: survey._id ? survey._id : '',
                    surveyType: survey.survey_type,
                    surveyStatus: status,
                    responseLimitName: "myntra",
                    trackSharePlatform: "manual",
                    questions: JSON.stringify(newAnswer),
                    loi: totalSeconds
                }
                result = await api.post(`${Config.new.site_api}/survey/SubmitFieldSurveyOnly`, data);
            }
            else if (props.match.path === '/npsSurvey/:id/rId=:id') {
                updateRequired = false
                let params = props.match.params.id;

                let data = {
                    id: params,
                    client_id: survey.User_Id,
                    surveyName: survey.survey_name,
                    surveyId: survey._id ? survey._id : '',
                    surveyStatus: status,
                    questions: JSON.stringify(newAnswer),
                    surveyFor: "NPS",
                    answeredLanguage: selectedLang
                    // updateRequired: true
                }
                // if (survey?.saveOnNext && localStorage.getItem(`${survey._id}`))
                if (survey?.saveOnNext && responseId) {
                    result = await api.post(`${Config.new.site_api}/survey/updateNpsSurvey`, data);
                    toa = true
                } else {
                    result = await api.post(`${Config.new.site_api}/survey/submitNpsSurvey`, data);
                }
            }
            else {
                let data = {
                    compltedSurveyNumber: 1,
                    pointAdd: survey.points ? survey.points : 0,
                    email: email.trim(),
                    name: name,
                    surveyName: survey.survey_name,
                    surveyId: survey._id ? survey._id : '',
                    surveyType: survey.survey_type,
                    surveyStatus: status,
                    responseLimitName: "myntra",
                    trackSharePlatform: "manual",
                    questions: JSON.stringify(newAnswer),
                    loi: totalSeconds
                }
                result = await api.post(`${Config.new.site_api}/survey/SubmitSurvey`, data);
            }

            if (result?.code === 200 && result?.status == 'success') {
                setSurveySubmited(true)
                setSurveyScreen(false)


                if (toa) {
                    toast.success("Survey submitted")
                    localStorage.removeItem(`${survey._id}`)
                } else {
                    toast.success(result.message)
                }
                if (updateRequired === true) { updateCount() }
                setLoader(false)
                if (survey.recordingRequired) {
                    let newData = new FormData()
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(recordingRequiredBlob);
                    reader.onloadend = async () => {
                        const url = URL.createObjectURL(recordingRequiredBlob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `${survey.survey_name}-${responseId}-${Date.now()}.mp3`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        URL.revokeObjectURL(url);

                        let data = new File([reader.result], `${survey.survey_name}-${responseId}-${Date.now()}.mp3`, { type: 'MP3/WAV/PCM/OGG/ALAC' })
                        newData.append('isAudio', 1)
                        newData.append('file', data);
                        newData.append("Id", responseId)
                        newData.append("surveyId", survey._id)
                        let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadImage`, newData)
                        if (result?.code === 200) {
                            setLoader(false)
                            toast.success('Upload successfully', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setFile('')
                            SetImages([])

                        }
                    }
                }
                // getAvailableSurveys()
            } else if (result) {
                setSurveySubmited(true)
                setSurveyScreen(false)

                toast.error(result.message)
                if (updateRequired === true) { updateCount() }
                setLoader(false)
            }
        }
    }

    let lucidUrl
    useEffect(() => {
        setAudioType("audio/mp3");
        let userData = JSON.parse(localStorage.getItem('user'));
        setUserData(userData);
        lucidUrl = props.match.path
        if (lucidUrl === '/Survey/:id' || lucidUrl === '/NewSurvey/:id' || props.match.path === '/lucidSurvey/:id' || lucidUrl == '/review/:id' || lucidUrl === '/template/:id' || lucidUrl === '/npsSurvey/:id/rId=:id') {
            setEmailScreen(false)
            surveyDynamic()
            let params = new URLSearchParams(search).get('shareId');
            setShareId(params)

        }
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const milliseconds = now.getMilliseconds();

        var surveyStartTime = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + milliseconds
        setSurveyStartTime(surveyStartTime)
    }, [])


    const getSurveyLangCode = async (e) => {
        setSelectedLang(e.target.value)

        if (e.target.value == 'en') {
            setDisplayKeys({ ...displayKeys, question: 'question', option: 'option', que_message: 'que_message' })
            return
        }
        else {
            setDisplayKeys({ ...displayKeys, question: e.target.value !== "" ? `question_${e.target.value}` : 'question', option: e.target.value !== "" ? `option_${e.target.value}` : 'option', que_message: e.target.value !== "" ? `que_message_${e.target.value}` : 'que_message' })
        }
    }

    const checkKey = (event, type, option) => {


        if (type === "contact" && (option.option === 'First Name' || option.option === 'Last Name' || option.option === 'Name') && !((event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 8)) {
            var key = event.keyCode
            if (!((key >= 65 && key <= 90) || key == 8)) {
                event.preventDefault()

            }
        }
    }

    const getdependentValues = async (question, option, event) => {
        let data = {
            usedFor: option.key,
            matchFor: event
        }
        let result = await api.post(`${Config.new.site_api}/common/getCommonUsed`, data)
        if (result && result.code === 200) {
            question.options[option.setOn].dropdown_option = result.data[0].detail
        } else {
            toast.error(result.message)
        }
    }
    // console.log(answer, "answer", answer.length)
    const setAnswer = async (event, type, option, question, selectedColumn, selection) => {
        // console.log(event, "event")
        setBackQueAns([])
        setTextarea([])

        if (type === 'rating') {
            setSelectedCsat(event);
            setStarClass(event)

            setButton(true)
            setRatingValue(event)
            setTemporaryAns(event)

            if (option.options && option.options.map((op, index) => {
                if (op.action_id != "" && index + 1 == event) {
                    let acId = []
                    acId.push(op.action_id)
                    setActionId(acId)
                }
            }))
                if (surveyQues[quesNo]?.layout == 8 || surveyQues[quesNo].nextOnSelect) {
                    setTimeout(() => {
                        goToNextQuestion(surveyQues[quesNo], event)
                    }, 500)
                }
            return
        }



        let actId = [...actionId]
        let muiltactid = [...multiActId]
        if (option.action_id && option.action_id != "") {
            if (type == 'video' || event.target.checked || type == 'form' || type == 'text' || type === "date" || type === "number" || type === "csat") {
                if (type === "radio" && actId.length > 0) {
                    actId = []
                    actId.push(option.action_id)

                } else if (type === 'checkbox' && option.setRequired === true) {

                    muiltactid.push({ id: option.action_id, selected: true })

                    actId.push(option.action_id)

                } else {
                    actId.push(option.action_id)

                }
            } else {

                if (option.trigger) {
                    let index = actId.indexOf(option.action_id)
                    actId.splice(index, 1)

                } else {
                    let index = actId.indexOf(option.action_id)
                    actId.splice(index, 1)

                }
                // if (type === 'checkbox' && option.setRequired === true) {
                let index
                for (let y = 0; y < multiActId.length; y++) {
                    if (muiltactid[y].id == option.action_id) {
                        index = y
                        break;
                    }
                    // }
                }
                if (index || index == 0) { muiltactid.splice(index, 1) }
            }
        }
        setMultiActId(muiltactid)
        setActionId(actId)


        if (option.count_limit && (option.count || option.count == 0)) {
            if (option.count >= option.count_limit) {
                setActionId("exit")
            } else {
                // option.count += 1

            }
        }
        let related_questionCheck = []
        question && question.options && question.options.map((e) => {
            if (e.related_question) {
                related_questionCheck.push(e.related_question)
            }
        })
        if (related_questionCheck.length > 0) {
            let rel = [...dependant_ques]
            if (type == 'radio') {
                question && question.options && question.options.map((e) => {
                    if (e.related_question && rel.includes(e.related_question)) {
                        rel.splice(rel.indexOf(e.related_question, 1))
                    }
                })
            }
            if (event.target.checked && option.related_question) {
                for (let t = 0; t < option.related_question.length; t++) {

                    rel.push(option.related_question[t])
                }
            } else if (option.related_question) {
                for (let t = 0; t < option.related_question.length; t++) {

                    rel.splice(rel.indexOf(option.related_question[t]), 1)
                }
            } else if (option.exclusive) {
                for (let y = 0; y < question.options.length; y++) {
                    if (question.options[y].related_question) {
                        for (let t = 0; t < question.options[y].related_question.length; t++) {
                            if (rel.includes(question.options[y].related_question[t])) {
                                rel.splice(rel.indexOf(question.options[y].related_question[t]), 1)
                            }
                        }
                    }
                }
            }

            setDependentQues(rel)
        }

        if (type == "radio") {

            if (option.Imagerequired === 'yes' || option.option == 'Yes') {
                setselectImage('yes')
            } else if (option.Audiorequired === 'yes' || option.option == 'Yes') {
                setselectImage('yes')
            }
            else if (option.Imagerequired === 'yes' || option.option == 'No') {
                setselectImage('no')
            }
            if (option.TataImagerequired === "yes") {
                setButtonIndex(selectedColumn)
                setselectImage('yes')
            }
            question.options.forEach((op, index) => {
                if (selectedColumn !== index && op.textOpen === true) {
                    op.textOpen = false
                }
            })
            setEvent(event)
        } else if (type == "checkbox") {
            eventCheckbox.push(event);
            setEventCheckbox(eventCheckbox)
        }
        else if (type == "matrix") {
            eventMatrix.push(event);
            setEventMatrix(eventMatrix)
        }
        else if (type === "form" || type === "contact") {
            eventForm.push(event)
            setEventForm(eventForm)
        }
        else {

            setEventText(event)
        }


        let ans = []

        if (type == 'checkbox' || question?.type == 'checkbox') {

            if (answer.length < 0) {
                setButton(false)
            }
            if (option.exclusive && !event.target.checked) {
                setOptionExclusive([])
                // setTemporaryAns([])
            }

            if (option && (option.text_box == 'yes' || option.text_box == 'dropdown')) {
                if (option.text_box == 'yes') {
                    option.textOpen = true
                } else {
                    option.dropdown = true
                }
                setTextBox(true)
                let { value, checked } = event.target;
                let ansIndex = [...checkBoxIndex]
                if (option.text_box == 'yes' && option.option_type && type == 'text') {
                    event.checked = undefined
                    checked = undefined
                }

                setButton(false)
                let valueBlankCheckedUndefined = false
                if (value.trim() == "" || (checked == true) || value == null || value == undefined) {

                    if (!ansIndex.includes(option.option)) {
                        ansIndex[answer.length] = option.option
                    }
                    setCheckBoxIndex(ansIndex)
                    let ans = [...answer]
                    ans.push(option.option)
                    if (checked) { setTemporaryAns(ans) }

                    setButton(false)
                    valueBlankCheckedUndefined = true
                    option.selectedOption = true
                    if (value.trim() == "") {
                        let findIndex
                        answer.forEach((an, i) => {
                            if (an.includes(option.option)) {
                                findIndex = i
                            }

                        })
                        answer[findIndex] = `${option.option}:${event.target.value}`
                    }
                }
                else if (checked == false) {
                    let findIndex
                    answer.forEach((an, i) => {
                        if (an.includes(option.option)) {
                            findIndex = i
                        }
                        question.options.forEach((e, index) => {
                            if (e.text_box == 'yes' && an.includes(e.option) && event.target.value == e.option) {
                                // valueBlankCheckedUndefined = true
                            } else if (e.text_box == 'yes' && an.includes(e.option) && !an.includes(':')) {
                                valueBlankCheckedUndefined = true
                            }
                            if (e.text_box == 'yes' && an.includes(e.option) && an.includes(':') && an.split(':')[1]?.length == 0) {
                                valueBlankCheckedUndefined = true
                            }

                        })
                    })
                    answer.splice(findIndex, 1);
                    ansIndex.splice(ansIndex.indexOf(option.option), 1)

                    setCheckBoxIndex(ansIndex)
                    option.textOpen = false
                    option.selectedOption = false

                }
                else {
                    let but = true
                    let findIndex
                    answer.forEach((an, i) => {
                        if (an.includes(option.option)) {
                            findIndex = i
                        }
                        question.options.forEach((e, index) => {
                            if (e.text_box == 'yes' && an.includes(e.option) && !an.includes(':')) {
                                valueBlankCheckedUndefined = true
                            }
                            if (e.text_box == 'yes' && an.includes(e.option) && an.includes(':') && an.split(':')[1]?.length == 0) {
                                valueBlankCheckedUndefined = true
                            }

                        })
                    })

                    answer[findIndex] = `${option.option}:${event.target.value}`
                    if (checkboxCount == 0) {
                        setCount(1)
                    }
                    if (but) {
                        setButton(true)
                    }
                }

                if (!event.target.checked) {
                    if (answer.length > 0 && !valueBlankCheckedUndefined) {
                        setButton(true)
                    }
                    else {
                        setButton(false)
                    }
                }
                return
            }
            else {
                if (answer.includes(event.target.value)) {
                    let index = answer.indexOf(event.target.value)
                    answer.splice(index, 1);
                    option.selectedOption = false
                    // question.options.forEach((e, index) =>
                    for (let v = 0; v < question.options.length; v++) {
                        if (question.options[v].text_box == 'yes') {
                            let i = checkBoxIndex.indexOf(question.options[v].option);
                            if ((i == -1 || answer[i].split(':')[1].length && answer[i].split(':')[1].length > 0) && checkBoxIndex.includes(question.options[v].option)) {

                                setButton(true)

                            }
                            else if (answer.length == 0) {
                                setButton(false)
                                break
                            }
                            else {
                                setButton(false)
                                break
                            }
                        } else if (answer.length === 0) {
                            setButton(false)
                            // break
                            return
                        }
                    }


                }
                else {

                    setTemporaryAns([...answer, event.target.value])
                    let ans = [...answer, event.target.value]
                    option.selectedOption = true
                    let ansIndex = [...checkBoxIndex]
                    if (!ansIndex.includes(option.option)) {
                        ansIndex[answer.length] = option.option
                    }
                    setCheckBoxIndex(ansIndex)
                    // let i = checkBoxIndex.indexOf('Other');
                    let but = true
                    for (let v = 0; v < question.options.length; v++) {
                        // if (question.options[v].text_box == 'yes') {
                        if (checkBoxIndex.length == 0) {
                            // setButton(true)
                            but = true
                        } else if (ansIndex.includes(question.options[v].option)) {

                            // }
                            let i = ansIndex.indexOf(question.options[v].option);
                            // if ( (ans[i].includes(':')&&(ans[i].split(':')[1] && ans[i].split(':')[1]?.length > 0))) {
                            //     // setButton(true)
                            // }else 
                            // if (!ans[i].includes(':')){

                            // }
                            // else
                            if (question.options[v].text_box === 'yes' && (!ans[i].includes(':') || (ans[i].split(':')[1] == undefined || ans[i].split(':')[1]?.length == 0))) {
                                but = false
                            }
                            //  else {
                            //     // setButton(false)
                            //     but=false
                            // }

                        }

                    }
                    setButton(but)
                }

            }
            if (!event.target.checked) {

                // let i = checkBoxIndex.indexOf('Other');
                let flag = true
                for (let v = 0; v < question.options.length; v++) {
                    if (question.options[v].text_box == 'yes') {
                        let i = checkBoxIndex.indexOf(question.options[v].option);
                        if (!(answer.length > 0 && (i == -1 || answer[i].split(':')[1] && answer[i].split(':')[1].length > 0))) {
                            // setButton(true)
                            flag = false
                            break
                        }

                    }
                }
                if (flag) {
                    setButton(true)
                }
                else {
                    setButton(false)
                }
            }



        }
        else if (type == 'matrix') {
            setActionId([])
            if (question.selection == 'single') {

                let index = 0
                let flag = false;
                for (let i = 0; i < answer.length; i++) {
                    if (answer[i].bank == selectedColumn) {
                        index = i;
                        flag = true;
                        break
                    }
                }

                if (flag) {
                    answer.splice(index, 1);
                    flag = false
                }
                let ans = { bank: selectedColumn, answer: event.target.value }
                if (answer.length + 1 != selection.length) {
                    setButton(false)
                } else {
                    setButton(true)
                }
                setTemporaryAns([...answer, ans])

            }
            else {
                let sameIndex = 0
                let bankMatch = false
                for (let i = 0; i < answer.length; i++) {

                    let a = typeof (answer[i])
                    if (answer[i].bank == selectedColumn && question.selection != "input" && question.selection != "random" && question.selection == "multiple") {
                        sameIndex = i
                        bankMatch = true

                        break
                    }

                    else if ((question.selection == "input" || question.selection == "random") && answer[i].bank == selectedColumn && answer[i].quality == option.option) {

                        sameIndex = i
                        bankMatch = true

                        break

                    }
                }

                if (bankMatch) {
                    if (event.target.checked == true || question.selection == 'dropdown' || question.selection == 'input' || question.selection == "random") {
                        if (question.selection == 'input' || question.selection == "random") {
                            if (event.target.value.trim() == "") {
                                answer.splice(sameIndex, 1)
                                setButton(false)
                            } else {
                                answer[sameIndex].answer = event.target.value

                            }
                        } else if (question.selection == 'multiple') {
                            answer[sameIndex].answer.push(option.option);
                        }

                    } else if (question.selection == 'multiple') {
                        let targetIndex = answer[sameIndex].answer.indexOf(option.option)

                        answer[sameIndex].answer.splice(targetIndex, 1)
                        if (answer[sameIndex].answer.length == 0) {
                            answer.splice(sameIndex, 1)
                            setButton(false)
                        }
                    }
                    else {
                        let targetIndex = answer[sameIndex].answer.indexOf(event.target.value)

                        answer[sameIndex].answer.splice(targetIndex, 1)
                    }
                    if (selection) {

                        if (answer.length == selection.length) {
                            setButton(true)
                        } else {
                            setButton(false)
                        }
                    }
                }
                else {
                    if (event.target.checked == true || question.selection == 'dropdown' || question.selection == 'input' || question.selection == "random") {

                        let an = { bank: selectedColumn, answer: [] }
                        if (question.selection == 'multiple') {
                            an = { bank: selectedColumn, answer: [] }
                            an.answer.push(option.option)
                            // an.quality = option.option
                            answer.push(an)

                            if (selection) {
                                if (answer.length == selection.length) {
                                    setButton(true)
                                } else {
                                    setButton(false)
                                }

                            } else {
                                if (answer.length + 1 != (question.options.length)) {
                                    setButton(false)
                                } else {
                                    setButton(true)
                                }
                            }
                            // setTemporaryAns([...answer, b])
                        }
                        else if (question.selection == 'input' || question.selection == "random") {
                            an.answer = event.target.value
                            an.quality = option.option
                            let b = { ...an }
                            if (answer.length + 1 != (question.options.length * matHeader.length)) {
                                setButton(false)
                            } else {
                                setButton(true)
                            }
                            setTemporaryAns([...answer, b])
                        }
                        else {
                            an.answer.push(event.target.value)
                            setTemporaryAns([...answer, an])
                        }

                    }
                }

            }
        }

        else if (type === 'form' || type === "contact") {

            let temp = answer.length > 0 ? answer[0] : {}


            if (selectedColumn) {
                doubleDrop[1] = event.target.value
            }
            else {
                doubleDrop[0] = event.target.value
            }

            // temp[option.option] = option.text_box != 'yes' ? doubleDrop : event.target.value
            temp[option.option] = event.target.value
            if (type === 'form' && option.function_call) {
                // getdependentValues(question,option,event.target.value)
                let data = {
                    usedFor: option.key,
                    matchFor: event.target.value
                }
                let result = await api.post(`${Config.new.site_api}/common/getCommonUsed`, data)
                if (result && result.code === 200) {
                    question.options[option.setOn].dropdown_option = result.data[0].detail

                } else {
                    toast.error(result.message)
                }
            }
            setTemporaryAns([temp])


            let keyInAnswer = Object.keys(temp)

            let notEmpty = true
            keyInAnswer.forEach(op => {
                if (!temp[op] || temp[op] == "") {
                    notEmpty = false
                }
            })

            surveyQues[quesNo].options.forEach((op) => {
                if (op.option_type === 'number' && temp?.[op.option] && op?.minLength && op?.minLength > temp[op.option].length) {
                    notEmpty = false
                }
            })
            if ((surveyQues[quesNo].newOptions || surveyQues[quesNo].options).length === keyInAnswer.length && notEmpty) {
                setButton(true)
                if (surveyQues[quesNo]?.layout === 8 && surveyQues[quesNo]?.type == 'form') {
                    goToNextQuestion(surveyQues[quesNo])

                }
            } else {

                setButton(false)
            }


        }
        else if (type == 'video') {

            setButton(true)
        }

        else {
            if (option && option.text_box == 'yes') {
                option.textOpen = true
                setTextBox(true)
                setButton(false)
                if ((type == 'radio' || event.target.value.trim() == "" || event.target.value == null || event.target.value == undefined) && option.text_box == 'yes') {

                    setButton(false)
                }
                else {
                    if (type == 'radio') {
                        setRadioOption(event.target.value)
                    }
                    else {
                        setTemporaryAns(`${radioOption || option.option}:${event.target.value}`)
                    }

                    setButton(true)
                }
                return
            }

            else {
                option.textOpen = false
                setTextBox(false)
                ans.push(event.target.value)
                setTemporaryAns(ans)
                if (event.target.value.trim() == "") {
                    setButton(false)
                }
                if (ans.length == 0) {
                    setButton(false)
                }
                if (event.target.value == option.option) {
                    event.checked = true
                }

            }
        }
        if (type != 'matrix' && type !== 'form' && type !== "contact" && type !== "checkbox") {

            if (event.target.value.trim() == "") {
                setButton(false)

            }
            if (event.target.value.trim() > "") {

                if (!event.target.checked && answer.length == 0) {
                    setButton(false)
                }
                else {
                    if ((surveyQues[quesNo].layout == 8 && surveyQues[quesNo].type == 'radio') || surveyQues[quesNo].nextOnSelect) {
                        setTimeout(() => { goToNextQuestion(surveyQues[quesNo], option.option) }, 500)
                    }
                    setButton(true)

                }
            }

        }
        if (type === 'number') {
            if (option?.minVlaue > Number(event.target.value)) {

                setButton(false)
                return

            } else if (option?.minLength > (event.target.value).length) {
                setButton(false)
                return
            } else if (option?.maxVlaue < Number(event.target.value)) {

                setButton(false)
                return

            }
            else {
                let matHeader = []

                if (event.target.value === "") {
                    setButton(false)
                } else {
                    setButton(true)
                }
            }
        }
        // setButton(true)
        if (type === "checkbox" && option.exclusive && event.target.checked) {
            question.options.map((op) => {
                if (op.option !== option.option) {
                    optionExclusive.push(op.option)
                    op.selectedOption = false
                    if (op?.textOpen) {
                        op.textOpen = false
                    }
                }
            })
            setActionId([option.action_id])
            option.selectedOption = true
            setOptionExclusive(optionExclusive)
            if (option && option.text_box == 'yes') {
                option.textOpen = true
                setTextBox(true)
                setButton(false)
                if ((event.target.value.trim() == "" || event.target.value == null || event.target.value == undefined) && option.text_box == 'yes') {

                    setButton(false)
                }
                else {
                    if (type == 'checkbox') {
                        setRadioOption(event.target.value)
                    }
                    else {
                        setTemporaryAns(`${option.option}:${event.target.value}`)
                    }

                    setButton(true)
                }
                return
            }

            else {
                option.textOpen = false
                setTextBox(false)
                ans.push(event.target.value)
                setTemporaryAns(ans)
                if (event.target.value.trim() == "") {
                    setButton(false)
                }
                if (ans.length == 0) {
                    setButton(false)
                }
                if (event.target.value == option.option && event.checked) {
                    event.checked = true
                }

            }

        } else if (type === "checkbox" && option.exclusive && !event.target.checked) {
            setOptionExclusive([])
            setTemporaryAns([])
        }



    }
    const backQuestion = (question) => {
        setButton(false)
        setBackQueAns([])
        setTextarea([])
        setSerial(serial - 1)
        let tempMulti = [...multiActId]
        for (let y = 0; y < tempMulti.length; y++) {
            if (tempMulti[y].id == question._id) {
                tempMulti[y].selected = true
            }
        }
        setMultiActId(tempMulti)
        if (question.type === 'checkbox' || question.type === 'radio') {
            question.options.forEach((op, index) => {
                if (op.textOpen === true) {
                    op.textOpen = false
                }
                if (op.selectedOption = true) {
                    op.selectedOption = false
                }
                // if (multiActId.includes(op.action_id)) {
                //     let index = multiActId.indexOf(op.action_id)
                //     multiActId.splice(index, 1)
                //     setMultiActId(multiActId)
                // }
            })
        }
        // for logic in back que.

        setPreveiousQue(questionIndex[questionIndex.length - 3])
        let index = questionIndex[questionIndex.length - 2]
        // surveyQues[index].options.forEach((op) => {
        //     let index = multiActId.indexOf(op.action_id)
        //     multiActId.splice(index, 1)
        //     setMultiActId(multiActId)
        // })

        if (surveyQues[index].addMaturitCount) {
            surveyQues[index].options.forEach((op) => {
                if (surveyQues[index].answer && surveyQues[index].answer.includes(op.option)) {
                    let newCount = maturitCount - op.optionCount
                    setMaturitCount(newCount)
                }
            })

        }

        setQuesNo(index)

        { question.layout !== 8 ? preFillBackAnswer(surveyQues[index]) : changeCss(index, quesNo, "setBackCss") }
        setButton(true)
        questionIndex.splice(questionIndex.length - 1, 1)

        delete question.answer
        if (surveyQues[index].type == 'cxNote') {

            setButton(false)
            setSerial(serial)
        } else if (surveyQues[index].type == 'cxScore') {
            setButton(false)
        }

    }
    const preFillBackAnswer = (que) => {
        let actId = [...actionId]
        actId = []
        if (que && que.type == 'radio') {
            if (que.answer.includes(":")) {
                let ans = que.answer.split(":")
                setBackQueAns(ans[0])
                setTextarea([ans[1]])
                let answ = `${ans[0]}:${ans[1]}`
                setTemporaryAns(answ)
                que.options.forEach((option) => {
                    if (option.action_id != "" && ans[0] == option.option) {
                        // actId = []
                        actId.push(option.action_id)
                    }
                })
            }
            que.options.forEach((op) => {
                if (op.text_box == "yes" && op.textOpen == false && que.answer.includes(op.option)) {
                    op.textOpen = true

                    return
                } else {
                    resetKeyboardInput(que.answer)
                    setBackQueAns(que.answer)
                    setTemporaryAns(que.answer)
                    que.options.forEach((option) => {
                        if (option.action_id != "" && que.answer == option.option) {
                            actId = []
                            actId.push(option.action_id)
                        }
                    })
                }
            })
        } else if (que && que.type == 'checkbox') {
            let arr = []
            let text = []
            let totalResponses = []
            que.answer.forEach((ans) => {
                if (ans.includes(":")) {
                    let a = ans.split(":")
                    arr.push(a[0])
                    text[arr.indexOf(a[0])] = a[1]
                    setTextarea(text)
                    totalResponses.push(`${a[0]}:${a[1]}`)
                } else {
                    arr.push(ans)
                    totalResponses.push(ans)
                }

            })
            que.options.forEach((op) => {
                if (op.text_box == "yes" && op.textOpen == false && arr.includes(op.option)) {
                    op.textOpen = true
                }
            })
            setTemporaryAns(totalResponses)
            setBackQueAns(arr)
            // let multiId = [...multiActId]
            que.options.forEach((option) => {
                if (option.action_id != "" && arr.includes(option.option)) {
                    // actId = []
                    actId.push(option.action_id)
                }
            })
            // setMultiActId(multiId)
        } else if (que && que.type == 'matrix') {

            let tempQue = []
            let matAns = []
            let totalResponses = []
            que.answer.map(e => {
                if (e) {
                    if (que.selection == "single") {
                        let newValue = (((e.toString()).replaceAll('-', ',')).slice(0, -1)?.split(","))
                        const result = [];
                        for (let i = 0; i < newValue.length; i += 2) {
                            const bank = newValue[i]?.split(':')[1];
                            const answer = newValue[i + 1]?.split(':')[1];
                            totalResponses.push({ bank, answer });
                            // if (!tempQue.includes(field)) {
                            tempQue.push(bank)

                            matAns[tempQue.indexOf(bank)] = answer
                            // }
                        }
                    }// totalResponses.push(result)
                    else if (que.selection == "random") {
                        // let tempQue = []
                        let matinputAns = []
                        // let totalResponses = []
                        let newValue = (((e.toString()).replaceAll('^', ',')).split(";"))
                        let ans = newValue.map(obj => JSON.parse(obj))
                        ans.forEach((e) => {

                            tempQue.push(e.bank)
                            matAns.push(e.quality)
                            matinputAns.push(e.answer)
                            totalResponses.push(e)
                        })
                        seMmatInputValue(matinputAns)
                        // setTemporaryAns([ans])
                    }
                    else {
                        let newValue = (e.toString().replaceAll('-', ',')).slice(0, -1)?.split(",,")
                        for (let i = 0; i < newValue.length; i++) {
                            const bank = (newValue[i]?.split(':')[1]).split(",")[0];
                            let spl = newValue[i]?.split(':')
                            let answer = (newValue[i]?.split(':'))[newValue[i]?.split(':').length - 1].trim()
                            answer = answer.includes(",") ? answer.replace(" ", "").split(",") : [answer]

                            answer = answer.includes("") ? answer.filter(ans => ans != "") : answer
                            totalResponses.push({ bank, answer });
                            tempQue.push(bank)
                            matAns[tempQue.indexOf(bank)] = answer
                        }
                    }
                }
                setBackQueAns(tempQue)
                setTextarea(matAns)
                setTemporaryAns(totalResponses)
            })
        } else if (que && que.type == 'form' || que.type == 'contact') {
            let ans = que.answer
            ans = ans.includes("") ? ans.filter(ans1 => ans1 != "") : ans
            let labelArr = []
            let textArr = []
            let totalResponses = {}
            for (let i = 0; i < ans.length; i++) {
                let label = ans[i].split(":")[0]
                let text = ans[i].split(":")[1]
                labelArr.push(label)
                textArr.push(text)
                totalResponses[label] = text
                eventForm[i].target.value = text
                setEventForm(eventForm)
            }
            setBackQueAns(labelArr)
            setTextarea(textArr)
            setTemporaryAns([totalResponses])
        } else if (que && que.type == 'rating') {
            setTemporaryAns(que.answer)
            setRatingValue(que.answer)
            que.options.forEach((option) => {
                if (option.action_id != "" && que.answer === option.option) {
                    // actId = []
                    actId.push(option.action_id)
                }
            })
        } else {
            setTemporaryAns(que.answer)
            resetKeyboardInput(que.answer)
            que.options.forEach((option) => {
                if (option.action_id != "" && que.answer === option.option) {
                    // actId = []
                    actId.push(option.action_id)
                }
            })

        }

        setActionId(actId)
    }

    const audioPlay = () => {
        if (audioPause == "onPlay") {
            setAudioPause("onPause")
            player.current.audioEl.current.pause()
        } else if (audioPause !== "onPlay") {
            setAudioPause("onPlay")
            player.current.audioEl.current.play()
        }

    }



    const questionAudio = (question) => {

        speak({ text: question[displayKeys.question], voice: voices[0] })

        if (question.type != 'text' && question.type != 'rating') {
            question.options.forEach((op, index) => {
                let str = String.fromCharCode(index + 65)

                speak({ text: `option     ${str}`, voice: voices[0] })
                speak({ text: op.option, voice: voices[0] })
            })
        }

    }
    const handleImageChange = (event) => {
        SetImages(event.target.files)

    };
    const sevaImage1 = async (audioFile) => {
        setLoader(true)
        let newData = new FormData()
        let data
        let uniqueId = uniqieId
        if (Images.length > 0) {
            for (let i = 0; i < Images.length; i++) {
                setcount(count + i)
                let name = Images.item(i).name;
                let ext = name.split('.');
                ext = ext[ext.length - 1]
                const file = Images[i];
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = async () => {
                    if (audioFile === "Tata") {
                        data = new File([reader.result], `${survey.survey_name}${Date.now()}.${ext}`, { type: 'MP4/MOV/WMV/AVI/AVCHD/png/jpg' })
                    }
                    else if (audioFile === "audio") {
                        data = new File([reader.result], `${survey.survey_name}${Date.now()}.mp3`, { type: 'MP3/WAV/PCM/OGG/ALAC' })
                        newData.append('isAudio', 1)
                    } else {
                        data = new File([reader.result], `${survey.survey_name}-${uniqueId}-${Date.now()}.${ext}`, { type: 'MP4/MOV/WMV/AVI/AVCHD' })
                    }
                    // Use newFile to upload the renamed file

                    newData.append('file', data);
                    if (i == Images.length - 1 && audioFile !== "Tata") {
                        newData.append("Id", uniqueId)
                        newData.append("surveyId", survey._id)
                        let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadImage`, newData)

                        if (result?.code === 200) {
                            setLoader(false)
                            toast.success('Upload successfully', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setFile('')
                            SetImages([])
                            setTemporaryAns([uniqueId])

                        }
                    } else if (i == Images.length - 1 && audioFile == "Tata") {
                        newData.append("Id", `${surveyQues[0].answer[0]} (${surveyQues[1].answer[0]})`)
                        let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/TataImage`, newData)

                        if (result?.code === 200) {
                            setLoader(false)
                            toast.success('File upload successfully', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setFile('')
                            SetImages([])
                            // setTemporaryAns([result.data])

                        }
                    }
                }
            }
        }

    }

    const uploadOnly = async (event) => {
        setLoader(true)
        let newData = new FormData()
        let data
        let uniqueId = uniqieId
        if (event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                setcount(count + i)
                let name = event.target.files.item(i).name;
                let ext = name.split('.');
                ext = ext[ext.length - 1]
                const file = event.target.files[i];
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = async () => {
                    data = new File([reader.result], `${survey.survey_name}-${uniqueId}-${Date.now()}.${ext}`, { type: 'MP4/MOV/WMV/AVI/AVCHD' })

                    // Use newFile to upload the renamed file

                    newData.append('file', data);
                    if (i == event.target.files.length - 1) {
                        newData.append("Id", uniqueId)
                        newData.append("surveyId", survey._id)

                        let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadImage`, newData)

                        if (result.code === 200) {
                            setLoader(false)
                            toast.success('Upload successfully', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setFile('')
                            SetImages([])
                            setTemporaryAns(uniqueId)
                            setButton(true)
                        }
                    }
                }
            }
        }

    }

    const addNewMatOption = () => {
        let arr = surveyQues
        let newOp = {
            option: newmatValue,
            action_id: ""
        }
        if (newmatValue !== "" && inputTag == "Row") {
            arr[quesNo].options.push(newOp)
        } else if (newmatValue !== "" && inputTag == "Colum") {
            arr[quesNo].matOption.push(newmatValue)
        }

        setSurveyQues(arr)
        setNewmatValue("")
        // return
    }


    function getMatrixAnswer(row, column) {
        const a = answer.filter(ans => { return (ans.bank === column && ans.quality === row) })
        if (a.length) {
            return a[0].answer
        } else {
            return ""
        }
    }
    let emailVarify = async (email) => {
        let data = { email: email }
        let result = await api.post(`${Config.new.site_api}/common/emailVerify`, data)
        if (result.code === 200) {

        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const ckeckInputDisabled = (option, question) => {
        if (question?.ansChecked && question.type === 'radio') {
            let r = surveyQues.filter(e => { return e._id == question?.ansChecked })[0].answer[0]

            return true
        } else
            if (question?.ansChecked) {
                let r = surveyQues.filter(e => { return e._id == question?.ansChecked })[0].answer
                if (r.includes(option.option)) {

                    return true
                }
            } else if (question?.optionExclusive && optionExclusive.includes(option.option)) {
                return true
            } else if (question.selectCountCheck) {
                let r = surveyQues.filter(e => { return e._id == question?.selectCountCheck })[0].answer
                if (r[0] == answer.length && !answer.join('').includes(option.option)) {
                    return true
                }
            }
    }

    const checkAnswer = (option, type, question) => {

        let ans = [...backQueAns]
        if (type == 'checkbox' || type == 'radio') {
            if (question?.ansChecked) {

                let r = surveyQues.filter(e => { return e._id == question?.ansChecked })[0].answer
                if (r.includes(option.option)) {
                    if (!answer.includes(option.option)) {
                        answer.push(option.option)
                        setTemporaryAns(answer)
                    }
                    return true
                } else if (option.min <= Number(r[0]) && (option.max ? Number(r[0]) <= option.max : true)) {
                    if (answer.length == 0) {
                        setTemporaryAns([option.option])
                        if (option.action_id != '') {
                            setActionId(option.action_id)
                        }
                        if (option.related_question) {
                            let rel = [...dependant_ques]
                            if (type == 'radio') {
                                question && question.options && question.options.map((e) => {
                                    if (e.related_question && rel.includes(e.related_question[0])) {
                                        rel.splice(rel.indexOf(e.related_question[0], 1))
                                    }
                                })
                            }
                            for (let t = 0; t < option.related_question.length; t++) {

                                rel.push(option.related_question[t])
                            }
                            setDependentQues(rel)
                        }
                        question.answer = [option.option]
                    }

                    return true
                }

            } else if (type == 'checkbox' && option.selectedOption) {
                return true
            } else if (ans.length == 0) {
                // let d = document.querySelector("#que")
                // if (option.option == d.value) {
                // }
                // let elements=document.querySelectorAll("#que")

                //                 elements.forEach((val) => {
                //                     if (val.checked&&option.option == val.value) {

                //                         val.checked = true
                //                     } if (!val.checked&&option.option == val.value) {

                //                         val.checked = false
                //                     }
                //                 })


                // if (option.text_box == 'yes'&&answer.length>0) {
                //     answer.length>0 && answer.map((ans) => {
                //         if (ans.includes(':')) {
                //             let other = ans.split(":")
                //             if (other.includes(option.option)) {
                //                 return true
                //             } else {
                //                 return false
                //             }

                //         } else {
                //             if (answer.includes(option.option)) {
                //                 return true
                //             } else {
                //                 return false
                //             }
                //         }
                //     })
                // }else if (answer.includes(option.option)) {
                //     return true
                // } else {
                //     return false
                // }



            }
            else if (backQueAns.length > 0 && backQueAns.includes(option.option)) {
                return true
            } else {
                return false
            }

        }
    }
    const nextButtonText = (e) => {
        if (e == "next") {
            if (selectedLang == 'hi') {
                return "अगला सवाल"
            } else if (selectedLang == 'gu') {
                return "આગળનો પ્રશ્ન"
            } else if (selectedLang == 'mr') {
                return "पुढचा प्रश्न"
            } else if (selectedLang == 'ml') {
                return "അടുത്ത ചോദ്യം"
            } else if (selectedLang == 'te') {
                return "తరువాతి ప్రశ్న"
            } else if (selectedLang == 'bn') {
                return "পরের প্রশ্ন"
            } else {
                return "Next Question"
            }
        } else if (e == "submit") {
            if (selectedLang == 'hi') {
                return "जमा करना"
            } else if (selectedLang == 'gu') {
                return "સબમિટ કરો"
            } else if (selectedLang == 'mr') {
                return "प्रस्तुत करणे"
            } else if (selectedLang == 'ml') {
                return "സമർപ്പിക്കുക"
            } else if (selectedLang == 'te') {
                return "సమర్పించండి"
            } else if (selectedLang == 'bn') {
                return "জমা দিন"
            } else {
                return "Submit"
            }
        }
    }

    let nccsCategorys = [{ Illiterate: "E3", "School up to 4 yrs.": "E2", "School 5 – 9 yrs.": "E2", "SSC / HSC": "E2", "SSC/ HSC but not Graduate": "E2", "Graduate / PG – general": "E1", "Graduate / PG – professional": "D2" },
    { Illiterate: "E2", "School up to 4 yrs.": "E1", "School 5 – 9 yrs.": "E1", "SSC / HSC": "E1", "SSC/ HSC but not Graduate": "D2", "Graduate / PG – general": "D2", "Graduate / PG – professional": "D2" },
    { Illiterate: "E1", "School up to 4 yrs.": "E1", "School 5 – 9 yrs.": "D2", "SSC / HSC": "D2", "SSC/ HSC but not Graduate": "D1", "Graduate / PG – general": "D1", "Graduate / PG – professional": "D1" },
    { Illiterate: "D2", "School up to 4 yrs.": "D2", "School 5 – 9 yrs.": "D1", "SSC / HSC": "D1", "SSC/ HSC but not Graduate": "C2", "Graduate / PG – general": "C2", "Graduate / PG – professional": "C2" },
    { Illiterate: "D1", "School up to 4 yrs.": "C2", "School 5 – 9 yrs.": "C2", "SSC / HSC": "C1", "SSC/ HSC but not Graduate": "C1", "Graduate / PG – general": "B2", "Graduate / PG – professional": "B2" },
    { Illiterate: "C2", "School up to 4 yrs.": "C1", "School 5 – 9 yrs.": "C1", "SSC / HSC": "B2", "SSC/ HSC but not Graduate": "B1", "Graduate / PG – general": "B1", "Graduate / PG – professional": "B1" },
    { Illiterate: "C1", "School up to 4 yrs.": "B2", "School 5 – 9 yrs.": "B2", "SSC / HSC": "B1", "SSC/ HSC but not Graduate": "A3", "Graduate / PG – general": "A3", "Graduate / PG – professional": "A3" },
    { Illiterate: "C1", "School up to 4 yrs.": "B1", "School 5 – 9 yrs.": "B1", "SSC / HSC": "A3", "SSC/ HSC but not Graduate": "A3", "Graduate / PG – general": "A2", "Graduate / PG – professional": "A2" },
    { Illiterate: "B1", "School up to 4 yrs.": "A3", "School 5 – 9 yrs.": "A3", "SSC / HSC": "A3", "SSC/ HSC but not Graduate": "A2", "Graduate / PG – general": "A2", "Graduate / PG – professional": "A2" },
    { Illiterate: "B1", "School up to 4 yrs.": "A3", "School 5 – 9 yrs.": "A3", "SSC / HSC": "A2", "SSC/ HSC but not Graduate": "A2", "Graduate / PG – general": "A1", "Graduate / PG – professional": "A1" }

    ]
    const setQuestion = (que) => {

        if (que && que.includes('{var}')) {
            let currentIndex = 0;
            let que_ids = surveyQues[quesNo].q_index

            let replacements = []
            let nccsCol = ''
            let nccsRow = ''
            if (surveyQues[quesNo].nccsCatgory) {
                surveyQues.map((e) => {
                    if (e.nccsCol) {
                        nccsCol = e.answer[0]
                    } else if (e.nccsRow) {
                        nccsRow = e.answer.includes("none") ? 0 : e.answer.length > 8 ? 9 : e.answer.length
                    }

                })
                if (nccsCol && nccsRow) {
                    replacements.push(nccsCategorys[nccsRow][nccsCol])
                    surveyQues[quesNo].answer = nccsCategorys[nccsRow][nccsCol]


                }

            } else if (surveyQues[quesNo].varCondition) {
                let answercate = []

                surveyQues.map((e) => {
                    if (que_ids.includes(e._id)) {
                        let index = que_ids.indexOf(e._id)
                        if (typeof (e.answer) == 'string') {
                            answercate.push(e.answer)
                        } else {
                            answercate = e.answer
                        }
                    }
                })
                surveyQues[quesNo].varCondition.forEach((ele) => {
                    answercate && answercate.length > 0 && answercate.map(ans => {
                        let a = ans
                        if (a.includes(':')) {
                            a = a.split(":")[0].trim()
                        }
                        if (ele.answers.includes(a) && !replacements.includes(ele.category)) {
                            replacements.push(ele.category)
                        }
                    })
                })
                if (replacements.length == 0) {
                    replacements = ["Other"]
                }
            } else if (surveyQues[quesNo].secondDynamicOption) {
                if (surveyQues && surveyQues[quesNo].dynamicOption) {
                    // setLoader(true)
                    let newOptions
                    let r = surveyQues.filter(e => { return surveyQues[quesNo].dynamicOption === e._id })[0].answer
                    if (typeof (r) === 'string') {
                        r = [r]
                    }
                    newOptions = surveyQues[quesNo].options.filter((op) => {
                        if (op.filter && r.includes(op.filter)) {
                            return op
                        } else if (r.join('').includes(op.option)) {
                            if (op.text_box == 'yes') {
                                let newop = op
                                let result = r.find(item => item.startsWith(op.option));
                                newop.option = result
                                return newop
                            } else {
                                return op
                            }
                        }
                    })
                    if (surveyQues[quesNo].secondDynamicOption) {
                        let s = surveyQues.filter(e => { return surveyQues[quesNo].secondDynamicOption === e._id })[0].answer
                        if (typeof (s) === 'string') {
                            s = [s]
                        }
                        newOptions.filter((op) => {
                            if (s.join('').includes(op.option) === false) {
                                if (op.text_box == 'yes') {
                                    let newop = op
                                    let result = r.find(item => item.startsWith(op.option));
                                    newop.option = result
                                    replacements = [...replacements, op.option]
                                    return newop.option
                                } else {
                                    replacements = [...replacements, op.option]
                                    return op.option
                                }
                            }
                        })
                    }
                }
            }
            else {
                surveyQues.map((e) => {
                    if (que_ids.includes(e._id)) {
                        let index = que_ids.indexOf(e._id)
                        replacements[index] = e.answer
                    }
                })
            }

            if (surveyQues[quesNo].setNoteAns) {
                surveyQues[quesNo].answer = replacements
            }

            let replacedString = que.replace(/\{var\}/g, function () {
                let replacement
                if (surveyQues[quesNo].multiVarCondition) {
                    replacements && replacements.length > 0 && replacements.forEach((rep, inx) => {
                        if (inx == 0) {
                            replacement = rep
                        } else {
                            replacement = replacement + rep
                        }
                        if (inx + 1 != replacements.length) {
                            replacement = replacement + ','
                        }
                    })
                } else if (que_ids.length === 1) {
                    replacement = replacements[0];
                } else {
                    replacement = replacements[currentIndex];
                    if (replacement == undefined) {
                        replacement = replacements[currentIndex - 1];
                    }

                    currentIndex++;
                }
                return replacement;
            });

            return "Q" + serial + "." + replacedString
        } else {
            if (surveyQues[quesNo].type == 'cxNote') {
                return que
            } else {
                return "Q" + serial + "." + que
            }
        }
    }

    const changeCss = (inx, ind, setBackCss) => {
        let temp = [...surveyQues]
        if (setBackCss) {
            if (temp[inx] && temp[inx].type == 'form') {
                temp[inx].options.forEach((op) => {
                    op.dropdown_option.forEach((ele, index) => {
                        if (ele?.selected) {
                            ele.selected = false
                        }
                    })
                })
                // delete temp[inx].answer
            }
            if (temp[ind] && temp[ind].type == 'form') {
                temp[ind].options.forEach((op) => {
                    op.dropdown_option.forEach((ele, index) => {
                        if (ele?.selected) {
                            ele.selected = false
                        }
                    })
                })
            }
            setTemporaryAns([])
        } else {
            temp[quesNo].options[ind].dropdown_option.forEach((ele, index) => {
                if (index == inx) {
                    ele.selected = true
                } else {
                    ele.selected = false
                }
            })
        }
        setSurveyQues(temp)

    }

    return (
        <main className=''>
            {loader && <Loader />}

            {surveyQues && surveyQues[quesNo] && surveyQues[quesNo]?.layout === 7 ? <header className="py-2 topHeader ">
                <div className="container-fluid">
                    <img src={survey && survey.logo ? survey.logo : "../uds/uds-logo.png"} width={survey && survey.logoWidth ? survey.logoWidth : "50"} />
                </div>
            </header> : surveyQues && surveyQues[quesNo] && surveyQues[quesNo]?.layout === 8 ? <header1 className="headerMain">
                <div className="container">
                    <div className="text-center py-4">
                        <img src={survey && survey.logo ? survey.logo : "../images/new-logo.png"} width={100} />
                    </div>
                    {surveyScreen && <ul className="dashlineUl">
                        {surveyQues.map((que, index) => (<li className={index <= quesNo ? 'active' : ''} />))}

                    </ul>}
                </div>
            </header1> : <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom" style={{ height: "80px" }}>
                    <div className="container-fluid ">
                        <a className="navbar-brand1 logo-itemsw logoMain" href={(survey && survey?.logoLanding) ? survey.logoLanding : "#"} target="break"><img src={survey && survey.logo ? survey.logo : "../images/new-logo.png"} alt="" style={{ height: survey && survey.logoWidth ? survey.logoWidth : "70px", margin: "5px" }} /></a>
                        <div dangerouslySetInnerHTML={{ __html: survey?.header && survey.header }}></div>
                        <ul className="navbar-nav flex-row align-items-center justify-contents-center w-auto">

                            {survey && survey.languages && survey.languages.length > 0 && <div className="nav-item dropdown notification-drop">
                                <label>Language:</label>
                                <select className='form-control' name="languages" id="lang" value={selectedLang} onChange={(e) => getSurveyLangCode(e)}>
                                    {survey && survey.languages && survey.languages.length > 0 && survey.languages.map((data, index) => (<option name='language' value={data.lang_code}>{data.translated_name ? data.translated_name : data.lang_name}</option>))}


                                </select>
                            </div>
                            }

                        </ul>
                    </div>

                </nav>
            </section>}
            {!surveyScreen && surveyIframe && <div className="App"

                style={{ height: 'calc(100vh - 20px)' }}>
                <iframe src={survey?.url} height="100%" width="100%" title="Iframe Example" ></iframe>
            </div>}

            {!surveyIframe && (!surveyQues || surveyQues[quesNo]?.layout !== 8) && <section className='h-100 survey-screen container-fluid'>
                <div className='row justify-content-center'>
                    <div className={surveyQues && surveyQues[quesNo]?.layout === 7 ? "" : 'col-md-9 mb-5'}>
                        <div className='survey-questions bg-white p-3 my-3 com-bsl w-100 mb-5'>
                            {/* <div className='company-name w-100 mb-3'><img src='../images/logo (3).png' className=''></img></div> */}
                            <div className='row justify-content-center' >
                                {!surveySubmited && <>
                                    {emailScreen && <div className='con col-md-6'>
                                        <label>Enter Name :</label><br></br>
                                        <input className='form-control' onChange={(e) => setName(e.target.value)} placeholder='Enter your name'></input>
                                        <label>Enter Email :</label><br></br>
                                        <input className='form-control' onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email'></input>
                                        <br /><br />
                                        <button className='py-2 mainbtn' onClick={submitEmail}>Submit</button>
                                    </div>}

                                    {/* "../images/pause.png" */}

                                    {/* "../images/audio/c4559ad4-1cf8-4416-9f97-386f93d3772c.png" */}
                                    {!emailScreen && surveyScreen && !surveySubmited && <>

                                        {surveyQues && (('layout' in surveyQues[quesNo] == false) || (surveyQues[quesNo].layout == 1)) && <>
                                            <div className='col-md-10 justify-content-center d-flex flex-column'>
                                                <p className={surveyQues && surveyQues[quesNo].subquestion ? 'fs-5 fw-bold pb-1 text-center mb-0' : 'fs-5 fw-bold pb-1 text-center mb-0'}>{surveyQues && surveyQues[quesNo].type !== 'cxScore' && setQuestion(surveyQues[quesNo][displayKeys.question])}
                                                    {/* <p className={surveyQues && surveyQues[quesNo].subquestion ? 'fs-5 fw-bold pb-1 text-center mb-0' : 'fs-5 fw-bold pb-1 text-center mb-0'}>{surveyQues && surveyQues[quesNo][displayKeys.question]} */}
                                                    {survey.audio && selectedLang == 'en' && <button className='border-0 bg-transparent ms-2' onClick={(e) => questionAudio(surveyQues[quesNo])}><img src={(audioPause !== "onPlay") ? "../images/audio/c4559ad4-1cf8-4416-9f97-386f93d3772c.png" : "../images/pause.png"} style={{ width: "37px" }} /></button>}
                                                </p>
                                                {selectedLang != 'en' && survey.doubleLanguageRequired === true && <p className={surveyQues && surveyQues[quesNo].subquestion ? 'fs-6 fw-bold pb-1 text-center mb-0' : 'fs-6 fw-bold pb-1 text-center mb-0'}>{surveyQues && (surveyQues[quesNo].question)}
                                                </p>}

                                                {surveyQues && surveyQues[quesNo].que_message && <><spam className='fs-7 fw-bold pb-1 text-center mb-0 ' style={{ 'lineHeight': '32px' }} ><span className='bg-warning p-2'>{surveyQues && surveyQues[quesNo][displayKeys.que_message]}</span></spam>
                                                    {/* {selectedLang != 'en' && survey.doubleLanguageRequired=== true && <spam className='fs-7 fw-bold pb-1 text-end mb-0'>{surveyQues && surveyQues[quesNo][displayKeys.que_message]}</spam>} */}
                                                </>}
                                                {surveyQues[quesNo].question_audio && (<ReactAudioPlayer preload='metadata'
                                                    ref={player} onPlay={() => console.log("play")} onPause={() => setAudioPause('onPause')} src={surveyQues[quesNo].question_audio} />)}
                                                {surveyQues && surveyQues[quesNo].subquestion && <spam className='fs-0.5  py-2 text-center'>{surveyQues && surveyQues[quesNo].subquestion}</spam>}
                                                {surveyQues[quesNo].attachment && surveyQues[quesNo].attachment != "" && <div className='d-flex justify-content-center'><img className='mb-4' src={surveyQues[quesNo].attachment} width="100px"></img></div>}
                                            </div>


                                            {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} value={answer} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }} disabled={surveyQues[quesNo].virtualKeyboard ? true : false}>
                                                    <input value={input} placeholder="Type something..." readOnly />
                                                </textarea>
                                            </div>)}
                                            {/* {console.log(surveyQues[quesNo].virtualKeyboard ? true : false, "surveyQues[quesNo].virtualKeyboard?true:false")} */}
                                            {surveyQues && surveyQues[quesNo].type == 'time' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                <input type="time" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} value={answer} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                </input>
                                            </div>)}
                                            {surveyQues && surveyQues[quesNo].type == 'number' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                <input type="number" min="1" className="form-control col-md-10 mt-2" value={answer[0] ? answer[0] : ""} row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                </input>
                                            </div>)}
                                            {surveyQues && surveyQues[quesNo].type == 'ImageButton' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                <label for="inputField" className='mainbtn mr-3 text-white px-4'>Gallery</label>
                                                <input type="file" id="inputField" style={{ display: "none" }} name="file" accept='*' onChange={(e) => uploadOnly(e)} multiple />
                                            </div>)}
                                            <div className='col-md-12'>
                                                {surveyQues && surveyQues[quesNo].type == 'date' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto" id="options" >
                                                    <input type={surveyQues[quesNo].selection} maxLength="255" value={answer} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }} />

                                                </div>)}
                                                {surveyQues && (surveyQues[quesNo].type == 'form' || surveyQues[quesNo].type == 'contact') && (<div className='row surv-op'>
                                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && (surveyQues[quesNo].newOptions || surveyQues[quesNo].options).map((option, index) => (<div className='col-md-12 mb-3' >
                                                        <div className="form-check h-100">
                                                            {/* <textarea className="form-check-input fs-3" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                            <div className='row w-100'>
                                                                <div className='col-md-8'>
                                                                    <label className="fs-5 form-check-label w-100" >{option[displayKeys.option]}</label>
                                                                    {selectedLang != 'en' && survey.doubleLanguageRequired === true && <label className="fs-5 form-check-label w-100 secondlangauge" >{option.option}</label>}</div>
                                                                {/* {console.log(textarea.length > 0, backQueAns.length > 0, backQueAns.includes(option.option), "textarea.length > 0 && backQueAns.length > 0&&backQueAns.includes(option.option)")} */}
                                                                <div className='col-md-4' >
                                                                    {(option.option_type === "dropdown" || option.option_type === "dropdowns") ?
                                                                        <> <select className='form-control' name="que" id="que" value={(answer.length > 0 && answer[0][option.option]) ? answer[0][option.option] : ""} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                            <option hidden value="">{(option.dropdown_option_header) ? option.dropdown_option_header : 'Select Option'} </option>
                                                                            {option.dropdown_option && option.dropdown_option.length > 0 && option[selectedLang == 'en' ? 'dropdown_option' : option.option == 'State' || option.option == 'City' ? 'dropdown_option' : 'dropdown_option_' + selectedLang].map((op, index) =>
                                                                                <>
                                                                                    <option Value={option.dropdown_option[index]}>{op}</option></>
                                                                            )}
                                                                        </select>
                                                                            {(option.dropdown_option1 && option.dropdown_option1.length > 0) &&
                                                                                <select className='form-control mt-2' name="que2" id="que2" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], surveyQues[quesNo].selection) }} >
                                                                                    <option value="" hidden>{(option.dropdown_option1_header) ? option.dropdown_option1_header : "Select Option"}</option>
                                                                                    {option.dropdown_option1 && option.dropdown_option1.length > 0 && option.dropdown_option1.map((op) =>
                                                                                        <>
                                                                                            <option value={op}>{op}</option></>
                                                                                    )}
                                                                                </select>}
                                                                        </> : (textarea.length > 0 && backQueAns.length > 0 && backQueAns.length == (surveyQues[quesNo].newOptions || surveyQues[quesNo].options).length && backQueAns.includes(option.option)) ?
                                                                            <input type={option.option_type} maxLength="255" id="que" className="form-control" row="3" value={textarea && textarea[backQueAns.indexOf(option[displayKeys.option])]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onKeyDown={(e) => { checkKey(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} /> :
                                                                            <input type={option.option_type} maxLength="255" id="que" className="form-control" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onKeyDown={(e) => { checkKey(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />}
                                                                    {/* <input type="tel" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"></input> */}
                                                                </div>
                                                            </div>

                                                            {/* <textarea className="form-control col-md-10 mt-2" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                            {/* {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, 'text', option,surveyQues[quesNo]) }}>
                                                        </textarea>} */}
                                                        </div>
                                                    </div>))}

                                                </div>)}

                                            </div>
                                            <div className='col-md-12'>
                                                {surveyQues && surveyQues[quesNo].type == 'dropdown' && (<div className='row surv-op'>
                                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) =>
                                                    (<div className='col-md-12 mb-3' >
                                                        <div className="form-check h-100 ">

                                                            <div className='row w-100'>
                                                                <div className='col-md-8'>
                                                                    <label className="fs-5 form-check-label w-100" >{option[displayKeys.option]}</label>
                                                                    <label className="fs-5 form-check-label w-100" >{option[displayKeys.option]}</label></div>
                                                                <div className='col-md-4'>
                                                                    {/* <input type="text" maxLength="255" className="form-control" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} /> */}
                                                                    {/* <Select isMulti
                                                                    placeholder={<div>Search Option</div>}
                                                                    // onClick={(e) => getCity()}
                                                                    aria-label="Default select example"
                                                                    // onChange={handleSelectedcity}
                                                                    options={
                                                                        option.dropdown_option && option.dropdown_option.map((op, index) => {
                                                                            op = op
                                                                            op = index
                                                                           
                                                                            // op
                                                                            return op;
                                                                        })

                                                                    }

                                                                    
                                                                /> */}

                                                                    {option.dropdown_option1 && option.dropdown_option1.length > 0 && <select className='form-control' name="que" id="que" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                        <option disabled>Select Option</option>
                                                                        {option.dropdown_option1 && option.dropdown_option1.length > 0 && option.dropdown_option1.map((op) =>
                                                                            <>
                                                                                <option value={op}>{op}</option></>
                                                                        )}
                                                                    </select>}
                                                                </div>
                                                            </div>

                                                            {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                            </textarea>}
                                                        </div>
                                                    </div>
                                                    ))}

                                                </div>)}

                                            </div>
                                            {/* <div className='col-md-12'>
                                                {surveyQues && surveyQues[quesNo].type == 'contact' && ()} */}
                                            {surveyQues && surveyQues[quesNo].type == 'nps' && (<div className="col-lg-12  artingNPSver artingNPSverTom">
                                                {/* {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => ( */}
                                                <div className="d-flex ratingBoxSec pl-2">
                                                    <div className="comnRating angryRating angryRatingaligment" >
                                                        <img src="../images/angry_mini.svg" style={{ width: "40px" }} />
                                                        <div className="blockRating" >
                                                            <a className={`${answer === 0 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(0, "rating", surveyQues[quesNo])}>0</a>
                                                            <a className={`${answer === 1 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(1, "rating", surveyQues[quesNo])}>1</a>
                                                            <a className={`${answer === 2 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(2, "rating", surveyQues[quesNo])}>2</a>
                                                            <a className={`${answer === 3 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(3, "rating", surveyQues[quesNo])}>3</a>
                                                            <a className={`${answer === 4 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(4, "rating", surveyQues[quesNo])}>4</a>
                                                            <a className={`${answer === 5 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(5, "rating", surveyQues[quesNo])}>5</a>
                                                            <a className={`${answer === 6 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(6, "rating", surveyQues[quesNo])}>6</a>

                                                        </div>  </div>
                                                    {/* <div className="comnRating upsetRating angryRatingaligment" onClick={() => ""}>
                                                        <img src="../images/upset_mini.svg" style={{ width: "40px" }} />
                                                        <div className="blockRating">
                                                        </div>                                                                                 </div> */}
                                                    <div className="comnRating naturalRating angryRatingaligment" onClick={() => ""}>
                                                        <img src="../images/neutral_mini.svg" style={{ width: "40px" }} />
                                                        <div className="blockRating">
                                                            <a className={`${answer === 7 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(7, "rating", surveyQues[quesNo])}>7</a>
                                                            <a className={`${answer === 8 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(8, "rating", surveyQues[quesNo])}>8</a>

                                                        </div>
                                                    </div>
                                                    {/* <div className="comnRating happyRating angryRatingaligment" onClick={() => ""}>
                                                        <img src="../images/happy_mini.svg" style={{ width: "40px" }} />
                                                        <div className="blockRating">
                                                        </div>                                                                                 </div> */}
                                                    <div className="comnRating excitedRating angryRatingaligment" onClick={() => ""}>
                                                        <img src="../images/excited_mini.svg" style={{ width: "40px" }} />
                                                        <div className="blockRating">
                                                            <a className={`${answer === 9 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(9, "rating", surveyQues[quesNo])}>9</a>
                                                            <a className={`${answer === 10 ? 'npsTypeSurvey' : 'npsHover'}`} onClick={() => setAnswer(10, "rating", surveyQues[quesNo])}>10</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ))} */}
                                            </div>)}
                                            {(surveyQues && surveyQues[quesNo].type == 'csat') && (
                                                <div className="container-fluid allQueRport py-3 mt-2 earnSec">
                                                    <div className="row">
                                                        <div className="col-lg-12">

                                                            <div className="panel-body">
                                                                <div>
                                                                    <div className="react-bs-table-container">
                                                                        <div className="react-bs-table react-bs-table-bordered" style={{ height: '100%' }}>
                                                                            <div className="react-bs-container-header table-header-wrapper">

                                                                                {surveyQues && surveyQues[quesNo].selection == 'emoji' && <div className="h-100">
                                                                                    {/* {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => ( */}
                                                                                    <div className="d-flex ratingBoxSec w-100 pl-2 gap-3">

                                                                                        <div className={selectedCsat == 1 ? "comnRating angryRating m-2 angryRatingaligment selectMain" : "comnRating angryRating m-2 angryRatingaligment"} onClick={() => setAnswer(1, "rating", surveyQues[quesNo])}>
                                                                                            <img src="../images/angry_mini.svg" />
                                                                                            <div className="blockRating">
                                                                                                <a >1</a>
                                                                                            </div>
                                                                                            <span style={{ 'white-space': 'nowrap' }}>{surveyQues[quesNo].options.length && surveyQues[quesNo].options[0].option}</span> {/*  value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[0].option} /> */}
                                                                                        </div>
                                                                                        <div className={selectedCsat == 2 ? "comnRating upsetRating m-1 angryRatingaligment selectMain" : "comnRating upsetRating m-1 angryRatingaligment "} onClick={() => setAnswer(2, "rating", surveyQues[quesNo])}>
                                                                                            <img src="../images/upset_mini.svg" />
                                                                                            <div className="blockRating">
                                                                                                <a >2</a>
                                                                                            </div>
                                                                                            <span style={{ color: '#ff8181' }}>{surveyQues[quesNo].options.length && surveyQues[quesNo].options[1].option}</span>
                                                                                            {/* <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[1].option} />   */}
                                                                                        </div>
                                                                                        <div className={selectedCsat == 3 ? "comnRating naturalRating m-1 angryRatingaligment selectMain" : "comnRating naturalRating m-1 angryRatingaligment"} onClick={() => setAnswer(3, "rating", surveyQues[quesNo])}>
                                                                                            <img src="../images/neutral_mini.svg" />
                                                                                            <div className="blockRating">
                                                                                                <a>3</a>
                                                                                            </div>
                                                                                            <span style={{ color: '#ecd500' }}>{surveyQues[quesNo].options.length && surveyQues[quesNo].options[2].option}</span>
                                                                                            {/* <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[2].option} /> */}
                                                                                        </div>
                                                                                        <div className={selectedCsat == 4 ? "comnRating happyRating m-1 angryRatingaligment selectMain" : "comnRating happyRating m-1 angryRatingaligment"} onClick={() => setAnswer(4, "rating", surveyQues[quesNo])}>
                                                                                            <img src="../images/happy_mini.svg" />
                                                                                            <div className="blockRating">
                                                                                                <a >4</a>
                                                                                            </div>
                                                                                            <span style={{ color: '#aaff00' }}>{surveyQues[quesNo].options.length && surveyQues[quesNo].options[3].option}</span>
                                                                                            {/* <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[3].option} />   */}
                                                                                        </div>
                                                                                        <div className={selectedCsat == 5 ? "comnRating excitedRating m-1 angryRatingaligment selectMain" : "comnRating excitedRating m-1 angryRatingaligment"} onClick={() => setAnswer(5, "rating", surveyQues[quesNo])}>
                                                                                            <img src="../images/excited_mini.svg" />
                                                                                            <div className="blockRating">
                                                                                                <a >5</a>
                                                                                            </div>
                                                                                            <span style={{ color: '#0edc00' }}>{surveyQues[quesNo].options.length && surveyQues[quesNo].options[4].option}</span>
                                                                                            {/* <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[4].option} /> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* ))} */}
                                                                                </div>}



                                                                                {surveyQues && surveyQues[quesNo].selection == "ynm" && <div className=" row gap-3 justify-content-center my-5 ps-3 mn-like">
                                                                                    <label className="Like-thumb rounded-1 p-0" style={{ width: '125px' }} onClick={() => setAnswer("Yes", "rating", surveyQues[quesNo])}>
                                                                                        <input type="radio" className="rounded-1 " name="radio" />
                                                                                        <div className="checkmarksen d-grid text-center " style={{ padding: '20px 30px 0px' }}>
                                                                                            <img src="../images/Thumb-yes.svg" alt="" className="m-auto" width="40px" />
                                                                                            <span className="pt-3 pb-2 fs-6">Yes</span>
                                                                                        </div>
                                                                                    </label>
                                                                                    <label className="Like-thumb p-0" style={{ width: '125px' }} onClick={() => setAnswer("No", "rating", surveyQues[quesNo])}>
                                                                                        <input type="radio" name="radio" />
                                                                                        <div className="checkmarksen d-grid text-center" style={{ padding: '20px 30px 0px' }}>
                                                                                            <img src="../images/Thumb-no.svg" className="m-auto" alt="" width="40px" />
                                                                                            <span className="pt-3 pb-2 fs-6">No</span>
                                                                                        </div>
                                                                                    </label>
                                                                                    <label className="Like-thumb p-0" style={{ width: '125px' }} onClick={() => setAnswer("Maybe", "rating", surveyQues[quesNo])}>
                                                                                        <input type="radio" name="radio" />
                                                                                        <div className="checkmarksen d-grid text-center" style={{ padding: '20px 30px 0px' }}>
                                                                                            <img src="../images/Maybe.svg" alt="" className="m-auto" width="37px" />
                                                                                            <span className="pt-3 pb-2" style={{ fontSize: '14px' }}>Maybe</span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='col-md-12'>
                                                {surveyQues && surveyQues[quesNo].type == 'checkbox' && (<div className='row surv-op'>
                                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && (surveyQues[quesNo].newOptions || surveyQues[quesNo].options).map((option, index) => (<div className='col-md-6 mb-3' >
                                                        <div className="form-check h-100 ">
                                                            <label className="fs-5 form-check-label  w-100" >
                                                                {/* {backQueAns.length > 0 && backQueAns.includes(option[displayKeys.option]) ? <input className="form-check-input fs-3" type="checkbox" value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} checked />
                                                                    : <input className="form-check-input fs-3" type="checkbox" value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />} */}


                                                                {/* {backQueAns.length > 0 ? <input className="form-check-input fs-3" id="que" type="checkbox" name={surveyQues[quesNo]._id} value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], index)}
                                                                    checked={backQueAns && backQueAns.includes(option[displayKeys.option]) ? true : false} /> : <input className="form-check-input fs-3" id="que" type="checkbox" name={surveyQues[quesNo]._id} value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], index)} />} */}
                                                                <input className="form-check-input fs-3" id="que" type="checkbox" name={surveyQues[quesNo]._id} value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], index)} checked={checkAnswer(option, surveyQues[quesNo].type, surveyQues[quesNo])} disabled={ckeckInputDisabled(option, surveyQues[quesNo])} />
                                                                {option.imageUrl && <img src={option.imageUrl} style={{ height: "100%", width: "100%" }}></img>}
                                                                <div className='langaugetwo'>
                                                                    <span>
                                                                        {option[displayKeys.option]}
                                                                    </span>
                                                                    {selectedLang != 'en' && survey.doubleLanguageRequired === true && <span className='secondlangauge'>

                                                                        {option.option}
                                                                    </span>}
                                                                </div>

                                                                {option.text_box == 'yes' && backQueAns.length > 0 && backQueAns.includes(option[displayKeys.option]) ?
                                                                    option.option_type ? option.textOpen && <input type={option.option_type} className="form-control col-md-10 mt-2" row="3" value={textarea[backQueAns.indexOf(option[displayKeys.option])]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }} />
                                                                        :
                                                                        option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" value={textarea[backQueAns.indexOf(option[displayKeys.option])]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                        </textarea> :
                                                                    option.option_type ? option.textOpen && <input type={option.option_type} className="form-control col-md-10 mt-2" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }} />
                                                                        : option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                        </textarea>}

                                                                {option.text_box == 'dropdown' && backQueAns.length > 0 && backQueAns.includes(option[displayKeys.option]) ? option.dropdown &&
                                                                    <select className='form-control' name="que" id="que" value={(answer.length > 0 && answer.join("").includes(option.option)) ? answer[answer.findIndex(element => element.includes(option.option))].split(":")[1] : ""} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                        <option hidden value="">{(option.dropdown_option_header) ? option.dropdown_option_header : 'Select Option'} </option>
                                                                        {option.dropdown_option && option.dropdown_option.length > 0 && option[selectedLang == 'en' ? 'dropdown_option' : 'dropdown_option_' + selectedLang].map((op, index) =>
                                                                            <>
                                                                                <option Value={option.dropdown_option[index]}>{op}</option></>
                                                                        )}
                                                                    </select>
                                                                    : option.dropdown && <select className='form-control' name="que" id="que" value={(answer.length > 0 && answer.join("").includes(option.option)) ? answer[answer.findIndex(element => element.includes(option.option))].split(":")[1] : ""} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                        <option hidden value="">{(option.dropdown_option_header) ? option.dropdown_option_header : 'Select Option'} </option>
                                                                        {option.dropdown_option && option.dropdown_option.length > 0 && option[selectedLang == 'en' ? 'dropdown_option' : 'dropdown_option_' + selectedLang].map((op, index) =>
                                                                            <>
                                                                                <option Value={option.dropdown_option[index]}>{op}</option></>
                                                                        )}
                                                                    </select>}

                                                            </label>
                                                        </div>

                                                    </div>))}

                                                </div>)}

                                            </div>
                                            <div className='col-md-12'>
                                                {surveyQues && surveyQues[quesNo].type == 'video' && (<div className='row surv-op'>
                                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                        <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>
                                                            <ReactPlayer url={option.option} controls={true} value={option.option} onEnded={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />

                                                        </div>
                                                    </div>))}

                                                </div>)}

                                            </div>
                                            <div className='col-md-12'>
                                                {surveyQues && surveyQues[quesNo].type == 'note' && (<div className='row surv-op'>

                                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                        <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>
                                                            {/* <ReactPlayer url='http://localhost:3009/survey_video/UPI_AutoPay.mp4'  controls={true} value={option.option} onStart={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }}/> */}

                                                        </div>
                                                    </div>))}

                                                </div>)}

                                            </div>


                                            <div className="col-md-12 ">
                                                {surveyQues && surveyQues[quesNo].type == 'rating' && surveyQues[quesNo].selection == 'rating' && (<div className="">
                                                    {/* app.css line no.-6842    color: #F8B84E !important;*/}
                                                    <div className="quationSec w-100 position-relative mt-3">


                                                        <div className="optionsBox w-100 mb-5 ps-0">
                                                            <div className="startReview1 responsive d-flex justify-content-center align-items-center flex-column" id="startReview1" style={{ display: '{{(isset($created_survey_options[0]["option_value"]) && ($created_survey_options[0]["option_value"] == "startReview1") ? "block":"none")}}' }}>
                                                                <ul className="d-flex flex-nowrap p-0 ratingReviewsq startReview" >
                                                                    {/* <li><a href="javascript:void(0);"><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>0</span></a>
                                                                    </li> */}
                                                                    {surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (

                                                                        <li><button type="button" className={ratingValue == option.option ? 'active' : ''} onClick={(e) => setAnswer(option.option, surveyQues[quesNo].type, surveyQues[quesNo], option)}>{option[displayKeys.option]}</button>

                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                                {/* <Input className="range" type="range" name "" value="0" min="0" max="1000" /> */}
                                                                {surveyQues[quesNo].options[surveyQues[quesNo].options.length - 1].option > surveyQues[quesNo].options[0].option && <input type="range" className="formewrange mt-2 ms-0" style={{ 'width': '100%' }} min={surveyQues[quesNo].options[0].option} value={ratingValue} max={surveyQues[quesNo].options[surveyQues[quesNo].options.length - 1].option} onChange={(e) => setAnswer(e.target.value, surveyQues[quesNo].type, surveyQues[quesNo], surveyQues[quesNo].options[0].option)}></input>}
                                                            </div>
                                                            {surveyQues && surveyQues[quesNo].TextAtLhs && <span style={{ float: "left", color: '#f47321', marginTop: "10px" }}>{surveyQues[quesNo].TextAtLhs}</span>}
                                                            {surveyQues && surveyQues[quesNo].TextAtRhs && <span style={{ float: "right", color: '#f47321', marginTop: "10px" }}>{surveyQues[quesNo].TextAtRhs}</span>}
                                                        </div>
                                                    </div>

                                                </div>)}
                                                {surveyQues && surveyQues[quesNo].type == 'rating' && surveyQues[quesNo].selection == 'customRating' && (<div className="">

                                                    <div className="quationSec w-100 position-relative mt-3">

                                                        <div className="optionsBox w-100 mb-5">
                                                            <div className="startReview1 d-flex justify-content-center" id="startReview1" style={{ display: '{{(isset($created_survey_options[0]["option_value"]) && ($created_survey_options[0]["option_value"] == "startReview1") ? "block":"none")}}' }}>
                                                                <ul className="d-flex p-0 ratingReviewsq startReview" >

                                                                    {surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (

                                                                        <li>
                                                                            <button type="button" className={ratingValue == option.option ? 'active' : 'newRtus'} onClick={(e) => setAnswer(option.option, surveyQues[quesNo].type, surveyQues[quesNo], option)}><img src={option.imageUrl} /></button>
                                                                            <a style={{ color: '#F8B84E ' }} onClick={(e) => setAnswer(option.option, surveyQues[quesNo].type, surveyQues[quesNo])}>
                                                                                {/* <i className={starClass >= index + 1 ? "fa fa-star" : "fa fa-star-o"} /> */}
                                                                                {/* <i className="fa fa-star" /> */}
                                                                                {/* <span>{option.optionCount}</span> */}
                                                                                <span className=' mt-4'>{option[displayKeys.option]}</span>
                                                                            </a>
                                                                            {/* <input className='form-control mt-2' style={{'text-align': 'center'}} value={option[displayKeys.option]} disabled></input> */}
                                                                        </li>))}

                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>)}
                                                {surveyQues && surveyQues[quesNo].type == 'rating' && surveyQues[quesNo].selection == 'star' && (<div className="">
                                                    {/* app.css line no.-6842    color: #F8B84E !important;*/}
                                                    <div className="quationSec w-100 position-relative mt-3">

                                                        <div className="optionsBox w-100 mb-5">
                                                            <div className="startReview1 d-flex justify-content-center" id="startReview1" style={{ display: '{{(isset($created_survey_options[0]["option_value"]) && ($created_survey_options[0]["option_value"] == "startReview1") ? "block":"none")}}' }}>
                                                                <ul className="d-flex p-0 startReview" >
                                                                    {/* <li><a href="javascript:void(0);"><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>0</span></a>
                                                                    </li> */}
                                                                    {surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (

                                                                        <li><a style={{ color: '#F8B84E ' }} onClick={(e) => setAnswer(option.option, surveyQues[quesNo].type, surveyQues[quesNo])}><i className={starClass >= index + 1 ? "fa fa-star" : "fa fa-star-o"} />
                                                                            <i className="fa fa-star" />
                                                                            <span>{option[displayKeys.option]}</span></a>
                                                                        </li>))}

                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>)}
                                            </div>

                                            <div className='col-md-12'>
                                                {surveyQues && surveyQues[quesNo].type == 'radio' && (
                                                    <div className='row surv-op'>
                                                        {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && (surveyQues[quesNo].newOptions || surveyQues[quesNo].options).map((option, index) => (
                                                            <div className='col-md-6 mb-3'>

                                                                <div className="form-check h-100 ">
                                                                    <label className="fs-5  form-check-label d-flex align-items-center justify-content-between w-100" >
                                                                        {/* {backQueAns.length > 0 ? <input id="que" className="form-check-input fs-3" type="radio" name={surveyQues[quesNo]._id} value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], index)}
                                                                        checked={backQueAns && backQueAns.includes(option[displayKeys.option]) ? true : false} /> : <input id="que" className="form-check-input fs-3" type="radio" name={surveyQues[quesNo]._id} value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], index)} />} */}
                                                                        <input id="que" className="form-check-input fs-3" type="radio" name={surveyQues[quesNo]._id} value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], index)} checked={checkAnswer(option, surveyQues[quesNo].type, surveyQues[quesNo])} disabled={ckeckInputDisabled(option, surveyQues[quesNo])} />
                                                                        {option.imageUrl && <img src={option.imageUrl} style={{ height: "250px" }}></img>}
                                                                        <div className='langaugetwo'>
                                                                            <div dangerouslySetInnerHTML={{ __html: option[displayKeys.option] }}>

                                                                            </div>
                                                                            {selectedLang != 'en' && survey.doubleLanguageRequired === true && <span className='secondlangauge'>

                                                                                {option.option}
                                                                            </span>}
                                                                        </div>
                                                                        {backQueAns.length > 0 ? option.text_box == 'yes' && option.option_type ? option.textOpen && <input type={option.textOpen} value={textarea[0]} className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option) }} />
                                                                            : option.textOpen && <textarea type="text" maxLength="255" value={textarea[0]} className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option) }}>
                                                                            </textarea> :
                                                                            option.text_box == 'yes' && option.option_type ? option.textOpen && <input type={option.textOpen} className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option) }} />
                                                                                : option.textOpen && <textarea type="text" maxLength="255" className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option) }}>
                                                                                </textarea>}
                                                                        {option.TataImagerequired == 'yes' && buttonIndex == index && <button className='mainbtn mr-3 text-white px-4' onClick={(e) => setFile("Tata")} >Gallery</button>}
                                                                        {option.Imagerequired == 'yes' && selectImage == 'yes' && <button className='mainbtn mr-3 text-white px-4' onClick={(e) => setFile("galley")} >Gallery</button>}
                                                                        {option.Audiorequired == 'yes' && selectImage == 'yes' && <button className='mainbtn mr-3 text-white px-4' onClick={(e) => setFile("audio")} >Gallery</button>}
                                                                    </label>
                                                                </div>

                                                            </div>))}

                                                    </div>)}

                                            </div>

                                            <div className='col-md-12'>
                                                {surveyQues[quesNo].type == 'image' && (<div className='row surv-op'>
                                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-lg-6 col-md-12 mb-3'>
                                                        {/* <label className="fs-5 fw-normal form-check-label  w-100" > */}
                                                        <div className="form-check h-100 flex-column">
                                                            <label className="fs-5 form-check-label w-100" >
                                                                <input className="form-check-input fs-3" type="radio" name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, "radio", option, surveyQues[quesNo])} />
                                                                <div className="d-flex flex-column">

                                                                    {option.imageUrl && <img src={option.imageUrl} style={{ height: "250px" }}></img>}
                                                                    <div>{option[displayKeys.option]}</div>
                                                                </div>
                                                            </label>
                                                            {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option) }}>
                                                            </textarea>}
                                                        </div>

                                                    </div>))}

                                                </div>)}

                                            </div>




                                            <div className='col-md-12'>
                                                {surveyQues && surveyQues[quesNo].type == "matrix" && (
                                                    <div className='table-responsive matrix-table'>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    {!surveyQues[quesNo]?.dependance && surveyQues[quesNo].matOption && surveyQues[quesNo].matOption.length > 0 && surveyQues[quesNo].matOption.map((column, index) => (
                                                                        <th scope="col" style={{ "text-align": "center" }} key={index}><span>{column[displayKeys.option]}</span>{selectedLang != 'en' && survey.doubleLanguageRequired === true && <span className='metrixLangauge'>{column.option}</span>} </th>
                                                                    ))

                                                                    }

                                                                    {surveyQues[quesNo]?.dependance && matHeader && matHeader.length > 0 && matHeader.map((column, index) => (
                                                                        <>
                                                                            <th scope="col" style={{ "text-align": "center" }} key={index}><span>{column[displayKeys.option]}</span>{selectedLang != 'en' && survey.doubleLanguageRequired === true && <span>{column.option}</span>} </th>
                                                                            {surveyQues[quesNo] && surveyQues[quesNo].includeNone && index == matHeader.length - 1 && <th scope="col" style={{ "text-align": "center" }} key={index}>None </th>}
                                                                        </>
                                                                    ))

                                                                    }
                                                                    {surveyQues[quesNo].addOpButton && <button className="card bg-lgrey mt-3 bg-gray-card-border text-center"
                                                                        type="button"
                                                                        style={{ backgroundColor: '#ee6917', borderColor: '#f47321', color: "white" }}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#from-scratch-modal03"
                                                                        data-backdrop="static" data-keyboard="false" onClick={() => setInputTag("Colum")} >+</button>}
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {surveyQues[quesNo].options && surveyQues[quesNo].options.length > 0 && (surveyQues[quesNo].newOptions || surveyQues[quesNo].options).map((row, index) => (
                                                                    <tr>
                                                                        {/* {row.option} */}
                                                                        <>
                                                                            <th scope="row">
                                                                                <span>{row[displayKeys.option]}</span>
                                                                                {selectedLang != 'en' && survey.doubleLanguageRequired === true && <span className='metrixLangauge'>{row.option}</span>}
                                                                            </th>
                                                                            {!surveyQues[quesNo]?.dependance && surveyQues[quesNo].matOption && surveyQues[quesNo].matOption.length > 0 && surveyQues[quesNo].matOption.map((column, i) => (
                                                                                <td style={{ "text-align": "center" }}>
                                                                                    <div className="form-check" >

                                                                                        {(backQueAns.length > 0 && textarea.length > 0) ? surveyQues[quesNo].selection == 'single' && <input className="form-check-input" id="que" type="radio" value={column.option} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], row.option, surveyQues[quesNo].options)} name={index} checked={textarea && backQueAns && textarea[backQueAns.indexOf(row.option)] == column.option ? true : false} />
                                                                                            : surveyQues[quesNo].selection == 'single' && <input className="form-check-input text-center" type="radio" id="que" value={column.option} onClick={(event) => setAnswer(event, "matrix", surveyQues[quesNo]?.vertical ? column : row, surveyQues[quesNo], surveyQues[quesNo]?.vertical ? row.option : column.option, surveyQues[quesNo]?.vertical ? (surveyQues[quesNo].newOptions || surveyQues[quesNo].options) : surveyQues[quesNo].matOption)} name={index} />
                                                                                        }
                                                                                        {(backQueAns.length > 0 && textarea.length > 0) ? surveyQues[quesNo].selection == 'multiple' && <input type="checkbox" name="checkbox" id="que" value={row.option} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], row.option, surveyQues[quesNo].options)} className="form-check-input" checked={textarea != [] && backQueAns && (textarea[backQueAns.indexOf(row.option)]).includes(column) ? true : false} /> :
                                                                                            surveyQues[quesNo].selection == 'multiple' && <input type="checkbox" name="checkbox" value={row.option} onClick={(event) => setAnswer(event, "matrix", surveyQues[quesNo]?.vertical ? column : row, surveyQues[quesNo], surveyQues[quesNo]?.vertical ? row.option : column.option, surveyQues[quesNo]?.vertical ? (surveyQues[quesNo].newOptions || surveyQues[quesNo].options) : surveyQues[quesNo].matOption)} className="form-check-input" />}

                                                                                        {surveyQues[quesNo].selection == 'input' && <input type="text" name="checkbox" id="que" onChange={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], column)} className="form-control w-100" />
                                                                                        }
                                                                                        {surveyQues[quesNo].selection == 'dropdown' && <select className='form-control' name="que" id="que" onChange={(e) => { setAnswer(e, 'matrix', row, surveyQues[quesNo], column) }} >
                                                                                            <option hidden value="">{'Select Option'} </option>
                                                                                            {surveyQues[quesNo].matDropdown && surveyQues[quesNo].matDropdown.length > 0 && surveyQues[quesNo].matDropdown.map((op) =>
                                                                                                <>
                                                                                                    <option value={op}>{op}</option></>
                                                                                            )}
                                                                                        </select>}
                                                                                        {surveyQues[quesNo].selection == 'random' && <div tabIndex="-1" onKeyDown={(e) => { e.keyCode === 9 && e.preventDefault() }}>{(row.option_type === 'dropdown')} ?{answer.filter(ans => (ans.bank == column && ans.quality == row.option) ? ans.answer : "")} <select className='form-control' tabIndex="-1" name="que" id="que" onChange={(e) => { setAnswer(e, 'matrix', row, surveyQues[quesNo], column) }} >
                                                                                            <option hidden value="">{'Select Option'} </option>
                                                                                            {row.dropdown_option && row.dropdown_option.length > 0 && row[selectedLang == 'en' ? 'dropdown_option' : 'dropdown_option_' + selectedLang].map((op) =>
                                                                                                <>
                                                                                                    <option
                                                                                                        Value={op}
                                                                                                    >{op}</option></>
                                                                                            )}
                                                                                        </select> : {(row.option_type === 'text')} ?
                                                                                            <input className='form-control w-100' type="text" id="que" tabIndex="-1" name="checkbox" onChange={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], column)} />
                                                                                            : ""</div>}
                                                                                    </div>
                                                                                </td>

                                                                            ))}
                                                                            {surveyQues[quesNo]?.dependance && matHeader && matHeader.length > 0 && matHeader.map((column, i) => (
                                                                                <>
                                                                                    <td style={{ "text-align": "center" }}>

                                                                                        <div className="form-check" tabIndex="-1" onKeyDown={(e) => { e.keyCode === 9 && e.preventDefault() }}>
                                                                                            {surveyQues[quesNo].selection == 'single' && <input className="form-check-input" type="radio" value={row.option} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], column.option)} name={i} />
                                                                                            }
                                                                                            {surveyQues[quesNo].selection == 'multiple' && (<>
                                                                                                <input type="checkbox" name="checkbox" value={surveyQues[quesNo]?.vertical ? row.option : column.option} onClick={(event) => setAnswer(event, "matrix", surveyQues[quesNo]?.vertical ? column : row, surveyQues[quesNo], surveyQues[quesNo]?.vertical ? row.option : column.option, surveyQues[quesNo]?.vertical ? surveyQues[quesNo].options : matHeader)} className="form-check-input" />

                                                                                            </>)
                                                                                            }
                                                                                            {surveyQues[quesNo].selection == 'input' && <input type="text" name="checkbox" onChange={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], column.option)} className="form-control w-100" />
                                                                                            }
                                                                                            {surveyQues[quesNo].selection == 'dropdown' && <select className='form-control' name="que" id="que" onChange={(e) => { setAnswer(e, 'matrix', row, surveyQues[quesNo], column.option) }} >
                                                                                                <option hidden value="">{'Select Option'} </option>
                                                                                                {surveyQues[quesNo].matDropdown && surveyQues[quesNo].matDropdown.length > 0 && surveyQues[quesNo].matDropdown.map((op) =>
                                                                                                    <>
                                                                                                        <option value={op}>{op}</option></>
                                                                                                )}
                                                                                            </select>}
                                                                                            {surveyQues[quesNo].selection == 'random' && (row.option_type === 'dropdown') ? <select className='form-control' name="que" id="que" value={getMatrixAnswer(row.option, column)} onChange={(e) => { setAnswer(e, 'matrix', row, surveyQues[quesNo], column) }} >
                                                                                                <option hidden value="">{'Select Option'} </option>
                                                                                                {row.dropdown_option && row.dropdown_option.length > 0 && row[selectedLang == 'en' ? 'dropdown_option' : 'dropdown_option_' + selectedLang].map((op) =>
                                                                                                    <>
                                                                                                        <option Value={op}>{op}</option></>
                                                                                                )}
                                                                                            </select> : (row.option_type === 'text') ?
                                                                                                <input className='form-control w-100' type="text" name="checkbox" value={getMatrixAnswer(row.option, column)} onChange={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], column)} />
                                                                                                : ""}
                                                                                        </div>
                                                                                    </td>
                                                                                    {surveyQues[quesNo] && surveyQues[quesNo].includeNone && i == matHeader.length - 1 && <td style={{ "text-align": "center" }}><div className="form-check" >
                                                                                        {<input type="checkbox" name="checkbox" value={'none'} onClick={(event) => setAnswer(event, "matrix", { "option": "none", action_id: "" }, surveyQues[quesNo], surveyQues[quesNo]?.vertical ? row.option : column.option, surveyQues[quesNo]?.vertical ? surveyQues[quesNo].options : matHeader)} className="form-check-input" />}
                                                                                    </div> </td>}
                                                                                </>
                                                                            ))}
                                                                        </>

                                                                    </tr>
                                                                ))}
                                                                <th>{surveyQues[quesNo].addRowButton && <button className="card bg-lgrey bg-gray-card-border text-center"
                                                                    type="button"
                                                                    style={{ backgroundColor: '#ee6917', borderColor: '#f47321', color: "white" }}
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#from-scratch-modal03"
                                                                    data-backdrop="static" data-keyboard="false" onClick={() => setInputTag("Row")}>+</button>}</th>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>

                                            {surveyQues[quesNo]?.type == 'cxScore' &&
                                                <div className="scoreMain">
                                                    <div className="container">
                                                        <div className="row align-items-center rowMain">
                                                            <div className="col-md-6">
                                                                <div className="leftSec">
                                                                    <figure className="m-0 d-none d-md-block">
                                                                        <img src="../images/clickHere-img.svg" alt="" width={280} />
                                                                    </figure>
                                                                    <lottie-player
                                                                        src="../images/thank-you-simple.json"
                                                                        loop=""
                                                                        autoPlay=""
                                                                        style={{ height: 100, width: 200, margin: "auto" }}
                                                                    />
                                                                    <p>
                                                                        To Know more about CX Maturity!
                                                                    </p>
                                                                    {/* <div class="inputUs">
                                                                        <label for="Input1" class="form-label">Email address</label>
                                                                        <input type="email" class="form-control" id="Input1" placeholder=""></input>
                                                                    </div> */}
                                                                    <a className="clickHere" onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}>
                                                                        Connect with us
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="rightSec">
                                                                    <div className="innerScore">
                                                                        <div className='confettiLottie'>
                                                                            <lottie-player
                                                                                src="../images/confetti (3).json"
                                                                                className=""
                                                                                loop=""
                                                                                autoPlay=""
                                                                                style={{ height: 260, width: 270, margin: "auto" }}
                                                                            /></div>
                                                                        <p>Your CX Maturity Assessment Score </p>
                                                                        <span>
                                                                            {maturitCount} <b>{Object.entries(survey.maturity_category).find(([key, range]) => maturitCount >= range[0] && maturitCount <= range[1])[0]}</b>
                                                                        </span>
                                                                    </div>
                                                                    <ul className="scoreUl mt-3">
                                                                        <li>
                                                                            <b />
                                                                            &lt;=40 Initiation
                                                                        </li>
                                                                        <li>
                                                                            <b /> 41 to 60 Fragmented
                                                                        </li>
                                                                    </ul>
                                                                    <ul className="scoreUl second">
                                                                        <li>
                                                                            <b /> 61-80 Managed
                                                                        </li>
                                                                        <li>
                                                                            <b /> 81-100 Established
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </>}
                                        {/* <div className="tab-pane question-alignment fade directSurveylayoutwq" id="img-right-pane" aria-labelledby="img-right" > */}
                                        {surveyQues && surveyQues[quesNo].layout == 2 &&

                                            <div className='survey-question'>
                                                <h4 className='q-n d-flex'> <span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                                <p type={'text'} className=' fadeInUp' placeholder='Description (optional)'></p>
                                                <div className='align-con py-4 row  '>
                                                    <div className="col-lg-6">
                                                        {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (
                                                            surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                                <div className="input-group layoutoption mb-3">
                                                                    <label className='fs-5  form-check-label layoutSurvey' >
                                                                        <input className="input-group-text bg-white border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                        {/* {option.imageUrl && <img src={option.imageUrl} style={{ height: "250px" }}></img>}<br/> */}
                                                                        <div>{option[displayKeys.option]}</div>
                                                                    </label>
                                                                    {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control d-flex layoutTexterea  mt-2" placeholder={"Enter Here ..."} onChange={(e) => { }}>
                                                                    </textarea>}
                                                                </div>
                                                            ))
                                                        )}
                                                        {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                            <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                            </textarea>
                                                        </div>)}


                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <img src={surveyQues[quesNo].attachment ? surveyQues[quesNo].attachment : '../images/login-bg.jpg'} className='w-100 '></img>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* </div> */}


                                        {/* <div className="tab-pane question-alignment fade directSurveylayoutwq" id="img-left-pane" aria-labelledby="img-left" > */}
                                        {surveyQues && surveyQues[quesNo].layout == 3 &&

                                            <div className='survey-question'>
                                                <h4 className='q-n d-flex'> <span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                                <p type={'text'} className=' fadeInUp' placeholder='Description (optional)'></p>
                                                <div className='align-con py-4  row  '>


                                                    <div className='col-lg-7'>
                                                        <img src={surveyQues[quesNo].attachment ? surveyQues[quesNo].attachment : '../images/login-bg.jpg'} className='w-100 '></img>
                                                    </div>
                                                    <div className="col-lg-5 py-5">
                                                        {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (
                                                            (surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className="input-group mb-3">
                                                                <label className='fs-5  form-check-label layoutSurvey' >
                                                                    <input className="input-group-text bg-white border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                    {option[displayKeys.option]}
                                                                </label>

                                                            </div>)))
                                                        )}
                                                        {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                            <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                            </textarea>
                                                        </div>)}

                                                    </div>

                                                </div>
                                            </div>
                                        }
                                        {/* </div> */}
                                        {/* <div className="tab-pane question-alignment fade directSurveylayoutwq" id="bimg-right-pane" aria-labelledby="bimg-right" > */}
                                        {surveyQues && surveyQues[quesNo].layout == 4 &&
                                            <div className='survey-question'>
                                                <div className='align-con py-4 row align-items-center'>
                                                    <div className="col-lg-5">
                                                        <h4 className='q-n d-flex'><span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                                        {surveyQues && surveyQues[quesNo].que_message && <p><input type={'text'} className=' fadeInUp' placeholder='Description (optional)' value={surveyQues && surveyQues[quesNo].que_message}></input></p>}

                                                        {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (

                                                            (surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                                <div className="input-group mb-3">
                                                                    <label className='fs-5  form-check-label layoutSurvey' >
                                                                        <input className="input-group-text bg-white border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                        {option[displayKeys.option]}
                                                                    </label>

                                                                </div>)))
                                                        )}
                                                        {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                            <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                            </textarea>
                                                        </div>)}


                                                    </div>
                                                    <div className='col-lg-7'>
                                                        <img src={surveyQues[quesNo].attachment ? surveyQues[quesNo].attachment : '../images/login-bg.jpg'} className='w-100 '></img>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* </div> */}

                                        {/* <div className="tab-pane question-alignment fade directSurveylayoutwq" id="bimg-left-pane" aria-labelledby="bimg-left" > */}
                                        {surveyQues && surveyQues[quesNo].layout == 5 &&


                                            <div className='align-con py-4 row align-items-center'>
                                                <div className='col-lg-7'>
                                                    <img src={surveyQues[quesNo].attachment ? surveyQues[quesNo].attachment : '../images/login-bg.jpg'} className='w-100 '></img>
                                                </div>
                                                <div className="col-lg-5">
                                                    <h4 className='q-n d-flex'> <span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                                    <p type={'text'} className=' fadeInUp' placeholder='Description (optional)'></p>

                                                    <div className='survey-question'>
                                                        {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (
                                                            (surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className="input-group mb-3">
                                                                <label className='fs-5  form-check-label layoutSurvey' >
                                                                    <input className="input-group-text bg-white border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                    {option[displayKeys.option]}
                                                                </label>

                                                            </div>)))
                                                        )}
                                                        {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                            <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                            </textarea>
                                                        </div>)}


                                                    </div>

                                                </div>
                                            </div>}
                                        {/* </div> */}
                                        {surveyQues && surveyQues[quesNo].layout == 6 &&
                                            <div className="tab-pane question-alignment fade  directSurveylayoutwq" id="fimg-pane" aria-labelledby="fimg" >

                                                <div className='align-con m-0 py-4 row align-items-center justify-content-center'>

                                                    <div className="col-lg-6">
                                                        <h4 className='q-n d-flex'><span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                                        <p type={'text'} className=' fadeInUp' placeholder='Description (optional)'></p>

                                                        <div className='survey-question '>
                                                            {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (
                                                                (surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                                    <div className="input-group mb-3">
                                                                        <label className='fs-5  form-check-label layoutSurvey' style={{ color: 'white' }} >
                                                                            <input className="input-group-text text-light  border-0 rounded-1 mr-2 layoutRadio " type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} id="que" value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], index)} checked={checkAnswer(option, surveyQues[quesNo].type, surveyQues[quesNo])} disabled={ckeckInputDisabled(option, surveyQues[quesNo])} />
                                                                            {option[displayKeys.option]}
                                                                        </label>
                                                                        {option.text_box == 'yes' && backQueAns.length > 0 && backQueAns.includes(option[displayKeys.option]) ?
                                                                            option.option_type ? option.textOpen && <input type={option.option_type} className="form-control col-md-10 mt-2" row="3" value={textarea[backQueAns.indexOf(option[displayKeys.option])]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }} />
                                                                                :
                                                                                option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2 layoutTextereabackground" row="3" value={textarea[backQueAns.indexOf(option[displayKeys.option])]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                                </textarea> :
                                                                            option.option_type ? option.textOpen && <input type={option.option_type} className="form-control col-md-10 mt-2" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }} />
                                                                                : option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2 layoutTextereabackground" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                                </textarea>}
                                                                    </div>)))
                                                            )}
                                                            {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center " id="options" >
                                                                {/* <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2 layoutTextereabackground" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                                </textarea> */}
                                                                <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} value={answer} className="form-control col-md-10 mt-2 layoutTextereabackground" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                                </textarea>
                                                            </div>)}




                                                        </div>

                                                    </div>
                                                </div>
                                            </div>}

                                        {surveyQues && surveyQues[quesNo].layout == 7 && <div className="questionSection">
                                            <div className="container">
                                                <div className="questionType mb-3">
                                                    {surveyQues && (surveyQues[quesNo].type !== 'contact' && surveyQues[quesNo].type !== 'text') && <span>
                                                        <img src="images/noun-emoji.svg" alt="" width={20} className="me-2" />{" "}
                                                        {surveyQues && surveyQues[quesNo].type == 'radio' ? 'Single Choice' : surveyQues && surveyQues[quesNo].type == 'csat' ? 'Yes/No' : surveyQues && surveyQues[quesNo].type == 'checkbox' ? "Multi Choice" : "NPS"} Question
                                                    </span>}
                                                </div>
                                                <div className="questionBox">
                                                    <strong>Question {quesNo + 1}</strong>
                                                    <p>{surveyQues && surveyQues[quesNo][displayKeys.question]}</p>
                                                    {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') ? <div className="py-3 mb-5 radioGroup">
                                                        <ul className="cstmRadio">
                                                            {(surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                                <li className='form-check '>


                                                                    <label className=''>
                                                                        <input
                                                                            id='que'
                                                                            className="udsRadio form-check-input radioPosition"
                                                                            type={surveyQues && surveyQues[quesNo].type}
                                                                            name={surveyQues[quesNo]._id}
                                                                            value={option.option}
                                                                            onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], index)}
                                                                            checked={checkAnswer(option, surveyQues[quesNo].type)}
                                                                        />
                                                                        {option[displayKeys.option]}</label>
                                                                </li>

                                                            )))}

                                                        </ul>
                                                        {text_box && <div className="textMsg" >
                                                            {/* onChange={(e) => { setAnswer(e, 'text', option) }} */}
                                                            <textarea className="form-control" rows={3} onChange={(e) => { setAnswer(e, 'text', surveyQues[quesNo].options[surveyQues[quesNo].options.length - 1]) }} />
                                                            <span className="txtlimit">0/500</span>
                                                        </div>
                                                        }
                                                    </div> : surveyQues && surveyQues[quesNo].type == 'csat' ?
                                                        <div className="py-3 mb-5">
                                                            <ul className="yesNo">
                                                                <li >
                                                                    <label
                                                                        style={answer == 'Yes' ? { background: '#1CAF16' } : {}}>
                                                                        <input type="radio" id="que" className="d-none" name="yesNo" onClick={() => setAnswer("Yes", "rating", surveyQues[quesNo])} />
                                                                        <figure >
                                                                            <img src="uds/yes.svg" alt="" width={30} />
                                                                        </figure>{" "}
                                                                        Yes
                                                                    </label>


                                                                </li>
                                                                <li>
                                                                    <label style={answer == 'No' ? { background: '#FF0000' } : {}}>
                                                                        <input type="radio" id="que" className="d-none" name="yesNo" onClick={() => setAnswer("No", "rating", surveyQues[quesNo])} />
                                                                        <figure>
                                                                            <img src="uds/no.svg" alt="" width={30} />
                                                                        </figure>{" "}
                                                                        No
                                                                    </label>
                                                                </li>
                                                                <li>
                                                                    <label style={answer == 'May be' ? { background: '#FFD359' } : {}}>
                                                                        <input type="radio" id="que" className="d-none" name="yesNo" onClick={() => setAnswer("May be", "rating", surveyQues[quesNo])} />
                                                                        <figure>
                                                                            <img src="uds/noun-emoji.svg" alt="" width={30} />
                                                                        </figure>{" "}
                                                                        May be
                                                                    </label>
                                                                    {/* <input id='que' className="d-none" /> */}
                                                                </li>
                                                            </ul>
                                                        </div> : surveyQues && surveyQues[quesNo].type == 'nps' ?
                                                            <div className="py-3 mb-5 radioGroup">
                                                                <ul className="ratingList">
                                                                    {(surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => {
                                                                        return (index <= 6 ?
                                                                            <li className="countNumber sademoji">
                                                                                <input type="radio" name="count" id={`count${index}`} onClick={() => setAnswer(option.option, "rating", surveyQues[quesNo])} />
                                                                                <label htmlFor={`count${index}`}>
                                                                                    <figure>
                                                                                        <img src="uds/smiley_sad_white_icon.svg" alt="" width={37} />
                                                                                    </figure>
                                                                                    <h6>{option.option}</h6>
                                                                                </label>
                                                                            </li> : index <= 8 ? <li className="countNumber nuturalemoji">
                                                                                <input type="radio" name="count" id={`count${index}`} onClick={() => setAnswer(option.option, "rating", surveyQues[quesNo])} />
                                                                                <label htmlFor={`count${index}`}>
                                                                                    <figure>
                                                                                        <img src="uds/smiley_meh_white_icon.svg" alt="" width={37} />
                                                                                    </figure>
                                                                                    <h6>{option.option}</h6>
                                                                                </label>
                                                                            </li> : <li className="countNumber happyemoji">
                                                                                <input type="radio" name="count" id={`count${index}`} onClick={() => setAnswer(option.option, "rating", surveyQues[quesNo])} />
                                                                                <label htmlFor={`count${index}`}>
                                                                                    <figure>
                                                                                        <img src="uds/smiley_white_icon.svg" alt="" width={37} />
                                                                                    </figure>
                                                                                    <h6>{option.option}</h6>
                                                                                </label>
                                                                            </li>
                                                                        )
                                                                    }))}

                                                                </ul>
                                                                <div className="bottomLagend">
                                                                    <span className="text-danger text-center"> Detractors</span>
                                                                    <span className="text-warning"> </span>
                                                                    <span className="text-success"> Promoters</span>
                                                                </div>
                                                            </div>
                                                            : surveyQues && surveyQues[quesNo].type == 'text' ? <div className="textMsg py-3 mb-5">
                                                                {/* {(textarea.length > 0 && backQueAns.length > 0 && backQueAns.length == surveyQues[quesNo].options.length && backQueAns.includes(option.option)) ?
                                                                    <textarea type='text' maxLength="255" id="que" style={{ maxWidth: '520px' }} className="form-control" row='3' value={textarea && textarea[backQueAns.indexOf(option[displayKeys.option])]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onKeyDown={(e) => { checkKey(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} /> :
                                                                    <textarea type='tet' maxLength="255" id="que" style={{ maxWidth: '520px' }} className="form-control" row='3' placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onKeyDown={(e) => { checkKey(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />} */}

                                                                <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} value={answer} style={{ maxWidth: '520px' }} className="form-control" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                                </textarea>

                                                            </div> : (surveyQues[quesNo].type == 'form' || surveyQues[quesNo].type == 'contact') ?
                                                                surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 mb-3' >
                                                                    <div className="form-check h-100">
                                                                        {/* {console.log(option[displayKeys.option])} */}
                                                                        {/* <textarea className="form-check-input fs-3" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                                        <div className='row w-100'>
                                                                            <div className='col-md-8'>
                                                                                {/* <label className="fs-5 form-check-label w-100" >{option[displayKeys.option]}</label> */}
                                                                                {selectedLang != 'en' && survey.doubleLanguageRequired === true && <label className="fs-5 form-check-label w-100 secondlangauge" >{option.option}</label>}</div>
                                                                            {/* {console.log(textarea.length > 0, backQueAns.length > 0, backQueAns.includes(option.option), "textarea.length > 0 && backQueAns.length > 0&&backQueAns.includes(option.option)")} */}
                                                                            <div className="textMsg"  >
                                                                                {(option.option_type === "dropdown" || option.option_type === "dropdowns") ?
                                                                                    <> <select className='form-control' name="que" id="que" value={(answer.length > 0 && answer[0][option.option]) ? answer[0][option.option] : ""} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                                        <option hidden value="">{(option.dropdown_option_header) ? option.dropdown_option_header : 'Select Option'} </option>
                                                                                        {option.dropdown_option && option.dropdown_option.length > 0 && option[selectedLang == 'en' ? 'dropdown_option' : option.option == 'State' || option.option == 'City' ? 'dropdown_option' : 'dropdown_option_' + selectedLang].map((op, index) =>
                                                                                            <>
                                                                                                <option Value={option.dropdown_option[index]}>{op}</option></>
                                                                                        )}
                                                                                    </select>
                                                                                        {(option.dropdown_option1 && option.dropdown_option1.length > 0) &&
                                                                                            <select className='form-control mt-2' name="que2" id="que2" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], surveyQues[quesNo].selection) }} >
                                                                                                <option value="" hidden>{(option.dropdown_option1_header) ? option.dropdown_option1_header : "Select Option"}</option>
                                                                                                {option.dropdown_option1 && option.dropdown_option1.length > 0 && option.dropdown_option1.map((op) =>
                                                                                                    <>
                                                                                                        <option value={op}>{op}</option></>
                                                                                                )}
                                                                                            </select>}
                                                                                    </> : (textarea.length > 0 && backQueAns.length > 0 && backQueAns.length == surveyQues[quesNo].options.length && backQueAns.includes(option.option)) ?
                                                                                        <textarea type={option.option_type} maxLength="255" id="que" style={{ maxWidth: '520px' }} className="form-control" row='3' value={textarea && textarea[backQueAns.indexOf(option[displayKeys.option])]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onKeyDown={(e) => { checkKey(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} /> :
                                                                                        <textarea type={option.option_type} maxLength="255" id="que" style={{ maxWidth: '520px' }} className="form-control" row='3' placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onKeyDown={(e) => { checkKey(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />}
                                                                                {/* <input type="tel" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"></input> */}
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>)) : ""}

                                                    {surveyQues && surveyQues && surveyQues[quesNo].layout === 7 && quesNo < surveyQues.length - 1 && button && <a className="btn btn-primary" onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}>
                                                        Next
                                                    </a>}
                                                    {surveyQues && surveyQues && surveyQues[quesNo].layout === 7 && quesNo == surveyQues.length - 1 && button && <a className="btn btn-primary" onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}>
                                                        Submit
                                                    </a>}
                                                </div>
                                            </div>
                                            <div className="bottomJson">
                                                <lottie-player
                                                    src="uds/tm.json"
                                                    background="transparent"
                                                    speed={1}
                                                    style={{ width: 250, height: 250 }}
                                                    loop=""
                                                    autoPlay=""
                                                />
                                            </div>
                                        </div>

                                        }
                                        {/*============== New Code ==============*/}
                                        <div className='col-md-12 text-center'>
                                            {surveyQues && surveyQues && surveyQues[quesNo].layout != 7 && quesNo < surveyQues.length - 1 && button && (<div className='py-3'><button className=' mainbtn ' onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}  >{nextButtonText("next")}</button></div>)}

                                        </div>
                                        {surveyQues && surveyQues[quesNo].type == 'cxNote' && <div className='col-md-12 text-center'>
                                            {surveyQues && surveyQues && surveyQues[quesNo].layout != 7 && quesNo < surveyQues.length - 1 && (<div className='py-3'><button className=' mainbtn ' onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}  >Start Now</button></div>)}

                                        </div>}
                                        {surveyQues && quesNo == surveyQues.length - 1 && surveyQues[quesNo].layout !== 7 && button && (<div className='w-100 text-center my-4'>
                                            <a className='mainbtn btn-orange  text-white px-4' onClick={(e) => props.match.path == '/template/:id' ? setTemplate() : goToNextQuestion(surveyQues[quesNo])}>{props.match.path === '/template/:id' ? 'Use Template' : nextButtonText("submit")}</a><br /><br />
                                            {props.match.path === '/template/:id' && <a className='mainbtn  text-white px-4' onClick={(e) => history.push('/surveyTemplates')}>Go Back</a>}

                                        </div>)}
                                        <div className='col-md-12 text-center'>
                                            {surveyQues[quesNo].condition === "Start Button" && (<div className='py-3'><button className=' mainbtn ' onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}  >Start Survey</button></div>)}
                                        </div>
                                    </>}

                                </>}
                                {/* {surveySubmited && survey.maturity_category &&
                                    <div className="scoreMain">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-md-6">
                                                    <div className="leftSec">
                                                        <figure className="m-0">
                                                            <img src="../images/clickHere-img.svg" alt="" width={280} />
                                                        </figure>
                                                        <lottie-player
                                                            src="../images/thank-you-simple.json"
                                                            loop=""
                                                            autoPlay=""
                                                            style={{ height: 100, width: 200, margin: "auto" }}
                                                        /> 
                                                         <p>
                                                        We are so grateful for your answers! Reach out to us for more
                                                        details
                                                    </p>
                                                    <a href="" className="clickHere">
                                                        Click here
                                                    </a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="rightSec">
                                                        <div className="innerScore">
                                                            <div className='confettiLottie'>
                                                                <lottie-player
                                                                    src="../images/confetti (3).json"
                                                                    className=""
                                                                    loop=""
                                                                    autoPlay=""
                                                                    style={{ height: 260, width: 270, margin: "auto" }}
                                                                /></div>
                                                            <p>Your Maturity Assessment Score </p>
                                                            <span>
                                                                {maturitCount} <b>{Object.entries(survey.maturity_category).find(([key, range]) => maturitCount >= range[0] && maturitCount <= range[1])[0]}</b>
                                                            </span>
                                                        </div>
                                                        <ul className="scoreUl mt-3">
                                                            <li>
                                                                <b />
                                                                &lt;=40 Initiation
                                                            </li>
                                                            <li>
                                                                <b /> 41 to 60 Fragmented
                                                            </li>
                                                        </ul>
                                                        <ul className="scoreUl second">
                                                            <li>
                                                                <b /> 61-80 Managed
                                                            </li>
                                                            <li>
                                                                <b /> 81-100 Established
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>} */}
                                {surveySubmited && <div className="row m-0">
                                    <div className="col-md-12 p-0">

                                        <div className="w-100 qusSec px-4 py-5 h-100 text-center congSrc">
                                            {(surveyStutas == 'complete') && !survey.complatedMsg && <h3 style={{ color: "#ee6917" }}>Congratulations!! </h3>}
                                            <lottie-player src="../images/112766.json" background="transparent" speed={1} className=" text-center mx-auto d-block" style={{ height: '150px' }} loop autoPlay>
                                            </lottie-player>
                                            {(surveyStutas == 'complete') && survey.complatedMsg && <h3 style={{ color: "#ee6917" }}>{survey.complatedMsg} </h3>}
                                            {/* <h3 style={{ color: "#ee6917" }}>You may also visit our google page and share your experience by clicking below:<br/><a href='' target='_blank' className='btn btn-primary mt-3'>Click here</a> </h3> */}
                                            {(surveyStutas == 'complete') && survey.SecondComplatedMsg && eval(survey.SecondComplatedMsgCondition) && <span style={{ color: "#ee6917" }}>{survey.SecondComplatedMsg} <br /><br /><a href={survey.buttonLink} target='_blank' className='mainbtn mt-3'>Click here</a></span>}

                                            {(surveyStutas == 'complete') ? !survey.complatedMsg && <p style={{ fontWeight: 600 }} className="mb-5">You have successfully submited the survey</p> : (surveyStutas == 'Survey Already Submited') ? <p style={{ fontWeight: 600 }} className="mb-5">Thank You<br />Survey Already Submited</p> : <p style={{ fontWeight: 600 }} className="mb-5">Thank You<br />You are not eligible for this survey</p>}

                                            {/* {survey.maturity_category && <p style={{ fontWeight: 600 }} className="mb-5">Your maturity assessment count  {maturitCount} you fall under {
                                                Object.entries(survey.maturity_category).find(([key, range]) => maturitCount >= range[0] && maturitCount <= range[1])[0]} category</p>} */}
                                        </div>
                                    </div>
                                </div>}


                                <div className='d-flex flex-column align-items-center' style={{ gap: '15px' }}>
                                    {filee == "galley" && <input type="file" name="file" accept='*' onChange={handleImageChange} multiple />}
                                    {/* {filee == "galley" && Images && Images?.map(e=>{<img src={e} style={{ width: '100%', maxWidth: 400 }} />})} */}
                                    {filee == "galley" && Images.length !== 0 ? (
                                        <button onClick={sevaImage1} className="btn btn-primary"> Upload
                                        </button>
                                    ) : ""}
                                    {filee == "audio" && <input type="file" name="file" accept='audio/*' onChange={handleImageChange} multiple />}
                                    {filee == "audio" && Images.length !== 0 ? (
                                        <button onClick={() => sevaImage1("audio")} className="btn btn-primary"> Upload
                                        </button>
                                    ) : ""}
                                    {filee == "Tata" && <input type="file" name="file" accept='*' onChange={handleImageChange} multiple />}
                                    {filee == "Tata" && Images.length !== 0 ? (
                                        <button onClick={() => sevaImage1("Tata")} className="btn btn-primary"> Upload
                                        </button>
                                    ) : ""}
                                </div>
                                {/* {!surveyScreen && !surveySubmited &&!emailScreen&& <div className="row m-0">
                                    <div className="col-md-12 p-0">

                                        <div className="w-100 qusSec px-4 py-5 h-100 text-center congSrc ">

                                            <lottie-player src="../images/112766.json" background="transparent" speed={1} className="mt-10 text-center mx-auto d-block" style={{ height: '150px' }} autoPlay>
                                            </lottie-player>
                                            <h3 className='mt-5' style={{ color: "#ee6917" }}>This Survey Is Closed </h3>
                                          
                                        </div>
                                    </div>
                                </div>} */}

                            </div>
                        </div>

                    </div>

                    {/* <Keyboard
                        keyboardRef={r => (keyboardRef.current = r)}

                        layout={layouts[layout]}


                        layoutName={shiftMode ? 'shift' : 'default'}


                        onChange={(e) => setAnswer({ target: { value: e } }, surveyQues[quesNo].type, surveyQues[quesNo].options[0])}
                        onKeyPress={onKeyPress}


                    /> */}
                </div>
                {!survey?.hideBackButton && surveyQues && surveyQues[quesNo].layout !== 7 && <div className='w-100' style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
                    <div className='container'>
                        <div className='progress-survey w-100 bg-white com-bsl m-0 p-2'>
                            {surveyQues && quesNo > 0 && !surveySubmited && <div className='p-0' onClick={(e) => { backQuestion(surveyQues[quesNo]) }}>
                                <div className='d-inline-block bg-blue text-center w-fit rounded-2 px-4 py-2 '><img style={{ 'width': 25 }} src='images/left-chevron.png' /></div>
                            </div>}

                        </div>
                    </div>
                </div>}

            </section>}
            {surveyQues && surveyQues[quesNo]?.layout === 8 && (<>

                {surveyScreen && (<div className="surveyContent">
                    <div className="container">
                        <div className="surveyQuestion my-1">
                            <h1>
                                {surveyQues && surveyQues[quesNo].question}
                            </h1>
                            {surveyQues && surveyQues[quesNo].que_message && <h3>
                                {surveyQues && surveyQues[quesNo].que_message}
                            </h3>}
                            {surveyQues && surveyQues[quesNo].topic && <div className="questionsBadge">
                                <img src={surveyQues[quesNo]?.topicImg} alt="" width="20" />
                                <span>{surveyQues && surveyQues[quesNo].topic}</span>
                            </div>}
                            {(surveyQues && surveyQues[quesNo].type == 'nps') ?
                                <><ul className="npsRating mt-4">
                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                        <li key={index} onClick={() => setAnswer(index, "rating", surveyQues[quesNo])}>
                                            <input id="npsRating1" type="radio" name="radio" />
                                            <label className={index < 7 ? `bg-danger ${answer && answer === index && "npsChecked"}` : index < 9 ? "bg-warning" : "bg-success"}>
                                                {option[displayKeys.option]}
                                            </label>
                                        </li>

                                    ))}</ul>
                                    <div className="questionName">
                                        <span className="text-danger">Not likely</span>
                                        <span className="text-success">Most likely</span>
                                    </div></> :
                                (surveyQues && surveyQues[quesNo].type == 'form') ? surveyQues[quesNo] && surveyQues[quesNo].options &&
                                    surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, ind) => (
                                        <div className="listMain">
                                            <strong>{option[displayKeys.option]}</strong>
                                            <ul className="selectedUl">
                                                {option && option.dropdown_option && option.dropdown_option.map((tag, inx) => (
                                                    <li onClick={() => { changeCss(inx, ind); setAnswer({ target: { value: tag.name } }, surveyQues[quesNo].type, option, surveyQues[quesNo]) }}>
                                                        <input type="radio" className="d-none" id='que' />
                                                        <label >
                                                            <div className="designInner" style={{ backgroundColor: tag.selected ? tag.color : "#fff" }}>
                                                                <img src={tag.image} alt="" width="20" style={{ filter: tag.selected ? "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(154deg) brightness(100%) contrast(100%)" : "none" }} />
                                                            </div>
                                                            <span>{tag.name}</span>
                                                        </label>
                                                    </li>

                                                ))}

                                            </ul>
                                        </div>

                                    )) : ""}
                            {surveyQues && surveyQues[quesNo].type == 'text' && (<>


                                <div className="form-group mb-3">
                                    <textarea className="form-control mb-2" rows="6" placeholder="Enter free text" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }} maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 200}></textarea>
                                    <b className="d-block text-end opacity-50 fw-medium">{answer[0]?.length}/200</b>
                                </div>
                                <button type="button" className="btn btn-primary-npci mx-auto d-block" onClick={() => goToNextQuestion(surveyQues[quesNo])}>Save</button>


                            </>)}

                            {surveyQues && surveyQues[quesNo].type == 'contact' && (<>

                                {surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, ind) => {
                                    return (<>
                                        {option.mobile == "true" && <div className="form-group  mb-3 inputDesi">
                                            <h3 className="text-start">{option[displayKeys.option]}</h3>
                                            <div className='d-flex gap-2'>
                                                <input type="text" className="form-control w-25" value="+91" />
                                                <input type="number" className="form-control" placeholder="Enter you mobile number" onChange={(e) => { setAnswer(e, 'contact', option, surveyQues[quesNo]) }} />
                                            </div>
                                        </div>}
                                        {!option.mobile && (<>
                                            <h3 className="text-start">{option[displayKeys.option]}</h3>
                                            <div className="form-group mb-3">
                                                <input type="text" className="form-control nameInput" style={{ height: "40px" }} placeholder="Enter Bank Name" onChange={(e) => { setAnswer(e, 'contact', option, surveyQues[quesNo]) }} />
                                            </div>
                                        </>)}
                                    </>)
                                })}
                                <button type="button" className="btn btn-primary-npci mx-auto d-block" onClick={() => { goToNextQuestion(surveyQues[quesNo]) }}>Submit</button>

                            </>)}
                            {surveyQues && surveyQues[quesNo].type == 'radio' && (<>
                                <ul className="questionType mb-4">
                                    {surveyQues[quesNo].options.map((option, index) => {
                                        return (<li className="quesLi" onClick={(e) => setAnswer({ target: { checked: true, value: option.option } }, surveyQues[quesNo].type, option, surveyQues[quesNo], index)}>
                                            <input className="d-none" type="radio" />
                                            <label >
                                                <span style={{ backgroundColor: '#ffff', color: '#000' }}> {option[displayKeys.option]}</span>
                                                <img src={option.image} alt="" width="26" />
                                            </label>
                                        </li>)
                                    })}


                                </ul>
                            </>)}

                        </div>
                    </div>

                </div>)}
                {!surveyScreen && (<>
                    <div className="surveyContent">
                        <div className="container">
                            <div className="surveyQuestion p-0 thankyouMain">
                                <div className="text-center">
                                    {/* <div className="py-5 pt-2">
                         <img src="images/rupay-logo.png" width={100} />
                                </div> */}
                                    <lottie-player
                                        src="images/thank-you-simple.json"
                                        loop=""
                                        autoPlay=""
                                        style={{ width: "100%", height: 100, margin: "auto" }}
                                    />
                                    <h3 className="text-center px-4 my-4">
                                        We are so grateful for your answers! This will guide us in creating a
                                        better experience for you.
                                    </h3>
                                </div>
                                <div className="thankuFooter">
                                    <img src="images/npci-logo.png" alt="" width={80} />
                                </div>
                            </div>
                        </div>
                    </div>

                </>)}

                {surveyQues && quesNo > 0 && !surveySubmited && <div className='p-0' onClick={(e) => { backQuestion(surveyQues[quesNo]) }}>
                    <div className='d-inline-block bg-blue text-center w-fit m-2 rounded-2 px-4 py-2 ' style={{
                        'position': 'fixed', 'bottom': '0'
                    }} ><img style={{ 'width': 15 }} src='images/left-chevron.png' /></div>
                </div>}
                {!survey?.maturity_category && <div className='w-100  powered-btn'>
                    <a href='https://qdegrees.com/' target="_blank"> <div className='mainbtnblue m-2 position-fixed bottom-0 end-0'><small className='f-12'>Powered by</small>&nbsp;<b className='f-14'>QDegrees</b></div></a>
                </div>}

            </>)}

            <div style={{
                'position': 'fixed', 'bottom': '0',
                'margin': 'auto',
                'width': '100%',
                'z-index': '0'
            }} className=' text-center  end-0' dangerouslySetInnerHTML={{ __html: survey?.footer && survey.footer }}></div>


            <div
                className="modal fade survey-popup"
                id="from-scratch-modal03"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            // tabindex="-1"
            >
                <div className="modal-dialog choosingseew h-100 modal-dialog-centered survey-title-modal">
                    <div className="modal-content">
                        <div className="modal-header pb-0">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body  text-center arownew p-4 model-image-newsurvey">

                            <div className="mb-4" >
                                {inputTag == "Colum" && <h5 className="fs-1 text-blue fw-light">Enter new company name.</h5>}
                                {inputTag == "Row" && <h5 className="fs-1 text-blue fw-light">Enter new product name.</h5>}
                            </div>

                            {surveyQues?.[quesNo]?.addOpButton && inputTag == "Colum" && <input className='card2 card border-0 px-10 py-1  mb-4' type='text' placeholder='Enter here' value={newmatValue} onChange={(e) => setNewmatValue(e.target.value)} />}
                            {surveyQues?.[quesNo]?.addRowButton && inputTag == "Row" && <input className='card2 card border-0 px-10 py-1  mb-4' type='text' placeholder='Enter here' value={newmatValue} onChange={(e) => setNewmatValue(e.target.value)} />}
                            <button className='mainbtn' data-bs-dismiss="modal"
                                aria-label="Close" onClick={addNewMatOption}>save</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </main >
    )
}

export default DirectSurvey
