import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { message, Upload } from 'antd';
import Dropdown from '../Components/ReusableComponents/dropdown';
import 'react-circular-progressbar/dist/styles.css';
import API from '../utils/apiCalling';
import env from '../config/env';
import Multiselect from 'multiselect-react-dropdown';

import { useParams, useHistory, useLocation } from "react-router-dom";
import Loader from '../utils/loader';
import { capitalizeFirstLetter } from '../utils/CommonFunction';
import { Button, Select, Form, Input, TextArea } from 'antd';
import JoditEditor from 'jodit-react';
import Config from '../config/env'
import { SortableContainer, SortableElement, arrayMove, } from 'react-sortable-hoc';
import { BsThreeDotsVertical } from "react-icons/bs";



function Scratchsurvey(props) {
    const editor = useRef(null);
    const editor1 = useRef(null);
    const [content, setContent] = useState('');
    const [content1, setContent1] = useState('');
    const [header, setHeader] = useState(false)
    let matIndex = [{ name: <span style={{ width: '24rem' }}><input type='text' id='matDropdownItem' placeholder='Enter option Here ' /><button className='btn  text-orange' onClick={(e) => addDropdownOption(3)}>save</button></span>, value: "add" }]
    let api = new API()
    let history = useHistory();
    const location = useLocation();
    const { id } = useParams();
    let getUser_Id = JSON.parse(localStorage.getItem("user"));
    let survey = props.survey
    const closeModel = useRef(null);
    const openModel = useRef(null);
    const saveModal = useRef(null)
    let access = props.access
    const [surveyType, setSurveyType] = useState("Digital Surveys (CAWI)");
    const [exCompletes, setExCompletes] = useState('');
    const [Suvrey, setSuvrey] = useState([]);

    const [exIncidence, setExIncidence] = useState();
    const [exLOI, setExLOI] = useState();
    const [surveyCategoru, setSurveyCategory] = useState();
    const [optionIndex, setOptionIndex] = useState()
    const [questions, setQuestions] = useState('');
    const [surveyTitle, setSurveyTitle] = useState();
    const [active, setactive] = useState(true);
    const [surveyQuestion, setSurveyQuestion] = useState('');
    const [options, setOptions] = useState('');
    const [addOptionLabel, setOptionLabel] = useState("Add Choice")
    const [optionValue, setOptionValue] = useState([]);
    const [questionsArray, setQuestionsArray] = useState([])
    const [loader, setLoader] = useState(false)
    const [divClassl, setDivClassl] = useState(" ")
    const [divIdl, setDivIdl] = useState("sidebarl")
    const [divClassrig, setDivClassrig] = useState(" ")
    const [divIdrig, setDivIdrig] = useState("sidebarrig")
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(survey?.logo && survey?.logo != "" ? survey?.logo : "")
    const [isRequired, setIsRequired] = useState("no")
    const [inputFields, setInputFields] = useState([{ option: "", action_id: "", text_box: isRequired }])
    const [userData, setUserData] = useState()
    const [optionType, setOptionType] = useState()
    const [optionName, setOptionName] = useState({ type: 'Select Question Type', subType: "" })
    const [required, setRequired] = useState("required")
    const [multipleSelection, setMultipleSelection] = useState("single")
    const [rating, setRating] = useState({ min: 1, max: 1 })
    const [matOptions, setMatOptions] = useState([""])
    const [matDropdown, setMatDropdown] = useState([{ name: <span style={{ width: '24rem' }}><input type='text' id='matDropdownItem' placeholder='Enter option Here ' /><button className='btn  text-orange' onClick={(e) => addDropdownOption(3)}>save</button></span>, value: "add" }])
    const [questionArray, setQuestionArray] = useState()
    const [layout, setLayout] = useState(1)
    const [layoutOption, setLayoutOption] = useState(false)
    const [logicData, setLogicData] = useState([])
    const [logicOption, setLogicOptiond] = useState(null)
    const [surveys, setSurveyId] = useState([])
    const [qid, setQid] = useState()
    const [audience, setAudience] = useState();
    const [audio, setAudio] = useState(false)
    const [lang, setLang] = useState([])
    const [language, setLanguage] = useState([]);
    const [displayKeys, setDisplayKeys] = useState({ question: 'question', option: 'option', que_message: "que_message" })
    const [subQuestionType, setSubQuestionType] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [surveyId, setSurvey_Id] = useState()
    const [questionType, setQuestionType] = useState([])
    const [questionImage, setQuestionImage] = useState("")
    const [textLength, setTextLength] = useState({ case: false, length: 50 })
    var [selectedLang, setSelectedLang] = useState([])
    const [surveycategory, setSurveycategory] = useState();
    const [QuestionNo, setQuestion] = useState()
    const [MoveQuestion, SetMoveQuestion] = useState(null)
    const [position, setposition] = useState(null)
    const [contactForm, setContactForm] = useState(["FullName", "Email ID", "Company", "Address", "City", "ZipCode", "Counrty", "State", "Phone No."])
    const [finalContactForm, setFinalContactForm] = useState([])
    const [isDropdownOpened, setIsDropdownOpened] = useState(null)
    const [isOtherDisplayed, setIsOtherDisplayed] = useState(false);
    const [isTextRequired, setIsTextRequired] = useState(false);
    const [isOtherOptionCheck, setisOtherOptionCheck] = useState(false);
    const [ratingStar, setRatingStar] = useState(0)

    const filterSingleSelect = questionType.filter(item => item.name === 'Single Select')[0];

    const dropdownRef = useRef(null);


    const setMoveQuestions = (value, index) => {
        setQuestion(index + 1)
        setLogicData(value)
    }
    const saveMoveQuestion = (e) => {

        let questionmove = QuestionNo - 1
        let Position = Number(MoveQuestion) + Number(position)
        let old_index = questionmove
        let new_index = Position
        let arr = questionArray
        while (old_index < 0) {
            old_index += arr.length;
        }
        while (new_index < 0) {
            new_index += arr.length;
        }
        if (new_index >= arr.length) {
            var k = new_index - arr.length;
            while ((k--) + 1) {
                // arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        SetMoveQuestion('')
        setposition('')
        SaveLogic()
    }

    const config = useMemo(() =>
    ({
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: 'Start typings...'
    }),
        []
    );


    const SetSuvrey = (value) => {
        history.push(`/createSurvey/${value}`);
    };

    const addDasgboard = async () => {
        setLoader(true)
        let data = {
            survey_name: surveyTitle,
            User_Id: getUser_Id._id,
            survey_id: "",
            points: "",
            status: "Active",
            survey_type: "open",
            createdBy: "",
            is_shareable: "",
            languages: SelectLng([], "update"),
            response_Limit: exCompletes,
            Incidence: exIncidence,
            Loi: exLOI,
            audio: audio,
            category_id: surveyCategoru,
            audience: audience,
            questions: questionArray
        };


        let result = await api.post(
            `${Config.new.site_api}/client-survey/createNewSurvey`,
            { survey: JSON.stringify(data) }
        );
        if (result.code === 200) {
            toast.success("Survey Added Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            })
            closeSurveyModel()
            localStorage.setItem("survey", JSON.stringify(result.data));
            SetSuvrey(result.data._id);
            history.push('/createSurvey/' + result.data._id)
        }
        setLoader(false)
    }

    const closeSurveyModel = () => {
        closeModel.current.click();
    }

    const openCreationModel = () => {
        openModel.current.click();
    }

    const uploadOptionImage = async (event, index) => {
        if (event == 'remove') {
            let options = [...inputFields]
            options[index].imageUrl = ""

            setInputFields(options)
        } else {
            setLoader(true)
            let newData = new FormData()
            newData.append("file", event.target.files[0])
            let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadAttachment`, newData)
            if (result.code === 200) {
                let options = [...inputFields]
                options[index].imageUrl = result.data

                setInputFields(options)
            }
            setLoader(false)
        }

    }


    const uploadImage = async (event) => {
        setLoader(true)
        let newData = new FormData()
        newData.append("file", event.target.files[0])
        let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadAttachment`, newData)
        if (result.code === 200) {
            setQuestionImage(result.data)
        }
        setLoader(false)
    }
    const getLanguage = async (e) => {
        setLoader(true)
        const result = await api.post(`${Config.new.site_api}/survey/getLanguage`, {})
        if (result && result.code === 200) {
            setLanguage(result.data);
            let a = []
            result.data.forEach(ele => {
                a.push(ele.lang_name)
            })
            setLang(a)
        }
        setLoader(false)
    }

    const SelectLng = (e, mode) => {
        if (mode == "setData") {
            let options = e
            setSelectedLang(options)
        }
        else {

            let tem = []
            language.forEach(l => {
                if (selectedLang.includes(l.lang_name)) {
                    tem.push(l)
                }

            })
            return tem
        }
    }

    const pagination = (mode) => {
        if (mode == 'prev' && qid > 1) {
            editQuestion(qid - 2, questionArray[qid - 2], questionType)
        }
        else if (mode == 'next' && qid != questionArray.length) {
            editQuestion(qid, questionArray[qid], questionType)
        }
    }
    const setAudienceHandle = (id) => {
        setAudience(id);
        if (id === "Buy Audience") {
            setactive(false);
        } else if (id === "My Own Audience") {

            setactive(true);
            // setExCompletes();
            setExIncidence();
            // setExLOI('');
        }
    };

    const saveSurvey = (mode) => {
        if (questions == "" || mode == 'save') {
            let data = {
                Id: survey._id,
                survey_name: survey.survey_name,
                category_id: survey.category_id,
                audience: survey.audience,
                response_Limit: survey.response_Limit,
                Incidence: survey.Incidence ? survey.Incidence : "",
                Loi: survey.Loi,
                saved: 1
            }
            setLoader(true)
            api.post(`${env.new.site_api}/client-survey/EditSurvey`, data).then((res) => {
                if (res.code === 200) {
                    toast.success("Survey Saved Succesfully", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setType(filterSingleSelect)
                    setQuestionArray([...questionArray, questionArray])
                    setIsTextRequired(false);
                    setisOtherOptionCheck(false);

                    props.setSurvey(res.data)
                    setSurveyId(res.data)
                    editQuestion(0, res.data.questions[0], questionType)
                    saveModal.current.click()
                }
                else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    saveModal.current.click()

                }
                setLoader(false)
            })
        }
        else {
            createSurvey('save', 'save')
        }
    }


    const setTemp = (value, index, array) => {
        if (surveys.saved == 1) {
            return
        } else {
            // if (optionType == "rating" || optionType == 'rating') {
            //     index == 'min' ? setRating({ ...rating, min: Number(value) }) : setRating({ ...rating, max: Number(value) })
            //     let max = index == 'min' ? rating.max : Number(value);
            //     let min = index == 'max' ? rating.min : Number(value);
            //     let temp = [{ option: "", action_id: "", text_box: isRequired }]
            //     if (max > min) {

            //         {
            //             for (let i = min; i <= max; i++) {
            //                 temp[temp.length - 1] = { option: i, action_id: "" }
            //                 if (i < max) {
            //                     temp.push({ option: "", action_id: "" })
            //                 }
            //                 else {
            //                     setInputFields(temp)
            //                 }
            //             }
            //         }
            //     }
            //     else {
            //         for (let i = min; i >= max; i--) {
            //             temp[temp.length - 1] = { option: i, action_id: "" }
            //             if (i > max) {
            //                 temp.push({ option: "", action_id: "" })
            //             }
            //             else {
            //                 setInputFields(temp)
            //             }
            //         }
            //     }
            // }
            if (optionType == "rating" || optionType == 'rating') {
            
                setRatingStar(value);
                const min = 1;
                const max = value;
                let temp = [{ option: "", action_id: "", text_box: isRequired }]        
                for (let i = min; i <= max; i++) {
                    temp[temp.length - 1] = { option: i, action_id: "" };
                    if (i < max) {
                        temp.push({ option: "", action_id: "" });
                    } else {
                        setInputFields(temp);
                    }
                }
            }
            else if (optionType == 'matrix') {
                if (array == 'matOptions') {
                    let arr = [...matOptions]
                    arr[index] = value
                    setMatOptions(arr)
                }
                else {
                    let arr = [...inputFields]
                    arr[index][displayKeys.option] = value
                    setInputFields(arr)
                }
            }
            else if (optionType !== "rating") {
                let temp = [...inputFields]
                temp[index][displayKeys.option] = value
                setInputFields(temp)
            }
        }


    }

    const setLogicOption = (e, option, index) => {
        let arr = [...logicData]
        arr[0].options[index].value = e

        if ((e) === "p1") {
            arr[0].options[index].disable = false
        } else {
            arr[0].options[index].disable = true
            arr[0].options[index].action_id = e
        }

        setLogicData(arr)

    }
    const setDeleteQuestion = (e) => {
        DeleteQuestion(questionArray.filter((Obj) => Obj._id !== e.target.value))
    }
    const setLogicQuestion = (e, option, index) => {
        let arr = [...logicData]
        arr[0].options[index].logicQue = e.target.value
        option.action_id = e.target.value
        setLogicData(arr)
    }

    const setLogic = (id) => {
        let filterQuestion = questionArray.filter(e => e._id === id)
        setLogicData(filterQuestion)

    }

    const getTypes = async (userData) => {
        setLoader(true)
        let newData = {
            parent_id: ""
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/getQuestionType`, newData)
        if (result.code === 200) {

            setQuestionType(result.data)
            getSurveyById(((id === undefined) ? survey?._id : id), result.data)
            setSurvey_Id(((id === undefined) ? survey?._id : id))
        }
        else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        setLoader(false)
    }
    const showToast = (use, message) => {
        if (use == 'upgrade') {
            toast.warn(message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    useEffect(() => {
        setType(filterSingleSelect)
    }, [questionType])


    const setType = async (event) => {
        setLoader(true)
        if (!event?.plan.includes(userData?.plan_id)) {
            // showToast('upgrade','To use this type of question , Upgrade To higher Package')
            return
        }
        if (event.value == "radio" || event.value == "checkbox") {
            setInputFields([{ option: "", action_id: "", text_box: isRequired }])
            setLayoutOption(true);
            setOptionLabel("Add Choice")
        }
        else {
            setOptionLabel("Add Label")
            setLayoutOption(false)
            setLayout(1)
        }
        if (event.value == "contact") {
            setFinalContactForm()
        }
        if(event.value=="csat"){
            let arr = []
            arr.push({ option: "Angry", action_id: "" })
            arr.push({ option: "Upset", action_id: "" })
            arr.push({ option: "Neutral", action_id: "" })
            arr.push({ option: "Happy", action_id: "" })
            arr.push({ option: "Excited", action_id: "" })
            setInputFields(arr)
        }

        if(event.value=='nps'){
        setInputFields([{ option: "", action_id: "", text_box: isRequired }])
        }
        if(event.value=='rating'){
            setRatingStar(0)
        }
        let option = { ...optionName }
        option.type = event.name
        setOptionName(option)
        setOptionType(event.value)
        let newData = {
            parent_id: event.value
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/getQuestionType`, newData)
        if (result.code === 200) {
            if (result.data.length > 0) {
                option.subType = result.data[0].name
                setOptionName(option)
                setSelection(result.data[0], event.value, event.name)
            }
            setSubQuestionType(result.data)
        }
        else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        setLoader(false)
    }




    const setSelection = (option, type = optionType, optionNaam) => {
        if (type == 'csat') {
            if (option.value == 'emoji') {
                let arr = []
                arr.push({ option: "Angry", action_id: "" })
                arr.push({ option: "Upset", action_id: "" })
                arr.push({ option: "Neutral", action_id: "" })
                arr.push({ option: "Happy", action_id: "" })
                arr.push({ option: "Excited", action_id: "" })
                setInputFields(arr)
            }

        }
        setMultipleSelection(option.value)
        setOptionName({ ...optionName, subType: option.name, type: optionNaam || optionName.type })
    }

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'))
        setUserData(user)
        getTypes(user)

        { location.pathname == `/useTemplate/${id}` && openCreationModel() }
        getLanguage();
        getsueveyCategory(user?._id);
    }, [logicData])

    const getsueveyCategory = async (id) => {
        setLoader(true)
        let data = {
            userId: id,
        };
        let result = await api.post(
            `${Config.new.site_api}/client-survey/getSurveyCategory`,
            data
        );
        if (result.code === 200) {

            setSurveycategory(result.data);
            let data = {
                category: "All Categories",
                _id: ""
            }
            let temp = [data]

            result.data.forEach((item) => {
                temp.push(item)
            })
            //   setStage(data)
            setSurveycategory(temp);
            //   getSurveyByUserId(data._id);
        }
        setLoader(false)
    };

    const removeFields = (index, array) => {
        if (array !== 'mat') {
            let data = [...inputFields];
            data.splice(index, 1)
            setInputFields(data)
        }
        else {
            let data = [...matOptions];
            data.splice(index, 1)
            setMatOptions(data)
        }
    }

    var mongoObjectId = function () {
        var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    };



    const fieldEmpty = () => {
        setFinalContactForm([])
        setQuestions('')
        setSurveyQuestion('')
        setInputFields([{ option: "", action_id: "", text_box: isRequired }])
        setMatOptions([""])
        setQuestionImage("")
        setOptionValue([])
        setLayout(1)
        setLayoutOption(false);
        setOptionType()
        setOptionName({ type: 'Select Question Type', subType: "" })
        setOptions('')
        setRating({ min: 1, max: 1 })
        setOptionType('')
        setRequired('required')
        setMultipleSelection('single')
    }




    let values = {
        _id: mongoObjectId(),
        questions: questions
    }



    const addNewOptionCol = (array) => {
        // document.querySelectorAll("#flexSwitchCheckDefault").forEach((val) => {

        //     if (val.checked) {

        //         val.checked = false
        //     }
        //     // val.value = ""
        // })
        if (surveys.saved == 1) {
            return
        }
        else {
            if (array == 'matOptions') {
                setMatOptions([...matOptions, ""])
                setIsRequired("no")
            }
            else {
                let newfield = array == 'other' ? { option: 'Other', action_id: "", text_box: 'yes' } : { option: "", action_id: "", text_box: isRequired }
                setInputFields([...inputFields, newfield])
                setIsRequired("no")
            }
        }
    }

    const removeLogo = () => {
        setLoader(true)
        api.post(`${env.new.site_api}/client-survey/UpdateSurveylogo`, { _id: survey._id }).then((res) => {
            if (res.code !== 200) {
                toast.warn("Unable To Delete The Logo , please try Again", {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
            else {
                setImageUrl(null)
            }
            setLoader(false)
        })
    }

    const changeclassnamel = () => {
        if (divIdl === "sidebarl" && divClassl === " ") {
            setDivClassl("handleClassNameAndIdl")
            setDivIdl(" ")
        } else {
            setDivClassl(" ")
            setDivIdl("sidebarl")
        }
    }

    const changeclassnamerig = () => {
        if (divIdrig === "sidebarrig" && divClassrig === " ") {
            setDivClassrig("handleClassNameAndIdrig")
            setDivIdrig(" ")
        } else {
            setDivClassrig(" ")
            setDivIdrig("sidebarrig")
        }
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';



        const isLt2M = file.size / 1024 / 1024 < 10;

        if (!isLt2M) {
            message.error('Image must smaller than 10MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const { Dragger } = Upload;

    const setFormOption = (option, index) => {
        let temp = [...inputFields]
        temp[index]["option_type"] = option.value
        temp[index]["option_type_name"] = option.name
        if (option.value == "dropdown") {
            temp[index]["dropdown_option"] = [{ name: <span style={{ width: '24rem' }}><input type='text' id='dropdownOption' placeholder='Enter option Here ' /><button className='btn  text-orange' onClick={(e) => addDropdownOption(1)}>save</button></span>, value: "add" }]
        }
        if (option.value == "dropdowns") {
            temp[index]["dropdown_option"] = [{ name: <span style={{ width: '24rem' }}><input type='text' id='dropdownOption' placeholder='Enter option Here ' /><button className='btn  text-orange' onClick={(e) => addDropdownOption(1)}>save</button></span>, value: "add" }]
            temp[index]["dropdown_option1"] = [{ name: <span style={{ width: '24rem' }}><input type='text' id='dropdownOption1' placeholder='Enter option Here ' /><button className='btn  text-orange' onClick={(e) => addDropdownOption(2)}>save</button></span>, value: "add" }]

        }
        setInputFields(temp)
    }

    const addDropdownOption = (dropdown, editOptions, newIndex) => {

        if (dropdown == 1) {
            if (document.getElementById('dropdownOption').value == "") {
                toast.warn("Dropdown Option Can not be Blank", {
                    position: toast.POSITION.TOP_RIGHT
                })
                return
            }

            let options
            let optionIndex1
            if (editOptions && newIndex !== undefined) {
                options = [...editOptions]
                optionIndex1 = newIndex
            } else {
                options = [...inputFields]
                optionIndex1 = optionIndex
            }
            let len = options[optionIndex1]['dropdown_option'].length
            options[optionIndex1]['dropdown_option'][len] = document.getElementById('dropdownOption').value
            document.getElementById('dropdownOption').value = ""
            setInputFields(options)
        }
        else if (dropdown == 2) {
            if (document.getElementById('dropdownOption1').value == "") {
                toast.warn("Dropdown Option Can not be Blank", {
                    position: toast.POSITION.TOP_RIGHT
                })
                return
            }
            let options = [...inputFields]
            let len = options[optionIndex]['dropdown_option1'].length
            options[optionIndex]['dropdown_option1'][len] = document.getElementById('dropdownOption1').value
            document.getElementById('dropdownOption1').value = ""
            setInputFields(options)
        }
        else if (dropdown == 3) {
            if (document.getElementById('matDropdownItem').value == "") {
                toast.warn("Dropdown Option Can not be Blank", {
                    position: toast.POSITION.TOP_RIGHT
                })
                return
            }
            let options = [...matIndex]
            options[matIndex.length] = document.getElementById('matDropdownItem').value
            matIndex.push(document.getElementById('matDropdownItem').value)
            document.getElementById('matDropdownItem').value = ""
            setMatDropdown(options)

        }
    }



    const handleChange = async (info) => {
        setLoader(true)
        let newData = new FormData()
        newData.append("Id", id)
        newData.append("file", info.fileList[0].originFileObj)
        let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadLogo`, newData)
        if (result.code === 200) {
            setImageUrl(result.data.logo)
        }

        if (info.file.status === 'uploading') {
            // setLoading(true);
            return;
        }

        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
        setLoader(false)
    };



    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };


    // const isOther = (e) => {
    //     if (e.target.checked === true) {
    //         addNewOptionCol('other')
    //     }
    // }
    const removeOptionCol = (option) => {
        setInputFields(inputFields.filter(field => field.option !== option));
    };


    const isOther = (e) => {
        setisOtherOptionCheck(e.target.checked);
        if (e.target.checked) {
            setIsOtherDisplayed(true);
            addNewOptionCol('other');
        } else {
            setIsOtherDisplayed(false);
            removeOptionCol('Other');
        }
    };
    // const isText = (e) => {
    //     if (e.target.checked === true) {

    //         inputFields.forEach(e => {
    //             e.text_box = 'yes'
    //         })
    //     }
    //     else {
    //         inputFields.forEach(e => {
    //             e.text_box = 'no'
    //         })
    //     }
    // }

    const isText = (e) => {
        const checked = e.target.checked;
        setIsTextRequired(checked);
        
        inputFields.forEach(field => {
            field.text_box = checked ? 'yes' : 'no';
        });
    };

    const getSurveyById = async (id, types) => {
        setLoader(true)
        let data = {
            id: id
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/getSurvey`, data)
        if (result && result.code === 200) {
            setSurveyId(result.data)
            let TQuestionArray = result.data.questions
            // setQuestionArray(result.data.questions)
            setQuestionArray(TQuestionArray)

            if (result.data.saved == 1) { editQuestion(0, result.data.questions[0], types) }
        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        setLoader(false)
    }


    // Change dropdown option selected------------------------------------
    const label = document.querySelector(".dropdown__filter-selected");
    const options1 = Array.from(
        document.querySelectorAll(".dropdown__select-option")
    );
    options1.forEach((option) => {
        option.addEventListener("click", () => {
            label.textContent = option.textContent
        });
    });

    const createSurvey = async (mode, func) => {
        if (optionType && optionType !== '' && questions && questions !== "") {
           
            for(let i=0;i<inputFields.length;i++){
                if(optionType=='radio' || optionType == 'checkbox' || optionType == 'form'){

                    if(inputFields[i].option == "" || inputFields[i].option == null){   
                       
                        toast.error("Please enter option")
                        return
                    }
                }
            }  
            if (((optionType == 'radio' || optionType == 'checkbox' || optionType == 'form') && inputFields.length < 2) || (optionType == 'matrix' && inputFields.length < 2 && matOptions.length < 2)) {
                toast.warn("Question Should have minimum 2 options", {
                    position: toast.POSITION.TOP_RIGHT
                })
                return
            }
            let translatedQuestion
            let res
            let options = [...inputFields]
            let tempLanguage = []
            let contactOption = []
            if (optionType == 'text') {
                inputFields[0].option = ''
                options = inputFields.slice(0, 1)
            }
            if (optionType == 'form') {
                options.forEach(op => {
                    if (op.option_type == 'dropdown') {
                        if (op.dropdown_option.length > 1) {
                            op.dropdown_option.splice(0, 1)
                        }
                        else {
                            toast.error("Dropdown Can not be Empty", {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }

                    }
                    else if (op.option_type == 'dropdowns') {
                        if (op.dropdown_option.length > 1 && op.dropdown_option1.length > 1) {
                            op.dropdown_option.splice(0, 1)
                            op.dropdown_option1.splice(0, 1)
                        }
                        else {
                            toast.error("Dropdown Can not be Empty", {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                    }
                })
            }
            if (optionType == 'matrix' && multipleSelection == 'dropdown') {

                if (matDropdown.length > 1) {


                }
                else {
                    toast.error("Dropdown Can not be Empty", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }

            }
            if (optionType == 'contact') {
                finalContactForm.map((ele) => {
                    contactOption.push({ ...options[0], option: ele })
                })

            }



            if (optionType == "nps" && mode=="save") {
                setInputFields([{ option: "", action_id: "", text_box: isRequired }])
                for (let i = 0; i <= 10; i++) {
                    let newObj = {
                        action_id: "",
                        option: i.toString(),
                        text_box: "no"
                    };
                    inputFields.push(newObj)
                }
                inputFields.shift()
            }
            survey.languages.forEach(ele => {
                if (ele.lang_code != 'en') {
                    tempLanguage.push(ele.lang_code)
                }
            })
            if (optionType !== 'rating') {
                inputFields.forEach((op) => {
                    // op.option = op.option.trim()
                    if (typeof op.option === 'string') {
                        op.option = op.option.trim();
                    }
                })
            }
            if (mode == 'save') {
                if (access && questionArray.length >= access?.Survey_Designing[0]?.Number_of_Questions) {
                    toast.warn(`You can add only ${access?.Survey_Designing[0]?.Number_of_Questions} questions in this package, Upgrade Your Package to Add More.`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return
                }
                matDropdown.splice(0, 1)
                let obj = {
                    _id: mongoObjectId(),
                    question: questions.trim(),
                    que_message: surveyQuestion,
                    options: (optionType == 'form' || optionType == 'text') ? options : (optionType == 'contact') ? contactOption : inputFields,
                    matOption: matOptions,
                    layout: layout,
                    maxCharacter: textLength.case ? textLength.length : 255,
                    selection: multipleSelection,
                    type: optionType,
                    attachment: questionImage,
                    matDropdown: matDropdown,
                    condition: required
                }
                let setIndex = questionArray.length
                let tempQuestionArray = [...questionArray]
                tempQuestionArray.push(obj)

                setQuestionArray(tempQuestionArray)
                if (func == 'save') {
                    saveSurveyQuestion(tempQuestionArray, 'save')
                } else {
                    saveSurveyQuestion(tempQuestionArray)
                }
                setLoader(true)
                if (displayKeys.question == 'question') {
                    let res = await api.post(`${Config.new.site_api}/client-survey/translate`, { data: JSON.stringify(tempQuestionArray[setIndex]), lang: JSON.stringify(tempLanguage) })
                    if (res.code === 200) {

                        tempQuestionArray[setIndex] = res.data
                        setQuestionArray(tempQuestionArray)
                        saveSurveyQuestion(tempQuestionArray, "translate")

                        setLoader(false)
                    }
                }


            }

            else if (mode == 'edit') {
                let obj = { ...questionArray[qid - 1] }
                obj[displayKeys.question] = questions
                obj[displayKeys.que_message] = surveyQuestion
                obj["options"] = (optionType == 'form' || optionType == 'text') ? options : (optionType == 'contact') ? contactOption : inputFields
                obj["matOption"] = matOptions
                obj["layout"] = layout
                obj["selection"] = multipleSelection
                obj["maxCharacter"] = textLength.case ? textLength.length : 255
                obj["type"] = optionType
                obj["attachment"] = questionImage
                obj["condition"] = questionArray[qid - 1].condition
                obj["matDropdown"] = matDropdown


                let tempQuestionArray = [...questionArray]
                tempQuestionArray[qid - 1] = obj

                // setActionId(tempQuestionArray)
                setQuestionArray(tempQuestionArray)
                saveSurveyQuestion(tempQuestionArray)
                setLoader(true)
                if (displayKeys.question == 'question') {
                    let res = await api.post(`${Config.new.site_api}/client-survey/translate`, { data: JSON.stringify(tempQuestionArray[qid - 1]), lang: JSON.stringify(tempLanguage) })

                    if (res.code === 200) {
                        tempQuestionArray[qid - 1] = res.data
                        setQuestionArray(tempQuestionArray)
                        // saveSurveyQuestion(tempQuestionArray, "translate")
                    }
                }
                const _ID = surveyId !== undefined ? surveyId : survey._id;
        
                const data = {
                    _id: _ID,
                    questions: tempQuestionArray,
                };
                
                try {
                    const result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) });
                  
                } catch (error) {
                   
                }
                setLoader(false)

            }



        } else {
            optionType == "" || !optionType ?
                toast.error("Please Select Question Type", {
                    position: toast.POSITION.TOP_RIGHT
                }) : toast.error("Please Enter Question ", {
                    position: toast.POSITION.TOP_RIGHT
                })
        }
    }

    const editDropdown = (mode, index, dropdown, event) => {
        if (mode == 'del') {
            if (dropdown == 'dropdown_option') {
                let options = [...inputFields]
                options[optionIndex].dropdown_option.splice(index, 1)
                setInputFields(options)

            } else if (dropdown == 'dropdown_option1') {
                let options = [...inputFields]
                options[optionIndex].dropdown_option1.splice(index, 1)
                setInputFields(options)
            }
            else if (dropdown == 'matDropdown') {
                let options = [...matDropdown]
                options.splice(index, 1)
                setMatDropdown(options)
            }
        } else {
            if (dropdown == 'dropdown_option') {
                let options = [...inputFields]
                options[optionIndex].dropdown_option[index] = event.target.value
                setInputFields(options)

            } else if (dropdown == 'dropdown_option1') {
                let options = [...inputFields]
                options[optionIndex].dropdown_option1[index] = event.target.value
                setInputFields(options)
            }
            else if (dropdown == 'matDropdown') {
                let options = [...matDropdown]
                options[index] = event.target.value
                setMatDropdown(options)
            }
        }
    }

    const saveSurveyQuestion = async (obj, mode) => {
        setLoader(true)

        let _ID
        if (id === undefined) {
            _ID = survey._id
        } else {
            _ID = id
        }
        let data = {
            _id: _ID,
            questions: obj,
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) })
        if (result.code === 200) {
            setFinalContactForm([])
            if (mode != "translate" && mode != "save") {

                setQuestionsArray([])
                fieldEmpty()
                if (!editMode) {
                    toast.success("Question Added Successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setRatingStar(0)
                    setType(filterSingleSelect)
                    setIsTextRequired(false);
                    setisOtherOptionCheck(false);
                }
                else {
                    setEditMode(false)
                    setQid()
                    toast.success("Question Updated", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setRatingStar(0)
                    setType(filterSingleSelect)
                    setIsTextRequired(false);
                    setisOtherOptionCheck(false);
                }
            } else if (mode == 'save') {
                saveSurvey('save')
            }

        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        setLoader(false)

    }

    const SaveLogic = async () => {
        let _ID
        let arr = [...logicData]


        arr.length > 0 && arr.forEach((que) => {
            que.options.length > 0 && que.options.forEach((op) => {
                op.value && delete op["value"]
                op.disable && delete op["disable"]
                op.logicQue && delete op["logicQue"]
                op.dropdown_option && op.dropdown_option.length > 0 && op.dropdown_option.forEach((drop, index) => {
                    typeof drop == "object" && op.dropdown_option.splice(index, 1)
                    op.value && delete op["value"]
                    op.disable && delete op["disable"]
                    op.logicQue && delete op["logicQue"]
                })
            })
        })
        questionArray.filter((e, i) => {
            if (e._id == arr[0]._id) {
                questionArray[i] = arr[0]
            }
        })

        setLogicData(arr)
        if (id === undefined) {
            _ID = survey._id
        } else {
            _ID = id
        }
        let data = {
            _id: _ID,
            questions: questionArray,
        }
        setLoader(true)
        let result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) })

        if (result.code === 200) {
            setQuestionsArray([])
            fieldEmpty()
            toast.success("Set Successfully", {
                position: toast.POSITION.TOP_RIGHT
            })
             setType(filterSingleSelect)
             setRatingStar(0)
        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        setLoader(false)
    }

    const DeleteQuestion = async (value) => {
        let _ID
        if (id === undefined) {
            _ID = survey._id
        } else {
            _ID = surveys._id
        }
        let data = {
            _id: _ID,
            questions: value,
        }
        setLoader(true)
        let result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) })
        if (result.code === 200) {
            setQuestionArray(result.data.questions)
            fieldEmpty()
            toast.success("Question Deleted Successfully", {
                position: toast.POSITION.TOP_RIGHT
            })
            setRatingStar(0)
            setType(filterSingleSelect)
            setIsTextRequired(false);
            setisOtherOptionCheck(false);
        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        setLoader(false)
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : ""}
            <div className='f-12 fw-bold d-flex flex-column justify-content-around align-items-center'>
                <p className='text-orange m-0'>Upload Logo</p>
                <p className='m-0'>Drop your files here or</p>
                <span className='text-orange'>Browse</span>
            </div>

        </div>

    );
    const setHeaderFooter = async () => {
        let data = {
            _id: id,
            header: content,
            footer: content1,
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/setHeaderFooter`, data)
    }

    const contactCheckbox = (event) => {
        let { value, checked } = event.target;
        let form
        if (!finalContactForm) {
            form = [...contactForm]
        } else {
            form = [...finalContactForm]
        }
        // (finalContactForm.length == 0 && (finalContactForm = [...contactForm]))
        // let form = [...finalContactForm]

        if ((checked == true) || value == null || value == undefined) {
            if (!form.includes(value)) {
                // form.push(value)
                form.splice(contactForm.indexOf(value), 0, value)
            }
            setFinalContactForm(form)
        }
        else if (checked == false) {

            form.splice(form.indexOf(value), 1)
            // let arr = [...finalContactForm]
            setFinalContactForm(form)

        }


    }


    const editQuestion = (index, question, types) => {
        if (question.type == 'contact') {

            let contactOptions = []
            question.options.length > 0 && question.options.forEach((op) => {
                contactOptions.push(op.option)

            })
            setFinalContactForm(contactOptions)
            document.querySelectorAll("#que").forEach((val) => {

                if (contactOptions.includes(val.value)) {
                    val.checked = true
                } else {
                    val.checked = false
                }
            })

        }
        setQid(index + 1)
        setQuestions(question[displayKeys.question])
        setSurveyQuestion(question.que_message)
        setOptionType(question.type)
        setEditMode(true)
        setMultipleSelection(question.selection)
        setMatOptions(question.matOption)
        setMatDropdown(question.matDropdown)
        types.forEach((type) => {
            if (type.value == question.type) {
                setOptionName({ ...optionName, type: type.name })
            }
        })
        if ((!survey.saved || survey.saved !== 1) && question.type == 'form') {
            question.options.forEach((op, ind) => {
                if (op.option_type == 'dropdown' || op.option_type == 'dropdowns') {
                    op.dropdown_option?.unshift({ name: <span style={{ width: '24rem' }}><input type='text' id='dropdownOption' placeholder='Enter option Here ' /><button className='btn  text-orange' onClick={(e) => addDropdownOption(1, question.options, ind)}>save</button></span>, value: "add" })
                    op.dropdown_option1?.unshift({ name: <span style={{ width: '24rem' }}><input type='text' id='dropdownOption1' placeholder='Enter option Here ' /><button className='btn  text-orange' onClick={(e) => addDropdownOption(2)}>save</button></span>, value: "add" })
                }
            })
        }
        setInputFields(question.options)

    }

    const setQuestionLang = (e) => {
        setDisplayKeys({ ...displayKeys, question: e.target.value == "" || e.target.value == "en" ? 'question' : `question_${e.target.value}`, option: e.target.value == "" || e.target.value == "en" ? 'option' : `option_${e.target.value}` })
        if (qid)
            setQuestions(questionArray[qid - 1][e.target.value == "" || e.target.value == "en" ? 'question' : `question_${e.target.value}`])
    }

    const showLogicQue = (optionId) => {
        let a = questionArray && questionArray.filter((que) => { return (que._id === optionId) })
        if (a.length) {
            return a[0]._id
        } else {
            return ""
        }
    }

    const contactFormCheckbox = (value) => {
        if (!finalContactForm) {
            return true
        } else {
            let a = finalContactForm && finalContactForm.filter((option) => { return (option == value) })
            if (a.length) {
                return true
            } else {
                return false
            }

        }
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpened(null);
        }
    };


    const handleThreeDotClick = (id) => {
        setIsDropdownOpened(isDropdownOpened === id ? null : id);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const SortableItem = SortableElement(({ value, indexNumber, survey, questionType, editQuestion, setLogic, setMoveQuestions, setDeleteQuestion }) => (
        <div className='questions-tab py-3' style={{ borderBottom: "1px solid #afafaf50",  position:"relative" }}>
            <div className='question-count d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center cursor-pointer' role='button' onClick={(e) => survey.saved === 1 ? editQuestion(indexNumber, value, questionType) : null}>
                    <div className='bg-blue badge d-flex align-items-center '>

                        <i className="bi bi-check2 mr-2 fs-5"></i><span className=''>{indexNumber + 1}</span>
                    </div>
                    <p className='f-12 m-0 px-2'>{capitalizeFirstLetter(value[displayKeys.question])}</p>
                    <button className='btn threeDotBtnScratchSurvey2'
                        style={{ position: 'relative', right: '20px' }}
                        onClick={() => handleThreeDotClick(value._id)}
                        onMouseEnter={(e) => { e.target.style.backgroundColor = '#f47321'; e.target.style.border = 'none'; }}
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                    >
                        ⋮
                    </button>
                </div>
            </div>
            {isDropdownOpened === value._id && <ul
                ref={dropdownRef}
                style={{
                    position: 'absolute',
                    right: '20px',
                    width: "130px",
                    border: '1px solid #cdcacab8',
                    backgroundColor: 'white',
                    zIndex: '1000',
                    borderRadius: '5px'
                }}
            >
                <li>
                    <button
                        className="dropdown-item f-12 border-bottom px-3 py-2"
                        value={value._id}
                        onClick={(e) => {
                            setIsDropdownOpened(null);
                            setLogic(e.target.value)
                            setType(filterSingleSelect)
                            setRatingStar(0)
                            setIsTextRequired(false);
                            setisOtherOptionCheck(false);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModall-12"
                    >
                        Logic
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item f-12 border-bottom px-3 py-2"
                        value={value._id}
                        onClick={() => {
                            setIsDropdownOpened(null);
                            editQuestion(indexNumber, value, questionType)
                            setIsTextRequired(false);
                            setisOtherOptionCheck(false);
                        }}
                    >
                        Edit
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item f-12 border-bottom px-3 py-2"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModall-13"
                        value={value._id}
                        onClick={() => {
                            setIsDropdownOpened(null);
                            setMoveQuestions(value, indexNumber)
                            setType(filterSingleSelect)
                            setRatingStar(0)
                            setIsTextRequired(false);
                            setisOtherOptionCheck(false);
                        }}
                    >
                        Move
                    </button>
                </li>
                <li>
                    <button
                        className="dropdown-item text-danger f-12 px-3 py-2"
                        value={value._id}
                        onClick={(e) => {
                            setIsDropdownOpened(null);
                            setDeleteQuestion(e)
                        }}
                    >
                        Delete
                    </button>
                </li>
            </ul>
            }
        </div>
    ));

    const SortableList = SortableContainer(({ items, survey, questionType, editQuestion, setLogic, setMoveQuestions, setDeleteQuestion }) => {
        return (
            <div>
                {items.map((que, index1) => {
                    return (
                        <SortableItem
                            key={`item-${que._id}`}
                            index={index1}
                            indexNumber={index1}
                            value={que}
                            survey={survey}
                            questionType={questionType}
                            editQuestion={editQuestion}
                            setLogic={setLogic}
                            setMoveQuestions={setMoveQuestions}
                            setDeleteQuestion={setDeleteQuestion}

                        />)
                })}
            </div>
        );
    });

    // const onSortEnd = ({ oldIndex, newIndex }) => {
    //     setQuestionArray(arrayMove(questionArray, oldIndex, newIndex));
    // };

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        const newQuestionArray = arrayMove(questionArray, oldIndex, newIndex);
        setQuestionArray(newQuestionArray);
        
        const _ID = surveyId !== undefined ? surveyId : survey._id;
        
        const data = {
            _id: _ID,
            questions: newQuestionArray,
        };
        
        try {
            const result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) });
            // toast.success('Question updated');
        } catch (error) {
            toast.error('Not updating ');
        }
    };

    return (
        <>
            <div
                className="modal fade survey-popup"
                id="from-scratch-modal02"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <Form className="modal-dialog choosingseew h-100 modal-dialog-centered survey-title-modal">
                    <div className="modal-content">
                        <div className="modal-header pb-0">

                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />

                        </div>
                        <h4 style={{ textAlign: 'center' }}>Header & Footer</h4>
                        <div className="modal-body text-center arownew p-4 model-image-newsurvey">
                            <div className="row">
                                <div className="row-sm-12">
                                    <div className='w-100%'>
                                        <JoditEditor
                                            ref={editor}
                                            value={content}
                                            config={config}
                                            onChange={newContent => setContent(newContent)}
                                        />
                                    </div>
                                </div>&nbsp;
                                <div className="row-sm-12">
                                    <div className='w-100%'>
                                        <JoditEditor
                                            ref={editor1}
                                            value={content1}
                                            onChange={newContent1 => setContent1(newContent1)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button className='mainbtnblue tada mt-2' onClick={setHeaderFooter}>Submit</button>
                        </div>
                    </div>
                </Form>
            </div>
            {logicData &&

                <section>
                    {loader && <Loader />}
                    <div className="modal fade" id="saveModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 text-orange" id="exampleModalLabel">Save Survey</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={saveModal}></button>
                                </div>
                                <div className="modal-body">
                                    <h5><b>Are You Sure to Save the Survey ?</b></h5>
                                    <p>( No changes can be made once saved.
                                        If you need to make changes, kindly make a clone of the survey.)</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                                    <button type="button" className="btn btn-primary" onClick={(e) => { saveSurvey() }}>Yes </button>
                                    <a
                                        href={`https://doyoursurvey.com/#/review/` + id}
                                        target="_blank"
                                    >
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal logicItem_pop fade" id="exampleModall-12" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            {/* <form onSubmit={SaveLogic}> */}
                            <div className="modal-content">
                                <div className="modal-header align-items-center">
                                    <h1 className="modal-title m-0 p-0 fs-5 text-orange" id="exampleModalLabel">Question Logic</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                {(logicData[0]?.options)?.map((option, index) => (
                                    <div className="modal-body logicContens">
                                        <p className='d-flex p-0 m-0 mb-4 align-items-center'>
                                            <span>{index + 1}</span>
                                            <div className="dropdownnma w-100">
                                                <p>{option?.option}</p>
                                            </div>
                                            <span>is</span>
                                            <div className="dropdownnma w-100">
                                                <select className="dropdown__options-filter m-0" onChange={e => setLogicOption(e.target.value, option, index)} style={{ height: "42px" }} value={option.value ? option.value : ""} >
                                                    {/* logicOption.length>0&&logicOption.forEach(e=>{if(e==option) return e.option}) */}
                                                    <option value="">Choose page</option>
                                                    <option value="p1">P1</option>
                                                    <option value="final">End of Survey</option>
                                                    <option value="exit">Disqualification</option>
                                                </select>
                                            </div>
                                            <span>Skip to</span>
                                            {questionArray &&
                                                <div className="dropdownnma w-100">
                                                    <select className="dropdown__options-filter m-0" onChange={e => setLogicQuestion(e, option, index)} style={{ height: "42px" }} disabled={option.disable ? option.disable : ''} value={option.action_id != "" ? showLogicQue(option.action_id) : ""} >
                                                        <option value="">Choose Question</option>
                                                        {questionArray.map((Question, index) =>
                                                            <option value={Question._id}>Question {index + 1}</option>
                                                        )}
                                                    </select>
                                                </div>}
                                            <i className="bi bi-trash"></i>
                                        </p>
                                    </div>
                                ))}
                                <div className='my-4 d-flex justify-content-center'><button type='submit' className="mainbtn f-14" data-bs-dismiss="modal" aria-label="Close" onClick={() => SaveLogic()} >Save</button></div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                    <div className="modal logicItem_pop fade" id="exampleModall-13" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <form onSubmit={(e) => saveMoveQuestion(e)}>
                                <div className="modal-content">
                                    <div className="modal-header align-items-center">
                                        <h1 className="modal-title m-0 p-0 fs-5 text-orange" id="exampleModalLabel">Move this question to</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body logicContens">
                                        <p className='d-flex p-0 m-0 mb-4 align-items-center'>
                                            <span>Question No</span>
                                            <div className="dropdownnma w-100">
                                                <p className='mt-3'>{QuestionNo}</p>
                                            </div>
                                            {/* <span>is</span> */}
                                            {questionArray &&
                                                <div className="dropdownnma w-100">
                                                    <select className="dropdown__options-filter m-0" style={{ height: "42px" }} onChange={e => SetMoveQuestion(e.target.value)} value={MoveQuestion} >
                                                        <option hidden value="">Choose Question</option>
                                                        {questionArray.map((Question, index) =>
                                                            <option value={index}>Question {index + 1}</option>
                                                        )}
                                                    </select>
                                                </div>}

                                            <span>Move</span>
                                            <div className="dropdownnma w-100">
                                                <select className="dropdown__options-filter m-0" onChange={e => setposition(e.target.value)} style={{ height: "42px" }} value={position}  >
                                                    <option hidden value="">Choose Position</option>
                                                    <option value="-1">Before</option>
                                                    <option value="1">After</option>
                                                    {/* <option value="exit">Disqualification</option> */}
                                                </select>
                                            </div>

                                        </p>
                                    </div>
                                    <div className='my-4 d-flex justify-content-center'><button type='submit' className="mainbtn f-14" data-bs-dismiss="modal" aria-label="Close" >Save</button></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            }
            <main className='bg-lgrey'>
                <div
                    className="card bg-lgrey bg-gray-card-border text-center"
                    type="button"
                    ref={openModel}
                    data-bs-toggle="modal"
                    data-bs-target="#from-scratch-modal"
                ></div>
                <section id="all-section" className="section-survey sectionhuia">
                    <div className="container-fluid">
                        <div className="tab-content persCreate" id="myTabContent">
                            <div className="tab-pane fade show active  layout-grid" id="create-tab-pane" role="tabpanel" aria-labelledby="create-tab" tabIndex={0}>
                                <div className="wrapper p-0 wrapperpoPup">
                                    <nav id={divIdl === "sidebarl" ? "sidebarl" : " "} className={divClassl === "handleClassNameAndIdl" ? "handleClassNameAndIdl  handleBox2" : "mb-3 sidetarsa handleBox2 com-bsl"} >
                                        {/*{changeId}  onClick={handleId} */}
                                        <div className="position-relative  d-flex flex-column justify-content-between">
                                            <div className='intconq'>
                                                <button type="button" id="sidebarCollapsel" onClick={changeclassnamel} className="btn border-0 text-blue">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="white" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                    </svg>
                                                </button>
                                                <div className="f-14 d-flex justify-content-between align-items-center py-3">
                                                    <h5 className='text-orange'>Questions</h5>
                                                    {survey && survey.languages && survey.languages.length > 0 && <select className='languageDropdown' onChange={(e) => setQuestionLang(e)}>
                                                        {survey.languages.map((ln) => (<option value={ln.lang_code}>{ln.lang_name}</option>))}
                                                    </select>}

                                                </div>

                                                <div className='sortableDiv'>
                                                    {questionArray && <SortableList
                                                        items={questionArray}
                                                        onSortEnd={onSortEnd}
                                                        survey={survey}
                                                        questionType={questionType}
                                                        editQuestion={editQuestion}
                                                        setLogic={setLogic}
                                                        setMoveQuestions={setMoveQuestions}
                                                        setDeleteQuestion={setDeleteQuestion}
                                                    />}

                                                </div>
                                            </div>
                                        </div>
                                        {/* <ButtonToggle className='d-flex align-items-center text-orange justify-content-center'
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#from-scratch-modal02"
                                            > Header</ButtonToggle>
                                            <ButtonToggle className='d-flex align-items-center  text-orange justify-content-center'
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#from-scratch-modal02"
                                            >Footer</ButtonToggle>  */}
                                        {/* <div className='settoption'>
                                            <h5 >Header & Footer</h5>
                                            <input className="form-check-input center" value="optional"
                                                onChange={(e) => setHeader(!header)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#from-scratch-modal02"
                                                style={{ marginLeft: "0px" }} type="checkbox" role="switch" id="flexSwitchCheckDefault" />

                                        </div> */}
                                        <div className='upload-logo uploadLogoBox '>
                                            {/* <hr className=''></hr> */}
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                beforeUpload={beforeUpload}
                                                onChange={handleChange}
                                            >
                                                {(imageUrl) ? (
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <i className="bi bi-trash3-fill" onClick={(e) => removeLogo()}></i> <img
                                                            src={imageUrl}
                                                            alt="avatar"
                                                            className='uploaded-logo-img'
                                                            style={{
                                                                width: '100px',
                                                                objectFit: 'contain',
                                                                height: '100px'
                                                            }}
                                                        />
                                                    </div>
                                                ) : (uploadButton)
                                                }
                                            </Upload>

                                        </div>

                                    </nav>
                                    {/* ---------------------------------Change file modal -----------------------------------*/}
                                    <div className="modal" id="filedrop" tabIndex={-1} aria-labelledby="filedropLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered survey-title-modal">
                                            <div className="modal-content">
                                                <div className="modal-header media_gallery_model_header px-3 align-items-center">
                                                    <h5 className="modal-title" id="exampleModalLabel">Media Gallery</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <div className="modal-body text-center model-image-newsurvey">
                                                    <ul className="nav nav-tabs justify-content-around" id="myTab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="upload-tab" data-bs-toggle="tab" data-bs-target="#upload-tab-pane" type="button" role="tab" aria-controls="upload-tab-pane" aria-selected="true">Upload</button>
                                                        </li>
                                                        {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="image-tab" data-bs-toggle="tab" data-bs-target="#image-tab-pane" type="button" role="tab" aria-controls="image-tab-pane" aria-selected="false">Image</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video-tab-pane" type="button" role="tab" aria-controls="video-tab-pane" aria-selected="false">Video</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="icon-tab" data-bs-toggle="tab" data-bs-target="#icon-tab-pane" type="button" role="tab" aria-controls="icon-tab-pane" aria-selected="false">Icon</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="mygallery-tab" data-bs-toggle="tab" data-bs-target="#mygallery-tab-pane" type="button" role="tab" aria-controls="mygallery-tab-pane" aria-selected="false">My Gallery</button>
                                    </li> */}
                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">
                                                        {/* Upload button */}
                                                        <div className="tab-pane fade show active tab-pane-content file-choose-drop position-relative" id="upload-tab-pane" role="tabpanel" aria-labelledby="upload-tab" tabIndex={0} onChange={(e) => uploadImage(e)}>

                                                            <Dragger >
                                                                <div className='sur-bg-img bg-grey h-100 mx-3 d-flex flex-column justify-content-center align-items-center  '>
                                                                    <lottie-player src="../images/c699de4f-5e56-44ea-a5be-50a7d3bb823b.json" background="transparent" speed={1} style={{ width: '200px', height: '200px' }} loop autoPlay />
                                                                    <p>Upload or drop an image right here</p>
                                                                    <small>Gif ( up to 4 MB), PNG or JPG (up to 10 MB)</small>
                                                                </div>
                                                            </Dragger>
                                                        </div>
                                                        {/* Upload button */}
                                                        {/* Image button */}
                                                        {/* <div className="tab-pane fade tab-pane-content file-choose-drop" id="image-tab-pane" role="tabpanel" aria-labelledby="image-tab" tabIndex={0}>
                                        <div className="row justify-content-center m-2">


                                            <div className="form-group has-search main mt-2position-relative">
                                                <span className="form-control-feedback"><i className="bi bi-search"></i></span>
                                                <input type="text" className="form-control br-2" placeholder="Search free high-resolution photos" />
                                            </div>
                                            <div className="col-6  img-width-select-model"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                        </div>
                                    </div> */}

                                                        {/* Image button */}
                                                        {/* Video button */}
                                                        {/* <div className="tab-pane fade tab-pane-content file-choose-drop" id="video-tab-pane" role="tabpanel" aria-labelledby="video-tab" tabIndex={0}>
                                        <div className="row m-1">
                                            <div className="text-start">
                                                <p className='fw-bold pt-2'>Use videos from YouTube</p>
                                            </div> */}
                                                        {/* <div className="input-group m-3">
        <input type="text" className="form-control" placeholder="Change layout to add a YouTube video" aria-label="Recipient'    username" aria-describedby="basic-addon2">
        <span className="input-group-text p-0" id="basic-addon2"><button className="border-0">Add Lnik</button></span>
      </div> */}
                                                        {/* <div className="input-group mb-3">
                                                <input type="text" className="form-control br-2" placeholder="Change layout to add a YouTube video" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <button className="input-group-text addlinkbtn" id="basic-addon2">Add Link</button>
                                            </div>
                                            <div className="col-6  img-width-select-model">
                                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="w-100 d-none d-md-block" />
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="w-100 d-none d-md-block" />
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                        </div>
                                    </div> */}
                                                        {/* Video button */}
                                                        {/* Icon button */}
                                                        <div className="tab-pane fade tab-pane-content file-choose-drop" id="icon-tab-pane" role="tabpanel" aria-labelledby="icon-tab" tabIndex={0}>
                                                            <div className="row justify-content-center tab-icon-drop  m-1">

                                                                <div className="form-group has-search main mt-3 position-relative">
                                                                    <span className="form-control-feedback"><i className="bi bi-search"></i></span>
                                                                    <input type="text" className="form-control br-2" placeholder="Search free Icons" />
                                                                </div>

                                                                {/* <div className="row tab-icon-drop">
                                                <div className="col-3 "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="w-100 d-none d-md-block" />
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="w-100 d-none d-md-block" />
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>

                                            </div>
                                            <div className="text-right p-3">
                                                <a className="mainbtn text-white">Add</a>
                                            </div> */}
                                                            </div>
                                                        </div>
                                                        {/* Icon button */}
                                                        {/* My Gallery button */}
                                                        {/* <div className="tab-pane fade tab-pane-content file-choose-drop" id="mygallery-tab-pane" role="tabpanel" aria-labelledby="mygallery-tab" tabIndex={0}>
                                        <div className="row justify-content-center pt-3 m-0">
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            {/* <div className="w-100 d-none d-md-block"></div> 
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                        </div>
                                    </div> */}
                                                        {/* My Gallery button */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {<div id="content" className='scratsurmid mysidebartop mt-3 com-bsl '>
                                        <div className="tab-content" id="aligned-survey">
                                            {layout == 1 && <div className="question-alignment fade show active" id="all-left-pane" aria-labelledby="all-left" >
                                                <div className='survey-question text-right'>
                                                    {/* -----Mohib------ */}
                                                    <div className='align-con py-4 row'>
                                                        <div className='col-lg-12'>
                                                            <h4 className='q-n d-flex'>{qid != undefined ? qid : questionArray?.length + 1} <span className='text-orange'><i className="bi bi-arrow-right"></i></span>
                                                                <input type={'text'} name="question" value={capitalizeFirstLetter(questions).toString()} onChange={(e) => surveys.saved == 1 ? null : setQuestions(e.target.value)} className=' fadeInUp' placeholder="Please enter your question here"></input>
                                                            </h4>
                                                            <br /><p><input type={'text'} name="description" className=' fadeInUp' placeholder='Description (optional)' value={surveyQuestion} onChange={(e) => setSurveyQuestion(e.target.value)}></input></p>
                                                            {questionImage && questionImage != "" && <div className='d-flex mb-4 justify-content-center question-Image'><i className="bi bi-trash3-fill" onClick={(e) => setQuestionImage(null)}></i><img src={questionImage} width="70px" /></div>}

                                                            {optionType != 'matrix' && optionType != 'text' && optionType != 'csat' && optionType != "nps" &&
                                                                <div className='row'>

                                                                    {(optionType === 'rating') ?
                                                                        <>
                                                                            {/* <div className='d-flex minmaxvertdas align-items-center col-md-8'> */}
                                                                                {/* <span className='me-2 text-orange'>Scale</span><input min="0" max="10" type="number" className='me-3' value={rating.min} placeholder="Enter Min Rating" onChange={(e) => setTemp(e.target.value, 'min')}
                                                                                />
                                                                                <span className='me-2 text-orange'>To</span><input min="0" max="10" type='number' value={rating.max} placeholder="Enter Max Rating" onChange={(e) => setTemp(e.target.value, 'max')}
                                                                                /> */}
                                                                                <div className="button-container">
                                                                                    <button className="rating-button" data-stars="3" onClick={() => setTemp(3)}>3 Star</button>
                                                                                    <button className="rating-button" data-stars="5" onClick={() => setTemp(5)}>5 Star</button>
                                                                                    <button className="rating-button" data-stars="7" onClick={() => setTemp(7)}>7 Star</button>
                                                                                    <button className="rating-button" data-stars="10" onClick={() => setTemp(10)}>10 Star</button>
                                                                                </div>
                                                                                <div className='star-container'>
                                                                                    {ratingStar > 0 && <span className='starSpanScratchSurvey big-star'>{'★'.repeat(ratingStar)}</span>}
                                                                                </div>
                                                                        </>
                                                                        :
                                                                        (optionType === 'contact') ? contactForm.map((input, index) => {

                                                                            return (<>
                                                                                < div className='col-lg-12' onClick={(e) => setOptionIndex(index)}>
                                                                                    <div className="input-group mb-2" style={{ flexWrap: "nowrap", alignItems: "center", gap: "12px" }}>
                                                                                        {/* <span className="input-group-text border-0 rounded-1 mr-2" id="basic-addon1"><input value={optionType} type={optionType} /></span> */}
                                                                                        <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">{String.fromCharCode(65 + index)}</span>

                                                                                        <input className="form-control bg-transparent" style={{ width: "20%" }} name={"option" + index} value={capitalizeFirstLetter(input)} onChange={(e) => setTemp(e.target.value, index)} placeholder={`Enter ${optionType == 'form' ? 'label' : 'option'} ${String.fromCharCode(index + 65)}`} />
                                                                                        {/* {(optionType == 'radio' || optionType == 'checkbox') && (<><input type="file" id="myfileRety" name="myfile" onChange={(e) => uploadOptionImage(e, index)}></input>{input.imageUrl && <div className='newRtus'><i className="bi bi-x del-op" onClick={(e) => uploadOptionImage('remove', index)}></i><img src={input.imageUrl} /></div>}<i className="bi bi-paperclip pr-3 pt-2"></i></>)} */}
                                                                                        {optionType == "contact" && <input placeholder={`Enter the ${input}`} index={index} style={{ padding: "10px", margin: "6px 0" }} />}
                                                                                        {/* <i className="bi bi-x del-op" onClick={() => removeFields(index)}></i> */}
                                                                                        <input className='form-check-input fs-3' id='que' style={{ width: "38px", position: "static", borderRadius: "40px" }} type='checkbox' value={input} onChange={(e) => contactCheckbox(e)} defaultChecked={contactFormCheckbox(input)}></input>
                                                                                    </div>
                                                                                </div>
                                                                                {/* {optionType == 'contact'  && (<div onClick={(e) => setOptionIndex(index)}><Dropdown options={input.dropdown_option} selectedOption={`${input.dropdown_option.length - 1 ? input.dropdown_option.length - 1 : 'No'} options`} editDropdown={editDropdown} dropdownName={'dropdown_option'} /></div>)} */}
                                                                            </>
                                                                            )
                                                                        }) :
                                                                            <>

                                                                                {inputFields.map((input, index) => {
                                                                                    return (
                                                                                        <div key={index}>

                                                                                            <div className={optionType == "form" ? 'col-lg-12' : 'col-lg-6 col-md-12'} onClick={(e) => setOptionIndex(index)}>
                                                                                                <div className="input-group mb-2">
                                                                                                    {/* <span className="input-group-text border-0 rounded-1 mr-2" id="basic-addon1"><input value={optionType} type={optionType} /></span> */}
                                                                                                    <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">{String.fromCharCode(65 + index)}</span>

                                                                                                    <input className="form-control bg-transparent" name={"option" + index} value={capitalizeFirstLetter(String(input[displayKeys["option"]] || ""))} onChange={(e) => setTemp(e.target.value, index)} placeholder={`Enter ${optionType == 'form' ? 'label' : 'option'} ${String.fromCharCode(index + 65)}`} />
                                                                                                    {(optionType == 'radio' || optionType == 'checkbox') && (<><input type="file" id="myfileRety" name="myfile" onChange={(e) => uploadOptionImage(e, index)}></input>{input.imageUrl && <div className='newRtus'><i className="bi bi-x del-op" onClick={(e) => uploadOptionImage('remove', index)}></i><img src={input.imageUrl} /></div>}<i className="bi bi-paperclip pr-3 pt-2"></i></>)}
                                                                                                    {optionType == "form" && <Dropdown options={[{ name: 'Text', value: "" }, { name: 'Dropdown', value: 'dropdown' }, { name: 'Double Dropdown', value: 'dropdowns' }]} selectedOption={input.option_type_name ? input.option_type_name : 'Text'} setType={setFormOption} index={index} />}
                                                                                                    {index >= 1 && <i className="bi bi-x del-op" onClick={() => removeFields(index)}></i>}
                                                                                                </div>
                                                                                            </div>
                                                                                            {optionType == 'form' && (input.option_type == "dropdown" || input.option_type == "dropdowns") && (<div onClick={(e) => setOptionIndex(index)}><Dropdown options={input.dropdown_option} selectedOption={`${input.dropdown_option.length - 1 ? input.dropdown_option.length - 1 : 'No'} options`} editDropdown={editDropdown} dropdownName={'dropdown_option'} /></div>)}
                                                                                            {optionType == 'form' && input.option_type == "dropdowns" && (<div onClick={(e) => setOptionIndex(index)}><Dropdown options={input.dropdown_option1} selectedOption={`${input.dropdown_option1.length - 1 ? input.dropdown_option1.length - 1 : 'No'} options`} dropdownName={'dropdown_option1'} editDropdown={editDropdown} onClick={(e) => setOptionIndex(index)} /></div>)}

                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                                {(surveys && (!surveys.saved || surveys.saved != 1)) && <div className='col-lg-3 pt-2'>
                                                                                    <a className='' onClick={(e) => addNewOptionCol()}><u className='text-orange fw-bold fa fa-plus  '>{addOptionLabel}</u></a>
                                                                                </div>}
                                                                            </>
                                                                    }

                                                                </div>}
                                                            <br></br>
                                                            <div className='d-flex justify-content-between'>

                                                                <br></br>
                                                                {optionType == 'text' &&
                                                                    <><div className="col-mg-4 form-check mt-3 attpgglee form-switch p-0 m-0 mb-3 d-flex ">
                                                                        <span className="fw-bold text-orange">Long Text<br></br><span className='characttxts'>1000 Characters</span></span>
                                                                        <input className="form-check-input position-relative p-0 mx-3 m-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" value="1" onChange={(e) => { setTextLength({ ...textLength, case: e.target.checked }) }} />
                                                                        <span className="fw-bold text-orange">Short Text<br></br><span className='characttxts'>200 Characters</span></span>
                                                                    </div>
                                                                        <br></br>
                                                                        <h6 className='text-orange' style={{ width: "9%" }}>Max Characters <input type="number" className='mt-2' id="quantityss" name="quantityss" style={{ border: "1px solid #ced4da" }} onChange={(e) => setTextLength({ ...textLength, length: e.target.value })} /></h6>
                                                                    </>
                                                                }

                                                            </div>

                                                            {
                                                                optionType == 'matrix' &&
                                                                <div className='row'>

                                                                    {
                                                                        <>
                                                                            <h4 className='text-orange matrix-option' >Enter Row Options</h4>
                                                                            {inputFields.map((input, index) => {
                                                                                return (
                                                                                    <div className='col-lg-8'>
                                                                                        <div className="input-group mb-2">
                                                                                            <span className="input-group-text border-0 rounded-1 mr-2" id="basic-addon1"></span>
                                                                                            <input className="form-control bg-transparent " value={input[displayKeys.option]} onChange={(e) => setTemp(e.target.value, index)} placeholder={`Enter Option ${String.fromCharCode(index + 65)}`} />
                                                                                            <i className="bi bi-x del-op" onClick={() => removeFields(index)}></i>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            <div className='col-lg-3 pt-2'>
                                                                                <a className='' onClick={(e) => addNewOptionCol()}><u className='text-orange fw-bold '>Add Row Field</u></a>
                                                                            </div><br></br>

                                                                            <h4 className='text-orange matrix-option' >Enter Column Options</h4>

                                                                            {matOptions.map((input, index) => {
                                                                                return (
                                                                                    <div className='col-lg-8'>
                                                                                        <div className="input-group mb-2">
                                                                                            <span className="input-group-text border-0 rounded-1 mr-2" id="basic-addon1"></span>
                                                                                            <input className="form-control bg-transparent " value={input} onChange={(e) => setTemp(e.target.value, index, "matOptions")} placeholder={`Enter Option ${String.fromCharCode(index + 65)}`} />
                                                                                            <i className="bi bi-x del-op" onClick={() => removeFields(index, 'mat')}></i>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            <div className='col-lg-3 pt-2'>
                                                                                <a className='' onClick={(e) => addNewOptionCol("matOptions")}><u className='text-orange fw-bold '>Add Column Field</u></a>
                                                                            </div><br></br></>}
                                                                    {multipleSelection == 'dropdown' && <div className='mt-5 d-flex'>
                                                                        <span className='text-orange mat-Dropdown mr-2'>DropDown Options</span>
                                                                        <Dropdown options={matDropdown} selectedOption={`${(matDropdown.length) > 1 ? (matDropdown.length - 1) : 'No'} options`} dropdownName={'matDropdown'} editDropdown={editDropdown} />
                                                                    </div>}
                                                                </div>}

                                                            {(optionType == 'csat') && (
                                                                <div className="container-fluid allQueRport py-3 mt-2 earnSec">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">

                                                                            <div className="panel-body">
                                                                                <div>
                                                                                    <div className="react-bs-table-container">
                                                                                        <div className="react-bs-table react-bs-table-bordered" style={{ height: '100%' }}>
                                                                                            <div className="react-bs-container-header table-header-wrapper pl-5">

                                                                                                {multipleSelection == 'emoji' && <div className="col-lg-6 h-100">
                                                                                                    <div className="d-flex ratingBoxSec w-100 pl-2">
                                                                                                        <div className="comnRating angryRating m-2">
                                                                                                            <img src="../images/angry_mini.svg" />
                                                                                                            <div className="blockRating">
                                                                                                                <a >1</a>
                                                                                                            </div>
                                                                                                            <input value={inputFields[0][displayKeys.option]} onChange={(e) => setTemp(e.target.value, 0)} />
                                                                                                        </div>
                                                                                                        <div className="comnRating upsetRating m-1">
                                                                                                            <img src="../images/upset_mini.svg" />
                                                                                                            <div className="blockRating">
                                                                                                                <a >2</a>
                                                                                                            </div>
                                                                                                            <input value={inputFields[1][displayKeys.option]} onChange={(e) => setTemp(e.target.value, 1)} />                                                                                   </div>
                                                                                                        <div className="comnRating naturalRating m-1">
                                                                                                            <img src="../images/neutral_mini.svg" />
                                                                                                            <div className="blockRating">
                                                                                                                <a>3</a>
                                                                                                            </div>
                                                                                                            <input value={inputFields[2][displayKeys.option]} onChange={(e) => setTemp(e.target.value, 2)} />
                                                                                                        </div>
                                                                                                        <div className="comnRating happyRating m-1">
                                                                                                            <img src="../images/happy_mini.svg" />
                                                                                                            <div className="blockRating">
                                                                                                                <a >4</a>
                                                                                                            </div>
                                                                                                            <input value={inputFields[3][displayKeys.option]} onChange={(e) => setTemp(e.target.value, 3)} />                                                                                   </div>
                                                                                                        <div className="comnRating excitedRating m-1">
                                                                                                            <img src="../images/excited_mini.svg" />
                                                                                                            <div className="blockRating">
                                                                                                                <a >5</a>
                                                                                                            </div>
                                                                                                            <input value={inputFields[4][displayKeys.option]} onChange={(e) => setTemp(e.target.value, 4)} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>}



                                                                                                {multipleSelection == "ynm" && <div className=" row gap-3 my-5 ps-3 mn-like">
                                                                                                    <label className="Like-thumb rounded-1 p-0" style={{ width: '125px' }}>
                                                                                                        <input type="radio" className="rounded-1 " name="radio" />
                                                                                                        <div className="checkmarksen d-grid text-center " style={{ padding: '20px 30px 0px' }}>
                                                                                                            <img src="../images/Thumb-yes.svg" alt="" className="m-auto" width="40px" />
                                                                                                            <span className="pt-3 pb-2 fs-6">Yes</span>
                                                                                                        </div>
                                                                                                    </label>
                                                                                                    <label className="Like-thumb p-0" style={{ width: '125px' }}>
                                                                                                        <input type="radio" name="radio" />
                                                                                                        <div className="checkmarksen d-grid text-center" style={{ padding: '20px 30px 0px' }}>
                                                                                                            <img src="../images/Thumb-no.svg" className="m-auto" alt="" width="40px" />
                                                                                                            <span className="pt-3 pb-2 fs-6">No</span>
                                                                                                        </div>
                                                                                                    </label>
                                                                                                    <label className="Like-thumb p-0" style={{ width: '125px' }}>
                                                                                                        <input type="radio" name="radio" />
                                                                                                        <div className="checkmarksen d-grid text-center" style={{ padding: '20px 30px 0px' }}>
                                                                                                            <img src="../images/Maybe.svg" alt="" className="m-auto" width="37px" />
                                                                                                            <span className="pt-3 pb-2" style={{ fontSize: '14px' }}>Maybe</span>
                                                                                                        </div>
                                                                                                    </label>
                                                                                                </div>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {(optionType == 'nps') ? <div className="col-lg-12  artingNPSver">
                                                                {/* {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => ( */}
                                                                <div className="d-flex ratingBoxSec pl-2">
                                                                    {/* { console.log(index)} */}
                                                                    <div className="comnRating angryRating angryRatingaligment" onClick={() => ""}>
                                                                        <img src="../images/angry_mini.svg" style={{ width: "40px" }} />
                                                                        <div className="blockRating">
                                                                            <a >0</a>
                                                                            <a >1</a>
                                                                            <a >2</a>
                                                                            <a >3</a>
                                                                            <a >4</a>
                                                                            <a >5</a>
                                                                            <a>6</a>
                                                                        </div>
                                                                        <input value={""} />
                                                                    </div>
                                                                    {/* <div className="comnRating upsetRating angryRatingaligment" onClick={() => ""}>
                                                                        <img src="../images/upset_mini.svg" style={{ width: "40px" }} />
                                                                        <div className="blockRating">
                                                                            <a >3</a>
                                                                            <a >4</a>
                                                                            <a >5</a>
                                                                        </div>
                                                                        <input value={""} />                                                                                   </div> */}
                                                                    <div className="comnRating naturalRating angryRatingaligment" onClick={() => ""}>
                                                                        <img src="../images/neutral_mini.svg" style={{ width: "40px" }} />
                                                                        <div className="blockRating">
                                                                            
                                                                            <a>7</a>
                                                                            <a>8</a>

                                                                        </div>
                                                                        <input value={""} />
                                                                    </div>
                                                                    {/* <div className="comnRating happyRating angryRatingaligment" onClick={() => ""}>
                                                                        <img src="../images/happy_mini.svg" style={{ width: "40px" }} />
                                                                        <div className="blockRating">
                                                                        </div>
                                                                        <input value={""} />                                                                                   </div> */}
                                                                    <div className="comnRating excitedRating angryRatingaligment" onClick={() => ""}>
                                                                        <img src="../images/excited_mini.svg" style={{ width: "40px" }} />
                                                                        <div className="blockRating">
                                                                            <a >9</a>
                                                                            <a >10</a>
                                                                        </div>
                                                                        <input value={""} />
                                                                    </div>
                                                                </div>
                                                                {/* ))} */}
                                                            </div> : ""}


                                                            <div className='action w-100 mt-5'>
                                                                {editMode && (!surveys.saved || surveys.saved == 0) && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('edit')}>Update</a>}
                                                                {!editMode && (!surveys.saved || surveys.saved == 0) && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('save')}>Add</a>}
                                                                {!editMode && (!surveys.saved || surveys.saved == 0) && questionArray?.length >= 1 && <a className='mainbtn f-14 bg-orange' data-bs-toggle="modal" data-bs-target="#saveModal"  >Save</a>}
                                                                {surveys.saved == 1 && (<div className='d-flex justify-content-between w-100' >
                                                                    {qid > 1 && <span className='page-arrow preview-web bg-white text-orange rounded-0' onClick={(e) => pagination('prev')}>Previous</span>}
                                                                    {qid != questionArray?.length && <span className='page-arrow' onClick={(e) => pagination('next')}>Next</span>}
                                                                </div>)}
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>}


                                            {/*============== New Code ==============*/}

                                            {layout == 2 && <div className=" question-alignment fade" id="img-right-pane" aria-labelledby="img-right" >
                                                <div className='survey-question'>
                                                    <h4 className='q-n d-flex'>{qid != undefined ? qid : questionArray?.length + 1}<span className='text-orange'><i className="bi bi-arrow-right"></i></span><input type={'text'} className=' fadeInUp' value={(capitalizeFirstLetter(questions))} onChange={(e) => setQuestions(e.target.value)} placeholder="Please enter your question here"></input></h4>
                                                    <p><input type={'text'} className=' fadeInUp' placeholder='Description (optional)'></input></p>
                                                    <div className='align-con py-4 row'>
                                                        <div className="col-lg-5">
                                                            {inputFields.map((input, index) => {
                                                                return (<div className="input-group mb-3">
                                                                    <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">{String.fromCharCode(65 + index)}</span>
                                                                    <input type="text" className="form-control bg-transparent" value={input[displayKeys.option]} onChange={(e) => setTemp(e.target.value, index)} placeholder={`Enter Option ${String.fromCharCode(index + 65)}`} />
                                                                    {(optionType == 'radio' || optionType == 'checkbox') && (<><input type="file" id="myfileRety" name="myfile" onChange={(e) => uploadOptionImage(e, index)}></input>{input.imageUrl && <div className='newRtus'><i className="bi bi-x del-op" onClick={(e) => uploadOptionImage('remove', index)}></i><img src={input.imageUrl} /></div>}<i className="bi bi-paperclip pr-3 pt-2"></i></>)}
                                                                    <i className="bi bi-x del-op" onClick={() => removeFields(index)}></i>
                                                                </div>)
                                                            })}


                                                            <div className=" py-3">
                                                                <a className='' onClick={(e) => addNewOptionCol()}><u className='text-orange fw-bold '>{addOptionLabel}</u></a>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-7'>
                                                            <img src={questionImage && questionImage != "" ? questionImage : '../images/login-bg.jpg'} className='w-100 '></img>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='action w-100 mt-5'>
                                                    {editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('edit')}>Update</a>}
                                                    {!editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('save')}>Add</a>}
                                                    {!editMode && <a className='mainbtn f-14 mainbtnblue2' onClick={(e) => { saveSurvey() }} >Save</a>}
                                                </div>
                                            </div>}
                                            {layout == 3 && <div className=" question-alignment fade" id="img-left-pane" aria-labelledby="img-left" ><div className='survey-question'>
                                                <h4 className='q-n d-flex'>{qid != undefined ? qid : questionArray?.length + 1}<span className='text-orange'><i className="bi bi-arrow-right"></i></span><input type={'text'} className=' fadeInUp' value={questions} onChange={(e) => setQuestions(e.target.value)} placeholder="Please enter your question here"></input></h4>
                                                <p><input type={'text'} className=' fadeInUp' placeholder='Description (optional)'></input></p>
                                                <div className='align-con py-4  row  '>


                                                    <div className='col-lg-7'>
                                                        <img src={questionImage && questionImage != "" ? questionImage : '../images/login-bg.jpg'} className='w-100 '></img>
                                                    </div>
                                                    <div className="col-lg-5 py-5">
                                                        {inputFields.map((input, index) => {
                                                            return (<div className="input-group mb-3">
                                                                <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">{String.fromCharCode(index + 65)}</span>
                                                                <input type="text" className="form-control bg-transparent" value={input[displayKeys.option]} onChange={(e) => setTemp(e.target.value, index)} placeholder={`Enter Option ${String.fromCharCode(index + 65)}`} />
                                                                {(optionType == 'radio' || optionType == 'checkbox') && (<><input type="file" id="myfileRety" name="myfile" onChange={(e) => uploadOptionImage(e, index)}></input>{input.imageUrl && <div className='newRtus'><i className="bi bi-x del-op" onClick={(e) => uploadOptionImage('remove', index)}></i><img src={input.imageUrl} /></div>}<i className="bi bi-paperclip pr-3 pt-2"></i></>)}
                                                                <i className="bi bi-x del-op" onClick={() => removeFields(index)}></i>
                                                            </div>
                                                            )
                                                        })}
                                                        <div className=" py-3">
                                                            <a className='' onClick={(e) => addNewOptionCol()}><u className='text-orange fw-bold '>{addOptionLabel}</u></a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                                <div className='action w-100 mt-5'>
                                                    {editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('edit')}>Update</a>}
                                                    {!editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('save')}>Add</a>}
                                                    {!editMode && <a className='mainbtn f-14 mainbtnblue2' onClick={(e) => { saveSurvey() }} >Save</a>}
                                                </div>
                                            </div>}
                                            {layout == 4 && <div className=" question-alignment fade" id="bimg-right-pane" aria-labelledby="bimg-right" >
                                                <div className='survey-question'>

                                                    <div className='align-con py-4 row align-items-center'>
                                                        <div className="col-lg-5">
                                                            <h4 className='q-n d-flex'>{qid != undefined ? qid : questionArray?.length + 1}<span className='text-orange'><i className="bi bi-arrow-right"></i></span><input type={'text'} className=' fadeInUp' value={questions} onChange={(e) => setQuestions(e.target.value)} placeholder="Please enter your question here"></input></h4>
                                                            <p><input type={'text'} className=' fadeInUp' placeholder='Description (optional)'></input></p>

                                                            {inputFields.map((input, index) => {
                                                                return (<div className="input-group mb-3">
                                                                    <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">{String.fromCharCode(index + 65)}</span>
                                                                    <input type="text" className="form-control bg-transparent" value={input[displayKeys.option]} onChange={(e) => setTemp(e.target.value, index)} placeholder={`Enter Option ${String.fromCharCode(index + 65)}`} />
                                                                    {(optionType == 'radio' || optionType == 'checkbox') && (<><input type="file" id="myfileRety" name="myfile" onChange={(e) => uploadOptionImage(e, index)}></input>{input.imageUrl && <div className='newRtus'><i className="bi bi-x del-op" onClick={(e) => uploadOptionImage('remove', index)}></i><img src={input.imageUrl} /></div>}<i className="bi bi-paperclip pr-3 pt-2"></i></>)}
                                                                    <i className="bi bi-x del-op" onClick={() => removeFields(index)}></i>
                                                                </div>)
                                                            })}


                                                            <div className=" py-3">
                                                                <a className='' onClick={(e) => addNewOptionCol()}><u className='text-orange fw-bold '>{addOptionLabel}</u></a>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-7'>
                                                            <img src={questionImage && questionImage != "" ? questionImage : '../images/login-bg.jpg'} className='w-100 '></img>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='action w-100 mt-5'>
                                                    {editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('edit')}>Update</a>}
                                                    {!editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('save')}>Add</a>}
                                                    {!editMode && <a className='mainbtn f-14 mainbtnblue2' onClick={(e) => { saveSurvey() }} >Save</a>}
                                                </div>
                                            </div>}

                                            {layout == 5 && <div className=" question-alignment fade" id="bimg-left-pane" aria-labelledby="bimg-left" >
                                                <div className='survey-question'>

                                                    <div className='align-con py-4 row align-items-center'>
                                                        <div className='col-lg-7'>
                                                            <img src={questionImage && questionImage != "" ? questionImage : '../images/login-bg.jpg'} className='w-100 '></img>
                                                        </div>
                                                        <div className="col-lg-5">
                                                            <h4 className='q-n d-flex'>{qid != undefined ? qid : questionArray?.length + 1} <span className='text-orange'><i className="bi bi-arrow-right"></i></span><input type={'text'} className=' fadeInUp' value={questions} onChange={(e) => setQuestions(e.target.value)} placeholder="Please enter your question here"></input></h4>
                                                            <p><input type={'text'} className=' fadeInUp' placeholder='Description (optional)'></input></p>

                                                            {inputFields.map((input, index) => {
                                                                return (<div className="input-group mb-3">
                                                                    <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">{String.fromCharCode(index + 65)}</span>
                                                                    <input type="text" className="form-control bg-transparent" value={input[displayKeys.option]} onChange={(e) => setTemp(e.target.value, index)} placeholder={`Enter Option ${String.fromCharCode(index + 65)}`} />
                                                                    {(optionType == 'radio' || optionType == 'checkbox') && (<><input type="file" id="myfileRety" name="myfile" onChange={(e) => uploadOptionImage(e, index)}></input>{input.imageUrl && <div className='newRtus'><i className="bi bi-x del-op" onClick={(e) => uploadOptionImage('remove', index)}></i><img src={input.imageUrl} /></div>}<i className="bi bi-paperclip pr-3 pt-2"></i></>)}
                                                                    <i className="bi bi-x del-op" onClick={() => removeFields(index)}></i>
                                                                </div>)
                                                            })}


                                                            <div className=" py-3">
                                                                <a className='' onClick={(e) => addNewOptionCol()}><u className='text-orange fw-bold '>{addOptionLabel}</u></a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='action w-100 mt-5'>
                                                    {editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('edit')}>Update</a>}
                                                    {!editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('save')}>Add</a>}
                                                    {!editMode && <a className='mainbtn f-14 mainbtnblue2' onClick={(e) => { saveSurvey() }} >Save</a>}
                                                </div>
                                            </div>}
                                            {/* background-image: linear-gradient( 45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(http://localhost:3000/static/media/login-bg.0656b55….jpg);
                                            width: 100%;
                                            min-height: 550px;
                                            background-size: cover;
                                            background-position: center; */}
                                            {layout == 6 && <div classname=" question-alignment fade " id="fimg-pane" aria-labelledby="fimg" style={{ backgroundImage: 'linear-gradient( 45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(../images/login-bg.jpg)', width: '100%', minHeight: '550px', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                                <div className='survey-question '>

                                                    <div className='align-con m-0 py-4 row align-items-center justify-content-center'>

                                                        <div className="col-lg-6">
                                                            <h4 className='q-n d-flex'>{qid != undefined ? qid : questionArray?.length + 1}<span className='text-orange'><i className="bi bi-arrow-right"></i></span><input type={'text'} className=' fadeInUp' value={questions} onChange={(e) => setQuestions(e.target.value)} placeholder="Please enter your question here"></input></h4>
                                                            <p><input type={'text'} className=' fadeInUp' placeholder='Description (optional)'></input></p>
                                                            {inputFields.map((input, index) => {
                                                                return (
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">{String.fromCharCode(index + 65)}</span>
                                                                        <input type="text" className="form-control bg-transparent" value={input[displayKeys.option]} onChange={(e) => setTemp(e.target.value, index)} placeholder={`Enter Option ${String.fromCharCode(index + 65)}`} />
                                                                        {(optionType == 'radio' || optionType == 'checkbox') && (<><input type="file" id="myfileRety" name="myfile" onChange={(e) => uploadOptionImage(e, index)}></input>{input.imageUrl && <div className='newRtus'><i className="bi bi-x del-op" onClick={(e) => uploadOptionImage('remove', index)}></i><img src={input.imageUrl} /></div>}<i className="bi bi-paperclip pr-3 pt-2"></i></>)}
                                                                        <i className="bi bi-x del-op" onClick={() => removeFields(index)}></i>
                                                                    </div>)
                                                            })}


                                                            <div className=" py-3">
                                                                <a className='' onClick={(e) => addNewOptionCol()}><u className='text-orange fw-bold '>{addOptionLabel}</u></a>
                                                            </div>
                                                            <div className='action w-100 mt-5 '>
                                                                {editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('edit')}>Update</a>}
                                                                {!editMode && <a className='mainbtn f-14 mr-3' onClick={() => createSurvey('save')}>Add</a>}
                                                                {!editMode && <a className='mainbtn f-14 mainbtnblue2' onClick={(e) => { saveSurvey() }} >Save</a>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>}
                                            {/*============== New Code ==============*/}




                                        </div>
                                    </div>}
                                    <nav id={divIdrig === "sidebarrig" ? "sidebarrig" : " "} className={divClassrig === "handleClassNameAndIdrig" ? "handleClassNameAndIdrig handleBox" : "mb-3 sidetarsa handleBox mxSidea com-bsl "}>
                                        {/*{changeId}  onClick={handleId} */}
                                        <div className="position-relative">
                                            <button type="button" id="sidebarCollapserig" onClick={changeclassnamerig} className="btn border-0 text-blue">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="white" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                </svg>
                                            </button>
                                            <div className='right-dash-content rightUIa w-100'>
                                                <div className='rconhead'>
                                                    <h5 className='text-orange py-2'>Question Type</h5>

                                                </div>

                                                <div className="dropdownnma">
                                                    <input type="checkbox" className="dropdown__switch " id="filter-switch" hidden />
                                                    <label htmlFor="filter-switch" className="dropdown__options-filter">
                                                        {(!surveys.saved || surveys.saved != 1) ?
                                                            <Dropdown options={questionType} selectedOption={optionName.type} setType={setType} user={userData} />
                                                            : <h6>{optionName.type}</h6>}
                                                        {subQuestionType && subQuestionType.length > 0 && <div className="m-2"> <Dropdown options={subQuestionType} selectedOption={optionName.subType} setType={setSelection} user={userData} /></div>}

                                                    </label>
                                                </div>

                                                <hr className='my-3'></hr>
                                                <div className='settrpart'>
                                                    <h5 className='text-orange'>Settings</h5>
                                                    <div className='settoption'>
                                                        <p className='m-0 f-14'>Not Required</p>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" value="optional" onClick={e => setRequired(e.target.value)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                        </div>
                                                    </div>
                                                    {optionType === 'checkbox' ?
                                                        <div className='minmax'>
                                                            <div className='settoption'>
                                                                <p className='m-0 f-14'>Multiple Selection</p>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input " value='Multiple' onClick={e => setMultipleSelection(e.target.value)} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex minmaxval align-items-center'><input type={'number'} className='form-control' placeholder='Min'></input><i className="mx-2 bi bi-arrow-left-right"></i><input type={'number'} className='form-control' placeholder='Max'></input></div>
                                                        </div> : ''}
                                                    {(optionType == 'radio' || optionType == 'checkbox') && <div className='textfieldreq'>
                                                        {(!surveys.saved || surveys.saved != 1) && <div className='settoption'>
                                                            <p className='m-0 f-14'>"Other" option</p>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" role="switch" checked={isOtherOptionCheck} onClick={e => isOther(e)} id="flexSwitchCheckDefault" />
                                                            </div>
                                                        </div>}
                                                        <span className='d-flex text-start  settoption m-0'><small>Text Field Required?</small><div className="form-check form-switch"><input className="form-check-input border" checked={isTextRequired} type="checkbox" role="switch" onClick={e => isText(e)} id="flexSwitchCheckDefault"></input></div></span>
                                                    </div>}

                                                </div>
                                                <hr></hr>
                                                {(!surveys.saved || surveys.saved != 1) && <div className='d-flex align-items-center justify-content-between'>
                                                    <h5 className='text-orange m-0'>{questionImage ? 'Change Image' : 'Add Image'}</h5><span className='d-flex'>
                                                        <input type="file" className="imghsa" id="img" name="img" accept="image/*" role='button' onChange={(e) => uploadImage(e)} />
                                                        <i className="bi bi-card-image fs-4 px-2" role='button'></i>
                                                    </span>
                                                </div>}
                                                {layoutOption && (<> <hr></hr>
                                                    <div className='layout'>
                                                        <h5 className='text-orange '>Layout</h5>
                                                        <div className="row" id="" role="tablist">
                                                            <div className='col-4 p-1'>
                                                                <button onClick={(e) => setLayout(1)} className="nav-link alignment-btn active" id="all-left" data-bs-toggle="tab" data-bs-target="#all-left-pane" type="button" role="tab" aria-controls="all-left-pane" aria-selected="true"><img className='alignment-image' src='../images/Group 11139.svg'></img><img src='../images/Group 11145.svg' className='active-align'></img></button>
                                                            </div>
                                                            <div className='col-4 p-1'>
                                                                <button onClick={(e) => setLayout(2)} className="nav-link alignment-btn" id="img-right" data-bs-toggle="tab" data-bs-target="#img-right-pane" type="button" role="tab" aria-controls="img-right-pane" aria-selected="false"><img className='alignment-image' src='../images/Group 11140.svg'></img><img src='../images/Group 11145.svg' className='active-align'></img></button>
                                                            </div>
                                                            <div className='col-4 p-1'>
                                                                <button onClick={(e) => setLayout(3)} className="nav-link alignment-btn" id="img-left" data-bs-toggle="tab" data-bs-target="#img-left-pane" type="button" role="tab" aria-controls="img-left-pane" aria-selected="false"><img className='alignment-image' src='../images/Group 11141.svg'></img><img src='../images/Group 11145.svg' className='active-align'></img></button>
                                                            </div>
                                                            <div className='col-4 p-1'>
                                                                <button onClick={(e) => setLayout(4)} className="nav-link alignment-btn" id="bimg-right" data-bs-toggle="tab" data-bs-target="#bimg-right-pane" type="button" role="tab" aria-controls="bimg-right-pane" aria-selected="false"><img className='alignment-image' src='../images/Group 11142.svg'></img><img src='../images/Group 11145.svg' className='active-align'></img></button>
                                                            </div>
                                                            <div className='col-4 p-1'>
                                                                <button onClick={(e) => setLayout(5)} className="nav-link alignment-btn" id="bimg-left" data-bs-toggle="tab" data-bs-target="#bimg-left-pane" type="button" role="tab" aria-controls="bimg-left-pane" aria-selected="false"><img className='alignment-image' src='../images/Group 11143.svg'></img><img src='../images/Group 11145.svg' className='active-align'></img></button>
                                                            </div>
                                                            <div className='col-4 p-1'>
                                                                <button onClick={(e) => setLayout(6)} className="nav-link alignment-btn" id="fimg" data-bs-toggle="tab" data-bs-target="#fimg-pane" type="button" role="tab" aria-controls="fimg-pane" aria-selected="false"><img className='alignment-image' src='../images/Group 11144.svg'></img><img src='../images/Group 11145.svg' className='active-align'></img></button>
                                                            </div>
                                                        </div>

                                                    </div></>)}
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>



                        </div>
                    </div>
                </section>
            </main >
            <div
                className="modal fade survey-popup"

                id="from-scratch-modal"
                data
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            //  style={{display:"block", opacity:"1"}}
            >
                <div className="modal-dialog h-100 modal-dialog-centered survey-title-modal">
                    <div className="modal-content">
                        <div className="modal-header pb-0">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Create a new survey
                            </h5>
                            <button

                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                ref={closeModel}
                            />
                        </div>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={addDasgboard}
                        >
                            <div className="modal-body text-center arownew p-4 model-image-newsurvey">
                                <div className="surForm d-flex w-100 justify-content-between mb-3">
                                    <div className="suresForm">
                                        <label className="text-start">Survey Name</label>

                                        <Form.Item
                                            name="surveyname"
                                            rules={[{ required: true, message: 'Please input your survey name!' }]}>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => setSurveyTitle(e.target.value)}

                                                placeholder="Enter Survey Name"
                                            />
                                        </Form.Item>

                                    </div>
                                    {/* <lottie-player src="/../images/6c4fd321-e509-4d3a-a3ff-8da5bea55f10.json" background="transparent" speed={1} style={{ width: '250px', height: '250px' }} loop autoPlay /> */}
                                    {

                                        <div className="suresForm">
                                            <label className="text-start">Expected Completes (in number)<span><i className="bi ms-2 bi-question-circle-fill"></i><span className="quesnmsw">Expected total responses</span></span></label>
                                            <Form.Item
                                                name="expectedcompletes"
                                                rules={[{ required: true, message: 'Please input your expected completes!' }]}>
                                                <Input
                                                    type="number"
                                                    className="form-control"


                                                    onChange={(e) => setExCompletes(e.target.value)}
                                                    placeholder="Number Type"
                                                />
                                            </Form.Item>

                                        </div>
                                    }
                                </div>
                                <div className="surForm d-flex w-100 justify-content-between mb-3">
                                    <div className="suresForm">
                                        <label className="text-start">Survey Audience<span><i className="bi ms-2 bi-question-circle-fill"></i><span className="quesnmsw"> select the target audience for the survey</span></span></label>
                                        <Form.Item
                                            name="surveyaudience"
                                            rules={[{ required: true, message: 'Please select your audience!' }]}>
                                            <select
                                                className="form-control survey_border border-white"

                                                onChange={(e) => setAudienceHandle(e.target.value)}
                                                placeholder="Survey Category"
                                            >
                                                <option default>Select Audience</option>
                                                <option value="Buy Audience">Buy Audience</option>
                                                <option value="My Own Audience">
                                                    My Own Audience
                                                </option>
                                            </select>
                                        </Form.Item>

                                    </div>
                                    <div className="suresForm">
                                        <label className="text-start">
                                            Expected Completion LOI (in minutes)<span><i className="bi ms-2 fs-7 bi-question-circle-fill"></i><span className="quesnmsw">Length of interview</span></span>
                                        </label>
                                        <Form.Item
                                            name="expectedcompletionloi"
                                            rules={[{ required: true, message: 'Please input your  expected completion LOI !' }]}>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                onChange={(e) => setExLOI(e.target.value)}
                                                placeholder="Number Type"
                                            />
                                        </Form.Item>

                                    </div>

                                </div>
                                <div className="surForm d-flex w-100 justify-content-between mb-3">
                                    <div className="suresForm">
                                        <label className="text-start">Survey Category<span><i className="bi ms-2 bi-question-circle-fill"></i><span className="quesnmsw">select the category for which this survey will be created</span></span></label>
                                        <Form.Item
                                            name="surveycategory"
                                            rules={[{ required: true, message: 'Please input your survey category!' }]}>
                                            <select
                                                className="form-control survey_border border-white"
                                                onChange={(e) => setSurveyCategory(e.target.value)}
                                                placeholder="Survey Category"
                                            >
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    onChange={(e) => setExIncidence(e.target.value)}
                                                    placeholder="Edit survey category name"></input>
                                                <option hidden>Select Category</option>
                                                {surveycategory &&
                                                    surveycategory.map((e) => (
                                                        <option value={e._id}>{e.category}</option>
                                                    ))}

                                            </select>
                                        </Form.Item>

                                    </div>
                                    {(audience == "Buy Audience") ? <div className="suresForm">
                                        <label className="text-start">
                                            Expected Incidence Rate<span><i className="bi ms-2 bi-question-circle-fill"></i><span className="quesnmsw">The percentage of respondents that will qualify for the study after basic demographic targeting.</span></span>
                                        </label>
                                        <Form.Item
                                            name=" expectedincidencerate"
                                            rules={[{ required: true, message: 'Please input your  expected incidence rate!' }]}>
                                            <Input
                                                type="number"
                                                className="form-control"


                                                onChange={(e) => setExIncidence(e.target.value)}
                                                placeholder="Number Type"
                                            />
                                        </Form.Item>

                                    </div> : null}


                                </div>
                                <div className="surForm d-flex w-100 justify-content-between mb-3">
                                    {<div className={access && access.Digital_Survey_Type && access.Digital_Survey_Type[0] && access.Digital_Survey_Type[0].Vernacular_Survey == "No" ? "suresForm tooltip" : "suresForm"}>
                                        <label className="text-start">Languages {access && access.Digital_Survey_Type && access.Digital_Survey_Type[0] && access.Digital_Survey_Type[0].Vernacular_Survey == "No" && <i className="bi bi-lock-fill"></i>}</label>
                                        <form className="form-group w-100">


                                            <Form.Item
                                            >
                                                <Multiselect
                                                    className="survey_border border-white"
                                                    disablePreSelectedValues={true}
                                                    disable={access && access.Digital_Survey_Type && access.Digital_Survey_Type[0] && access.Digital_Survey_Type[0].Vernacular_Survey == "No"}
                                                    selectedValues={[lang[0]]}
                                                    isObject={false}
                                                    onRemove={(e) => SelectLng(e, "setData")}
                                                    onSearch={(e) => SelectLng(e, "setData")}
                                                    onSelect={(e) => SelectLng(e, "setData")}
                                                    options={lang}
                                                    placeholder='Select Languages'
                                                />
                                                {access && access.Digital_Survey_Type && access.Digital_Survey_Type[0] && access.Digital_Survey_Type[0].Vernacular_Survey == "No" && <span className="tooltiptextt w-100 mb-5">Not Available in this Package,Upgrade First</span>}

                                            </Form.Item>
                                        </form>


                                    </div>}
                                    {<div className={access && access.Digital_Survey_Type && access.Digital_Survey_Type[0] && access.Digital_Survey_Type[0].Audio_Survey == 'No' ? "suresForm tooltip text-dark" : "suresForm"} >
                                        <label className="text-start">Survey Audio {access && access.Digital_Survey_Type && access.Digital_Survey_Type[0] && access.Digital_Survey_Type[0].Audio_Survey == 'No' && <i className="bi bi-lock-fill"></i>}</label>
                                        <form className="form-group lh-2 text-left w-100">
                                            <label className="me-5 form-check-label" for="flexSwitchCheckDefault">Audio Required</label>
                                            <input className="form-check-input" type="checkbox" role="switch"
                                                disabled={access && access.Digital_Survey_Type && access.Digital_Survey_Type[0] && access.Digital_Survey_Type[0].Audio_Survey == 'No'} id="flexSwitchCheckDefault" onChange={(e) => setAudio(!audio)}
                                            />

                                        </form>
                                        <span className="tooltiptextt w-100">Not Available in this Package,Upgrade First</span>
                                    </div>}
                                </div>
                                <Form.Item>

                                    <button htmlType="submit"   >
                                        <a
                                            className="mainbtn text-white"
                                        // data-bs-dismiss="modal"
                                        // aria-label="Close"

                                        >
                                            Create Survey
                                        </a>
                                    </button>
                                </Form.Item>

                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <ToastContainer />

        </>
    )
}

export default Scratchsurvey
